import React, { useContext, useMemo, useState, useCallback, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import {
  Badge,
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Heading,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { api } from "lib";
import { useApiGet, useCustomToast, usePrevious } from "hooks";
import { MdClose, MdNotifications, MdNotificationsOff, MdOutlineNotifications } from "react-icons/md";
import { TbExternalLink } from "react-icons/tb";
import { PrivateContext } from "pages/Private";
import { SocketContext } from "SocketProvider";
import BubbleSound from "assets/sounds/bubble.mp3";

const BubbleAudio = new Audio(BubbleSound);

const Notifications = () => {
  const { currentUser, notificationsCount, setNotificationsCount } = useContext(PrivateContext);
  const { socket } = useContext(SocketContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, isLoadingData, refreshData] = useApiGet(
    useMemo(
      () => ({
        path: `/users/${currentUser._id}/notifications`,
        params: { perPage: -1, sort: { createdAt: -1 } },
      }),
      [currentUser._id]
    )
  );
  const [notifications, setNotifications] = useState([]);
  const prevNotificationsCount = usePrevious(notificationsCount);
  const iconButtonColor = useColorModeValue("gray.500", "white");
  const colorScheme = useColorModeValue("blackAlpha", "gray");
  const toast = useCustomToast();

  useEffect(() => {
    setNotifications(data?.data ?? []);
  }, [data?.data]);

  useEffect(() => {
    setNotificationsCount(notifications.length);
  }, [notifications.length]);

  useEffect(() => {
    const context = new AudioContext();
    if (context.state === "running" && notificationsCount > prevNotificationsCount) BubbleAudio.play();
  }, [notificationsCount, prevNotificationsCount]);

  useEffect(() => {
    if (socket) {
      socket.on("@notifications", (data) => {
        setNotifications((state) => {
          const notifications = [...state];
          notifications.unshift(data);
          return notifications;
        });
      });
      return () => socket.removeListener("@notifications");
    }
  }, [socket]);

  const handleDeleteData = useCallback(
    async (items) => {
      try {
        const data = items.map((o) => o._id);
        setNotifications((state) => {
          const notifications = [...state];
          _.remove(notifications, (o) => data.indexOf(o.id) !== -1);
          return notifications;
        });
        await api.delete(`/users/${currentUser._id}/notifications`, { data });
      } catch (error) {
        if (error.isHandled) return;
        toast({ description: error.message, status: "error", isClosable: true });
      }
    },
    [currentUser._id, toast]
  );

  return (
    <>
      <Tooltip label="Notificações">
        <Box position="relative">
          <IconButton
            icon={<Icon as={socket ? MdNotifications : MdNotificationsOff} boxSize="20px" />}
            variant="ghost"
            color={iconButtonColor}
            onClick={onOpen}
            isRound
            isDisabled={!socket}
          />
          {socket && notificationsCount >= 1 && (
            <Badge position="absolute" top="0" right="0" variant="solid" colorScheme="red" borderRadius="full">
              {notificationsCount}
            </Badge>
          )}
        </Box>
      </Tooltip>
      <Drawer placement="right" isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay bg="blackAlpha.800" />
        <DrawerContent bg="transparent" boxShadow="none">
          <DrawerBody as={VStack} alignItems="stretch" p="10px" spacing="10px">
            <HStack justifyContent="space-between">
              <Button size="xs" onClick={refreshData}>
                atualizar
              </Button>
              <HStack bg="blackAlpha.400" h="24px" px="10px" borderRadius="lg">
                <Text color="white" fontSize="xs" fontWeight="semibold">
                  notificações
                </Text>
                {isLoadingData ? (
                  <Spinner size="xs" color="white" />
                ) : (
                  <Text color="white" fontSize="xs" fontWeight="semibold">
                    ({notificationsCount})
                  </Text>
                )}
              </HStack>
              <Button size="xs" onClick={() => handleDeleteData(notifications)} isDisabled={notificationsCount === 0}>
                limpar tudo
              </Button>
            </HStack>
            {_.map(notifications, (item) => (
              <Box key={item._id} role="group" position="relative">
                <IconButton
                  size="xs"
                  top="4px"
                  right="4px"
                  opacity="0"
                  position="absolute"
                  transition="200ms"
                  icon={<Icon as={MdClose} />}
                  _groupHover={{ opacity: "1" }}
                  onClick={() => handleDeleteData([item])}
                  zIndex="9999"
                />
                <Box borderRadius="lg" _light={{ bg: "white" }} _dark={{ bg: "gray.700" }}>
                  <Box p="15px">
                    <Text fontSize="xs" opacity="0.6">
                      {moment(item.createdAt).fromNow()}
                    </Text>
                    <Text fontSize="xs" fontWeight="semibold">
                      {item.title}
                    </Text>
                    <Text fontSize="xs">{item.body}</Text>
                  </Box>
                  <HStack p="15px" bg="blackAlpha.50">
                    {item.action?.href && (
                      <Button
                        w="100%"
                        size="xs"
                        colorScheme={colorScheme}
                        rightIcon={<Icon as={TbExternalLink} />}
                        as={RouterLink}
                        to={item.action.href}
                        target="_blank"
                      >
                        {item.action.title}
                      </Button>
                    )}
                  </HStack>
                </Box>
              </Box>
            ))}
            {notificationsCount === 0 && (
              <Center _light={{ bg: "white" }} _dark={{ bg: "gray.700" }} p="30px" borderRadius="lg">
                <Box textAlign="center">
                  <Icon as={MdOutlineNotifications} boxSize={20} marginBottom="10px" />
                  <Heading size="xs">Nenhuma notificação</Heading>
                  <Text fontSize="xs">Você não possui notificações pendentes.</Text>
                  <Button mt="10px" size="xs" onClick={onClose}>
                    fechar
                  </Button>
                </Box>
              </Center>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Notifications;
