import React, { useState, useCallback, useContext, useEffect } from "react";
import ObjectID from "bson-objectid";
import {
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import _ from "lodash";
import { yup, masks } from "lib";
import { messages } from "consts";
import { Address, LatLng, InputCurrency } from "components";
import { AddressesContext } from "./index";
import CustomersDetailsContext from "../../context";

const Details = () => {
  const { setFormData: setCustomerFormData } = useContext(CustomersDetailsContext);
  const { editSelected, setEditSelected } = useContext(AddressesContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    setFormData(editSelected?._id ? { ...editSelected } : { travelDurationInMinutes: 0, travelCostAmount: 0 });
    setFormErrors({});
  }, [editSelected]);

  const handleSaveData = useCallback(
    async (data) => {
      setCustomerFormData((state) => {
        const addresses = [...(state?.addresses ?? [])];
        data.line1 = [data.street, data.number, data.complement, data.neighborhood].filter((o) => o).join(", ");
        data.line2 = `${data.city}/${data.state} - CEP ${masks.zipCode(data.zipCode)}`;
        if (data._id) {
          const index = addresses.findIndex((o) => o._id === data._id);
          addresses[index] = data;
        } else addresses.push({ ...data, _id: ObjectID().toHexString() });
        return { ...state, addresses };
      });
      setEditSelected();
    },
    [setEditSelected]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        title: yup.string().required(messages.error.required),
        zipCode: yup.string().required(messages.error.required),
        street: yup.string().required(messages.error.required),
        number: yup.string().required(messages.error.required),
        neighborhood: yup.string().required(messages.error.required),
        city: yup.string().required(messages.error.required),
        state: yup.string().required(messages.error.required),
      });
      await schema.validate(formData, { abortEarly: false });
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <>
      <Modal size="6xl" isOpen={editSelected} onClose={() => setEditSelected()} scrollBehavior="inside" isCentered autoFocus={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Endereço</ModalHeader>
          <Divider />
          <ModalBody py={4}>
            <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
              <GridItem colSpan={{ base: 12, lg: 12 }}>
                <FormControl isRequired={true} isInvalid={formErrors.title}>
                  <FormLabel fontSize="sm">Título</FormLabel>
                  <Input
                    value={formData.title ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))}
                  />
                  <FormErrorMessage>{formErrors.title}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>

            <Address
              data={formData}
              errors={formErrors}
              isRequired={true}
              onChange={(data) => setFormData((state) => ({ ...state, ...data }))}
            />

            <Divider my={8} />

            <Heading size="sm" mb={6}>
              Localização
            </Heading>
            <LatLng location={formData.location} onChange={(location) => setFormData((state) => ({ ...state, location }))} />

            <Divider my={8} />

            <Heading size="sm" mb={6}>
              Reunião
            </Heading>
            <Grid templateColumns="repeat(12, 1fr)" gap={4}>
              <GridItem colSpan={{ base: 12, lg: 6 }}>
                <FormControl isRequired={true} isInvalid={formErrors.travelDurationInMinutes}>
                  <FormLabel fontSize="sm">Duração da viagem</FormLabel>
                  <InputGroup>
                    <Input
                      as={InputCurrency}
                      precision="0"
                      value={formData.travelDurationInMinutes ?? ""}
                      onChange={(travelDurationInMinutes) => setFormData((state) => ({ ...state, travelDurationInMinutes }))}
                    />
                    <InputRightAddon fontSize="sm">minutos</InputRightAddon>
                  </InputGroup>
                  <FormErrorMessage>{formErrors.travelDurationInMinutes}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 6 }}>
                <FormControl isRequired={true} isInvalid={formErrors.travelCostAmount}>
                  <FormLabel fontSize="sm">Custo de viagem</FormLabel>
                  <InputGroup>
                    <InputLeftAddon fontSize="sm">R$</InputLeftAddon>
                    <Input
                      as={InputCurrency}
                      precision="2"
                      value={formData.travelCostAmount ?? ""}
                      onChange={(travelCostAmount) => setFormData((state) => ({ ...state, travelCostAmount }))}
                    />
                  </InputGroup>
                  <FormErrorMessage>{formErrors.travelCostAmount}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
          </ModalBody>
          <Divider />
          <ModalFooter as={HStack} justifyContent="flex-end">
            <Button size="sm" variant="outline" onClick={() => setEditSelected()}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="main" onClick={handleSubmit}>
              adicionar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default Details;
