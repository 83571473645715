import React, { useState, useEffect, useMemo, useCallback } from "react";
import _ from "lodash";
import moment from "moment";
import ReactInputMask from "react-input-mask";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  SlideFade,
  Spinner,
  Text,
  Textarea,
} from "@chakra-ui/react";
import * as yup from "yup";
import { api } from "lib";
import { Breadcrumb } from "components";
import { useApiGet, useCustomToast, useDocumentTitle } from "hooks";
import { messages } from "consts";
import { MdCalendarToday, MdChevronLeft } from "react-icons/md";
import { Content } from "pages/Private/Container";

export const VersionsDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar versão" : "Nova versão");
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [data, isLoadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/versions/${_id}` }), [_id]));
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const toast = useCustomToast();

  useEffect(() => {
    const formData = data ?? {};
    formData.publishedAt = moment(formData.publishedAt).format("DD/MM/YYYY HH:mm");
    setFormData(formData);
  }, [data]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.patch(`/versions/${_id}`, data) : await api.put("/versions", data);
        navigate(`/settings/records/versions/edit/${saved._id}`, { replace: true });
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        if (error.isHandled) return;
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        versionCode: yup.string().required(messages.error.required),
        description: yup.string().required(messages.error.required),
        publishedAt: yup.date().required(messages.error.required),
      });
      const data = { ...formData, publishedAt: moment(formData.publishedAt, "DD/MM/YYYY HH:mm").toDate() };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <>
      <Content>
        <HStack justify="space-between">
          <HStack spacing={{ base: "10px", lg: "20px" }}>
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { to: "/versions", label: "versões" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Versão</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.versionCode : "Novo cadastro"}</Text>
          </Box>
        </HStack>

        <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
          <GridItem colSpan={{ base: 8, lg: 6 }}>
            <FormControl isRequired={true} isInvalid={formErrors.versionCode}>
              <FormLabel fontSize="sm">Código da versão</FormLabel>
              <Input
                value={formData.versionCode ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, versionCode: target.value }))}
              />
              <FormErrorMessage>{formErrors.versionCode}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 6 }}>
            <FormControl isRequired={true} isInvalid={formErrors.publishedAt}>
              <FormLabel fontSize="sm">Publicada em</FormLabel>
              <InputGroup>
                <InputLeftElement>
                  <Icon as={MdCalendarToday} />
                </InputLeftElement>
                <Input
                  as={ReactInputMask}
                  mask="99/99/9999 99:99"
                  value={formData.publishedAt ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, publishedAt: target.value }))}
                />
              </InputGroup>
              <FormErrorMessage>{formErrors.publishedAt}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 12 }}>
            <FormControl isRequired={true} isInvalid={formErrors.description}>
              <FormLabel fontSize="sm">Descrição</FormLabel>
              <Textarea
                minH="400px"
                value={formData.description ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, description: target.value }))}
              />
              <FormErrorMessage>{formErrors.description}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      </Content>

      <Divider />
      <SlideFade in={true} offsetY="20px">
        <HStack p="20px">
          <Button size="sm" colorScheme="main" isLoading={isLoadingData || isLoadingSaveData} onClick={handleSubmit}>
            salvar
          </Button>
          <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
            voltar
          </Button>
        </HStack>
      </SlideFade>
    </>
  );
};
