import React, { useContext } from "react";
import _ from "lodash";
import { Divider, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, Heading, Input, Select } from "@chakra-ui/react";
import InputMask from "react-input-mask";
import { Address, BoxData, EmailAutocomplete, PhoneInput } from "components";
import CustomersDetailsContext from "../context";
import Contacts from "./contacts";

const General = () => {
  const { formData, setFormData, formErrors, isActive } = useContext(CustomersDetailsContext);

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 4, lg: 2 }}>
          <BoxData label="NID" value={formData.nid?.toLocaleString() ?? "-"} />
        </GridItem>
        <GridItem colSpan={{ base: 8, lg: 2 }}>
          <FormControl isRequired={true} isInvalid={formErrors.nid}>
            <FormLabel fontSize="sm">Status</FormLabel>
            <Select value={formData.status} onChange={({ target }) => setFormData((state) => ({ ...state, status: target.value }))}>
              <option value="active">Ativo</option>
              <option value="inactive">Inativo</option>
            </Select>
            <FormErrorMessage>{formErrors.nid}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={true} isInvalid={formErrors.tradingName}>
            <FormLabel fontSize="sm">Nome</FormLabel>
            <Input
              value={formData.tradingName ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, tradingName: target.value }))}
            />
            <FormErrorMessage>{formErrors.tradingName}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={isActive} isInvalid={formErrors.companyName}>
            <FormLabel fontSize="sm">Razão social</FormLabel>
            <Input
              value={formData.companyName ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, companyName: target.value }))}
            />
            <FormErrorMessage>{formErrors.companyName}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 6, lg: 6 }}>
          <FormControl isRequired={isActive} isInvalid={formErrors.document}>
            <FormLabel fontSize="sm">CNPJ</FormLabel>
            <Input
              as={InputMask}
              mask="99.999.999/9999-99"
              value={formData.document ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, document: target.value }))}
            />
            <FormErrorMessage>{formErrors.document}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 6, lg: 6 }}>
          <FormControl isInvalid={formErrors.ie}>
            <FormLabel fontSize="sm">Inscrição estadual</FormLabel>
            <Input value={formData.ie ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, ie: target.value }))} />
            <FormErrorMessage>{formErrors.ie}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <Divider my={8} />

      <Heading size="sm" mb={6}>
        Contato
      </Heading>
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <PhoneInput
            label="Telefone 1"
            phone={formData.phone1}
            category={formData.phone1Category}
            onChangePhone={(phone1) => setFormData((state) => ({ ...state, phone1 }))}
            onChangeCategory={(phone1Category) => setFormData((state) => ({ ...state, phone1Category }))}
            isRequired={true}
            isInvalid={formErrors.phone1}
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <PhoneInput
            label="Telefone 2"
            phone={formData.phone2}
            category={formData.phone2Category}
            onChangePhone={(phone2) => setFormData((state) => ({ ...state, phone2 }))}
            onChangeCategory={(phone2Category) => setFormData((state) => ({ ...state, phone2Category }))}
            isRequired={false}
            isInvalid={formErrors.phone2}
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <PhoneInput
            label="Telefone 3"
            phone={formData.phone3}
            category={formData.phone3Category}
            onChangePhone={(phone3) => setFormData((state) => ({ ...state, phone3 }))}
            onChangeCategory={(phone3Category) => setFormData((state) => ({ ...state, phone3Category }))}
            isRequired={false}
            isInvalid={formErrors.phone3}
          />
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <FormControl isInvalid={formErrors.website}>
            <FormLabel fontSize="sm">WebSite</FormLabel>
            <Input
              value={formData.website ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, website: target.value }))}
            />
            <FormErrorMessage>{formErrors.website}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <FormControl isRequired={isActive} isInvalid={formErrors.email}>
            <FormLabel fontSize="sm">E-mail</FormLabel>
            <EmailAutocomplete value={formData.email ?? ""} onChange={(email) => setFormData((state) => ({ ...state, email }))} />
            <FormErrorMessage>{formErrors.email}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <Divider my={8} />

      <Address
        data={formData.address}
        errors={formErrors.address}
        isRequired={true}
        onChange={(address) => setFormData((state) => ({ ...state, address: { ...state.address, ...address } }))}
      />

      <Divider my={8} />

      <Contacts />
    </>
  );
};

export default General;
