import React, { useContext, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { MdRefresh } from "react-icons/md";
import { TbCalendarTime, TbClockExclamation } from "react-icons/tb";
import { LuAlarmClock, LuAlarmClockOff } from "react-icons/lu";
import MeetingsDetailsContext from "../context";

const Schedules = () => {
  const { formData, schedules, isLoadingSchedules, refreshSchedules } = useContext(MeetingsDetailsContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [visibility, setVisibility] = useState("duration");
  const data = useMemo(() => {
    switch (visibility) {
      case "all":
        return schedules;
      case "availables":
        return _.filter(schedules, (o) => o.isAvailable);
      case "duration":
        return _.filter(schedules, (o) => o.isAvailable && o.durationInMinutes >= (formData.predictedDurationInMinutes ?? 0));
    }
  }, [visibility, schedules, formData.predictedDurationInMinutes]);

  return (
    <>
      <HStack spacing="2px">
        <Button size="xs" variant="outline" leftIcon={<Icon as={TbCalendarTime} />} isLoading={isLoadingSchedules} onClick={onOpen}>
          disponibilidade
        </Button>
        <IconButton size="xs" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingSchedules} onClick={refreshSchedules} />
        <Modal size="lg" isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader as={HStack}>
              <Text flex="1">Disponibilidade de horários</Text>
              <Box>
                <Select size="sm" value={visibility} onChange={({ target }) => setVisibility(target.value)}>
                  <option value="all">Todos</option>
                  <option value="availables">Disponíveis</option>
                  <option value="duration">Duração suficiente</option>
                </Select>
              </Box>
            </ModalHeader>
            <ModalBody as={VStack} alignItems="stretch">
              {_.map(data, (item) => (
                <HStack
                  key={item.startDate.toString()}
                  alignItems="center"
                  justifyContent="center"
                  p="10px"
                  borderRadius="lg"
                  bg={item.isAvailable ? "green.100" : "gray.100"}
                  opacity={item.isAvailable ? 1 : 0.5}
                  color="gray.900"
                >
                  <Center w="31px" h="31px" borderRadius="full" bg={item.isAvailable ? "green.500" : "gray.200"}>
                    <Icon as={item.isAvailable ? LuAlarmClock : LuAlarmClockOff} color={item.isAvailable ? "white" : "gray.900"} />
                  </Center>
                  <VStack spacing={0} flex="1">
                    <Text fontSize="xs" fontWeight="semibold">
                      {moment(item.startDate).format("HH:mm")} às {moment(item.endDate).format("HH:mm")}
                    </Text>
                    <Box textAlign="center" bg="blackAlpha.100" px="20px" borderRadius="xl">
                      {item.isAvailable ? <Text fontSize="xs">Disponível</Text> : <Text fontSize="xs">Indisponível</Text>}
                    </Box>
                  </VStack>
                  {item.isAvailable && item.durationInMinutes < formData.predictedDurationInMinutes ? (
                    <Tooltip label="Este horário tem duração inferior à duração prevista da reunião.">
                      <IconButton size="sm" colorScheme="yellow" icon={<Icon as={TbClockExclamation} />} borderRadius="full" />
                    </Tooltip>
                  ) : (
                    <Box w="31px" />
                  )}
                </HStack>
              ))}
              {_.size(data) === 0 && (
                <Center p="30px">
                  <Box textAlign="center">
                    <Icon as={LuAlarmClockOff} boxSize={20} marginBottom="10px" />
                    <Heading size="xs">Nenhum horário disponível</Heading>
                    <Text fontSize="xs">Não existem horários disponíveis correspondentes à sua busca.</Text>
                  </Box>
                </Center>
              )}
            </ModalBody>
            <ModalFooter>
              <Button size="sm" variant="outline" onClick={onClose}>
                fechar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </HStack>
    </>
  );
};

export default Schedules;
