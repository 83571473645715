import { memo, useMemo } from "react";
import { Badge } from "@chakra-ui/react";
import { translator } from "lib";

export const Status = memo(({ status, ...props }) => {
  const colorScheme = useMemo(() => {
    switch (status) {
      case "prospect":
        return "blue";
      case "active":
        return "green";
      case "inactive":
        return "gray";
      default:
        return "gray";
    }
  }, [status]);
  return (
    <Badge colorScheme={colorScheme} {...props}>
      {translator(status)}
    </Badge>
  );
});
