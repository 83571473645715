import React, { useCallback, useContext, useEffect, useMemo } from "react";
import _ from "lodash";
import {
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
} from "@chakra-ui/react";
import { useApiGet } from "hooks";
import { InputCurrency, SyncSelect, BoxData } from "components";
import { translator, formatMinutes } from "lib";
import { paymentMethods } from "consts";
import { MdRefresh } from "react-icons/md";
import CustomersDetailsContext from "../context";
import MinutesUsageAlert from "./minutesUsageAlert";

const Preferences = () => {
  const { formData, setFormData, formErrors } = useContext(CustomersDetailsContext);
  const [accounts, isLoadingAccounts, refreshAccounts] = useApiGet(
    useMemo(() => ({ path: "/accounts", params: { query: { detailing: "analytical", isActive: true }, isAutocomplete: true } }), [])
  );
  const [costCenters, isLoadingCostCenters, refreshCostCenters] = useApiGet(
    useMemo(() => ({ path: "/cost-centers", params: { query: { isActive: true }, isAutocomplete: true } }), [])
  );

  const setPrefs = useCallback(
    (key, obj) => {
      setFormData((state) => {
        let prefs = { ...state.prefs };
        prefs[key] = { ...prefs[key], ...obj };
        return { ...state, prefs };
      });
    },
    [setFormData]
  );

  useEffect(() => {
    const minutesUsageAvailableMonthly = (() => {
      try {
        if (formData.prefs.consultations.hourPrice === 0) return 0;
        const hoursUsageAvailableMonthly = _.divide(formData.prefs.monthlyPayments.amount, formData.prefs.consultations.hourPrice) || 0;
        return hoursUsageAvailableMonthly * 60;
      } catch (error) {
        return 0;
      }
    })();
    setPrefs("consultations", { minutesUsageAvailableMonthly });
  }, [formData.prefs?.monthlyPayments?.amount, formData.prefs?.consultations?.hourPrice]);

  return (
    <>
      <Heading size="sm" mb={6}>
        Mensalidade
      </Heading>
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={true} isInvalid={formErrors.prefs?.monthlyPayments?.paymentMethod}>
            <FormLabel fontSize="sm">Método de pagamento</FormLabel>
            <Select
              value={formData.prefs?.monthlyPayments?.paymentMethod ?? ""}
              onChange={({ target }) => setPrefs("monthlyPayments", { paymentMethod: target.value })}
            >
              <option value="">--Selecione</option>
              {paymentMethods.map((value) => (
                <option key={value} value={value}>
                  {translator(value)}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{formErrors.prefs?.monthlyPayments?.paymentMethod}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={true} isInvalid={formErrors.prefs?.monthlyPayments?.chartOfAccount}>
            <FormLabel fontSize="sm">Plano de contas</FormLabel>
            <HStack>
              <SyncSelect
                value={formData.prefs?.monthlyPayments?.chartOfAccount}
                options={accounts?.data}
                placeholder="Selecione"
                selectedOptionStyle="check"
                onChange={(chartOfAccount) => setPrefs("monthlyPayments", { chartOfAccount })}
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ code, name }) => `${code} - ${name}`}
                isClearable={true}
              />
              <IconButton variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingAccounts} onClick={refreshAccounts} />
            </HStack>
            <FormErrorMessage>{formErrors.prefs?.monthlyPayments?.chartOfAccount}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={true} isInvalid={formErrors.prefs?.monthlyPayments?.costCenter}>
            <FormLabel fontSize="sm">Centro de custos</FormLabel>
            <HStack>
              <SyncSelect
                value={formData.prefs?.monthlyPayments?.costCenter}
                options={costCenters?.data}
                placeholder="Selecione"
                selectedOptionStyle="check"
                onChange={(costCenter) => setPrefs("monthlyPayments", { costCenter })}
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ title }) => title}
                isClearable={true}
              />
              <IconButton variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingCostCenters} onClick={refreshCostCenters} />
            </HStack>
            <FormErrorMessage>{formErrors.prefs?.monthlyPayments?.costCenter}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <FormControl isRequired={true} isInvalid={formErrors.prefs?.monthlyPayments?.amount}>
            <FormLabel fontSize="sm">Valor</FormLabel>
            <InputGroup>
              <InputLeftAddon>R$</InputLeftAddon>
              <Input
                as={InputCurrency}
                value={formData.prefs?.monthlyPayments?.amount ?? ""}
                onChange={(amount) => setPrefs("monthlyPayments", { amount })}
              />
            </InputGroup>
            <FormErrorMessage>{formErrors.prefs?.monthlyPayments?.amount}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <FormControl isRequired={true} isInvalid={formErrors.prefs?.monthlyPayments?.dueDay}>
            <FormLabel fontSize="sm">Dia de vencimento</FormLabel>
            <Input
              as={InputCurrency}
              precision="0"
              value={formData.prefs?.monthlyPayments?.dueDay ?? ""}
              onChange={(dueDay) => setPrefs("monthlyPayments", { dueDay })}
            />
            <FormErrorMessage>{formErrors.prefs?.monthlyPayments?.dueDay}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <Divider my={8} />

      <Heading size="sm" mb={6}>
        Consultoria
      </Heading>
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={true} isInvalid={formErrors.prefs?.consultations?.hourPrice}>
            <FormLabel fontSize="sm">Valor de hora ideal</FormLabel>
            <InputGroup>
              <InputLeftAddon>R$</InputLeftAddon>
              <Input
                as={InputCurrency}
                value={formData.prefs?.consultations?.hourPrice ?? ""}
                onChange={(hourPrice) => setPrefs("consultations", { hourPrice })}
              />
            </InputGroup>
            <FormErrorMessage>{formErrors.prefs?.consultations?.hourPrice}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <BoxData label="Horas ideal" value={formatMinutes(formData.prefs?.consultations?.minutesUsageAvailableMonthly)} />
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <BoxData
            label="Horas utilizadas neste mês"
            value={formatMinutes(formData.prefs?.consultations?.minutesUsageInCurrentMonth || 0)}
            RightComponent={<MinutesUsageAlert />}
          />
        </GridItem>
      </Grid>

      <Divider my={8} />

      <Heading size="sm" mb={6}>
        Custo de viagem
      </Heading>
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={true} isInvalid={formErrors.prefs?.travelCosts?.paymentMethod}>
            <FormLabel fontSize="sm">Método de pagamento</FormLabel>
            <Select
              value={formData.prefs?.travelCosts?.paymentMethod ?? ""}
              onChange={({ target }) => setPrefs("travelCosts", { paymentMethod: target.value })}
            >
              <option value="">--Selecione</option>
              {paymentMethods.map((value) => (
                <option key={value} value={value}>
                  {translator(value)}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{formErrors.prefs?.travelCosts?.paymentMethod}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={true} isInvalid={formErrors.prefs?.travelCosts?.chartOfAccount}>
            <FormLabel fontSize="sm">Plano de contas</FormLabel>
            <HStack>
              <SyncSelect
                value={formData.prefs?.travelCosts?.chartOfAccount}
                options={accounts?.data}
                placeholder="Selecione"
                selectedOptionStyle="check"
                onChange={(chartOfAccount) => setPrefs("travelCosts", { chartOfAccount })}
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ code, name }) => `${code} - ${name}`}
                isClearable={true}
              />
              <IconButton variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingAccounts} onClick={refreshAccounts} />
            </HStack>
            <FormErrorMessage>{formErrors.prefs?.travelCosts?.chartOfAccount}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={true} isInvalid={formErrors.prefs?.travelCosts?.costCenter}>
            <FormLabel fontSize="sm">Centro de custos</FormLabel>
            <HStack>
              <SyncSelect
                value={formData.prefs?.travelCosts?.costCenter}
                options={costCenters?.data}
                placeholder="Selecione"
                selectedOptionStyle="check"
                onChange={(costCenter) => setPrefs("travelCosts", { costCenter })}
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ title }) => title}
                isClearable={true}
              />
              <IconButton variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingCostCenters} onClick={refreshCostCenters} />
            </HStack>
            <FormErrorMessage>{formErrors.prefs?.travelCosts?.costCenter}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>
    </>
  );
};

export default Preferences;
