import React, { Fragment, useCallback, useContext, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import ReactInputMask from "react-input-mask";
import { Link as RouterLink, useLocation } from "react-router-dom";
import {
  Avatar,
  Box,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Select,
  Text,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import { useApiGet, useCustomToast } from "hooks";
import { messages } from "consts";
import { PermissionedContainer, AsyncSelect, BoxData, InputCurrency, SingleDatePicker, SyncSelect, CustomerLogo } from "components";
import { api, masks, translator } from "lib";
import { MdCalendarToday, MdClose, MdRefresh, MdSearch } from "react-icons/md";
import { TbExternalLink } from "react-icons/tb";
import TasksDetailsContext from "./context";
import { BsQuestionCircle } from "react-icons/bs";

let loadCustomersTimeout;

const General = () => {
  const location = useLocation();
  const { formData, setFormData, formErrors } = useContext(TasksDetailsContext);
  const [teams, isLoadingTeams, refreshTeams] = useApiGet(
    useMemo(
      () => ({
        path: "/teams",
        params: {
          query: { isActive: true },
          sort: { title: 1 },
          perPage: -1,
          isAutocomplete: true,
        },
      }),
      []
    )
  );
  const options = useMemo(() => {
    const finded = _.find(teams?.data, (o) => o._id === formData.team?._id);
    return { users: finded?.users ?? [], demands: finded?.demands ?? [] };
  }, [formData.team?._id, teams?.data]);
  const [isLoadingMeeting, setIsLoadingMeeting] = useState(false);
  const toast = useCustomToast();

  const handleLoadCustomers = useCallback((search, cb) => {
    clearTimeout(loadCustomersTimeout);
    loadCustomersTimeout = setTimeout(async () => {
      const params = {
        search,
        query: { status: { $ne: "inactive" } },
        sort: { tradingName: 1 },
        perPage: 20,
        isAutocomplete: true,
      };
      const response = await api.post("/customers", params);
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleChangeDemand = useCallback((demand) => {
    setFormData((state) => {
      const responsible = _.find(state.team.users, (o) => o._id === demand.responsible);
      return { ...state, demand, responsible };
    });
  }, []);

  const handleSearchMeeting = useCallback(async () => {
    try {
      setIsLoadingMeeting(true);
      const query = { nid: formData.meetingNid };
      const { data } = await api.post("/meetings", { query });
      if (data.length == 0) throw new Error(messages.error.meetingNotFound);
      setFormData((state) => ({ ...state, meeting: data[0], meetingNid: undefined }));
    } catch (error) {
      if (error.isHandled) return;
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingMeeting(false);
    }
  }, [formData.meetingNid]);

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 4, lg: 2 }}>
          <BoxData label="NID" value={formData.nid?.toLocaleString() ?? "-"} />
        </GridItem>
        <GridItem colSpan={{ base: 4, lg: 2 }}>
          {["unscheduled", "pending"].indexOf(formData.status) !== -1 ? (
            <FormControl isRequired={true} isInvalid={formErrors.status}>
              <FormLabel fontSize="sm">Status</FormLabel>
              <Select value={formData.status ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, status: target.value }))}>
                <option value="unscheduled">Previsto</option>
                <option value="pending">Pendente</option>
              </Select>
              <FormErrorMessage>{formErrors.status}</FormErrorMessage>
            </FormControl>
          ) : (
            <BoxData label="Status" value={translator(formData.status)} />
          )}
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 5 }}>
          <FormControl isRequired={true} isInvalid={formErrors.customer}>
            <HStack mb="0.5em" justifyContent="space-between">
              <FormLabel fontSize="sm" m="0">
                Cliente
              </FormLabel>
              {formData.customer ? (
                <PermissionedContainer required="customers:update">
                  <Link as={RouterLink} to={`/customers/edit/${formData.customer._id}`} target="_blank" fontSize="xs">
                    editar cadastro
                  </Link>
                </PermissionedContainer>
              ) : (
                <PermissionedContainer required="customers:create">
                  <Link as={RouterLink} to={`/customers/new`} target="_blank" color="blue.500" fontSize="xs">
                    incluir cadastro
                  </Link>
                </PermissionedContainer>
              )}
            </HStack>
            <AsyncSelect
              value={formData.customer}
              defaultOptions
              loadOptions={handleLoadCustomers}
              placeholder="Selecione"
              onChange={(customer) => setFormData((state) => ({ ...state, customer }))}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ logoUrl, tradingName, segment }) => (
                <HStack>
                  <CustomerLogo alt={tradingName} src={logoUrl} boxSize="35px" />
                  <Box flex="1">
                    <Text fontSize="sm" noOfLines={1}>
                      {tradingName}
                    </Text>
                    <Text fontSize="xs">{segment?.title || "-"}</Text>
                  </Box>
                </HStack>
              )}
              isClearable={true}
              isDisabled={_.isObject(location.state?.customer)}
            />
            <FormErrorMessage>{formErrors.customer}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 3 }}>
          {formData.meeting ? (
            <HStack borderWidth="1px" p="13px" borderRadius="lg" spacing="15px">
              <IconButton
                as={RouterLink}
                to={`/meetings/edit/${formData.meeting._id}`}
                target="_blank"
                size="sm"
                variant="outline"
                flex={{ base: "1", lg: "none" }}
                icon={<Icon as={TbExternalLink} />}
              />
              <Box flex="1">
                <Text fontSize="xs">Reunião relacionada</Text>
                <Text fontWeight="semibold">{formData.meeting.nid}</Text>
              </Box>
              <IconButton
                size="sm"
                variant="outline"
                icon={<Icon as={MdClose} />}
                onClick={() => setFormData((state) => ({ ...state, meeting: null }))}
              />
            </HStack>
          ) : (
            <FormControl isInvalid={formErrors.meetingNid}>
              <FormLabel fontSize="sm">Reunião relacionada</FormLabel>
              <InputGroup>
                <InputLeftElement>
                  <Tooltip label="Caso esta tarefa seja relacionada a alguma reunião, informe o NID no campo acima e clique na lupa para realizar a busca.">
                    <IconButton size="sm" variant="ghost" icon={<Icon as={BsQuestionCircle} />} />
                  </Tooltip>
                </InputLeftElement>
                <Input
                  as={InputCurrency}
                  precision="0"
                  value={formData.meetingNid || null}
                  onChange={(meetingNid) => setFormData((state) => ({ ...state, meetingNid }))}
                />
                <InputRightElement>
                  <IconButton
                    size="sm"
                    variant="ghost"
                    icon={<Icon as={MdSearch} />}
                    isLoading={isLoadingMeeting}
                    onClick={handleSearchMeeting}
                  />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>{formErrors.meetingNid}</FormErrorMessage>
            </FormControl>
          )}
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={true} isInvalid={formErrors.team}>
            <HStack mb="0.5em" justifyContent="space-between">
              <FormLabel fontSize="sm" m="0">
                Time
              </FormLabel>
              {formData.team ? (
                <PermissionedContainer required="teams:update">
                  <Link as={RouterLink} to={`/settings/records/teams/edit/${formData.team._id}`} target="_blank" fontSize="xs">
                    editar cadastro
                  </Link>
                </PermissionedContainer>
              ) : (
                <PermissionedContainer required="teams:create">
                  <Link as={RouterLink} to={`/settings/records/teams/new`} target="_blank" color="blue.500" fontSize="xs">
                    incluir cadastro
                  </Link>
                </PermissionedContainer>
              )}
            </HStack>
            <HStack>
              <SyncSelect
                value={formData.team}
                options={teams?.data}
                placeholder="Selecione"
                onChange={(team) => setFormData((state) => ({ ...state, team, responsible: null, demand: null }))}
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ title }) => title}
                isClearable={true}
              />
              <IconButton variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingTeams} onClick={refreshTeams} />
            </HStack>
            <FormErrorMessage>{formErrors.team}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={true} isInvalid={formErrors.demand}>
            <HStack mb="0.5em" justifyContent="space-between">
              <FormLabel fontSize="sm" m="0">
                Demanda
              </FormLabel>
              {formData.demand ? (
                <PermissionedContainer required="demands:update">
                  <Link as={RouterLink} to={`/settings/records/demands/edit/${formData.demand._id}`} target="_blank" fontSize="xs">
                    editar cadastro
                  </Link>
                </PermissionedContainer>
              ) : (
                <PermissionedContainer required="demands:create">
                  <Link as={RouterLink} to={`/settings/records/demands/new`} target="_blank" color="blue.500" fontSize="xs">
                    incluir cadastro
                  </Link>
                </PermissionedContainer>
              )}
            </HStack>
            <SyncSelect
              value={formData.demand}
              options={options.demands}
              placeholder="Selecione"
              onChange={handleChangeDemand}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ title, informedAvgDurationInMinutes, calculatedAvgDurationInMinutes }) => (
                <Box>
                  <Text fontSize="sm">{title}</Text>
                  <Text fontSize="xs">
                    Infor. {informedAvgDurationInMinutes?.toLocaleString() ?? "-"} minutos | Calc.{" "}
                    {calculatedAvgDurationInMinutes?.toLocaleString() ?? "-"} minutos
                  </Text>
                </Box>
              )}
              isDisabled={!formData.team}
            />
            <FormErrorMessage>{formErrors.demand}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={true} isInvalid={formErrors.responsible}>
            <HStack mb="0.5em" justifyContent="space-between">
              <FormLabel fontSize="sm" m="0">
                Responsável
              </FormLabel>
              {formData.responsible ? (
                <PermissionedContainer required="users:update">
                  <Link as={RouterLink} to={`/users/edit/${formData.responsible._id}`} target="_blank" fontSize="xs">
                    editar cadastro
                  </Link>
                </PermissionedContainer>
              ) : (
                <PermissionedContainer required="users:create">
                  <Link as={RouterLink} to={`/users/new`} target="_blank" color="blue.500" fontSize="xs">
                    incluir cadastro
                  </Link>
                </PermissionedContainer>
              )}
            </HStack>
            <SyncSelect
              value={formData.responsible}
              options={options.users}
              placeholder="Selecione"
              onChange={(responsible) => setFormData((state) => ({ ...state, responsible }))}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ avatarUrl, name }) => (
                <HStack>
                  <Avatar size="xs" name={name} src={avatarUrl} />
                  <Text>{name}</Text>
                </HStack>
              )}
              isClearable={true}
              isDisabled={!formData.team}
            />
            <FormErrorMessage>{formErrors.responsible}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 3 }}>
          <FormControl isRequired={true} isInvalid={formErrors.dueDate}>
            <FormLabel fontSize="sm">Vencimento</FormLabel>
            <SingleDatePicker
              date={formData.dueDate}
              onDateChange={(dueDate) =>
                setFormData((state) => ({
                  ...state,
                  dueDate,
                  referenceDate: moment(dueDate).startOf("month").format("MM/YYYY"),
                }))
              }
            />
            <FormErrorMessage>{formErrors.dueDate}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 3 }}>
          <FormControl isRequired={true} isInvalid={formErrors.referenceDate}>
            <FormLabel fontSize="sm">Mês de referência</FormLabel>
            <Input
              as={ReactInputMask}
              mask="99/9999"
              value={formData.referenceDate ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, referenceDate: target.value }))}
            />
            <FormErrorMessage>{formErrors.referenceDate}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 3 }}>
          <BoxData label="Tempo pausado" value={`${formData.pausedDurationInMinutes?.toLocaleString() ?? 0} minutos`} />
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 3 }}>
          <BoxData label="Duração" value={`${formData.durationInMinutes?.toLocaleString() ?? 0} minutos`} />
        </GridItem>
      </Grid>

      <Divider my={8} />

      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 12, lg: 12 }}>
          <FormControl isRequired={true} isInvalid={formErrors.title}>
            <FormLabel fontSize="sm">Título</FormLabel>
            <Input value={formData.title ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))} />
            <FormErrorMessage>{formErrors.title}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 12 }}>
          <FormControl isRequired={true} isInvalid={formErrors.description}>
            <FormLabel fontSize="sm">Descrição</FormLabel>
            <Textarea
              minH="200px"
              value={formData.description ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, description: target.value }))}
            />
            <FormErrorMessage>{formErrors.description}</FormErrorMessage>
          </FormControl>
        </GridItem>

        {["started", "finished", "canceled"].map((status) => {
          const keys = { by: status.concat("By"), at: status.concat("At") };
          return (
            formData[keys.by] && (
              <Fragment key={status}>
                <GridItem colSpan={{ base: 12, lg: 6 }}>
                  <BoxData label={`${translator(status)} por`} value={formData[keys.by]?.name ?? "-"} />
                </GridItem>
                <GridItem colSpan={{ base: 12, lg: 6 }}>
                  <FormControl isRequired={true} isInvalid={formErrors[keys.at]}>
                    <FormLabel fontSize="sm">{translator(status)} em</FormLabel>
                    <InputGroup>
                      <InputLeftElement>
                        <Icon as={MdCalendarToday} />
                      </InputLeftElement>
                      <Input
                        as={ReactInputMask}
                        mask="99/99/9999 99:99"
                        value={formData[keys.at] ?? ""}
                        onChange={({ target }) => setFormData((state) => ({ ...state, [keys.at]: target.value }))}
                      />
                    </InputGroup>
                    <FormErrorMessage>{formErrors[keys.at]}</FormErrorMessage>
                  </FormControl>
                </GridItem>
              </Fragment>
            )
          );
        })}
      </Grid>

      {["finished", "canceled"].indexOf(formData.status) !== -1 && (
        <Grid templateColumns="repeat(12, 1fr)" gap={4} mt={4}>
          <GridItem colSpan={{ base: 12, lg: 12 }}>
            <FormControl isRequired={true} isInvalid={formErrors.notes}>
              <FormLabel fontSize="sm">Notas</FormLabel>
              <Textarea
                minH="200px"
                value={formData.notes ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, notes: target.value }))}
              />
              <FormErrorMessage>{formErrors.notes}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      )}
    </>
  );
};

export default General;
