import React, { useState, useEffect, useMemo, useCallback } from "react";
import _ from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  SlideFade,
  Spinner,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { MdChevronLeft, MdHistory, MdRefresh } from "react-icons/md";
import * as yup from "yup";
import { messages } from "consts";
import { api } from "lib";
import { useApiGet, useCustomToast, useDocumentTitle } from "hooks";
import { BoxData, Breadcrumb, DocumentHistory, EmailAutocomplete, SyncSelect, PermissionedContainer } from "components";
import { Content } from "pages/Private/Container";

export const UsersDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar usuário" : "Novo usuário");
  const navigate = useNavigate();
  const location = useLocation();
  const [data, isLoadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/users/${_id}` }), [_id]));
  const [userGroups, isLoadingUserGroups, refreshUserGroups] = useApiGet(
    useMemo(() => ({ path: "/user-groups", params: { query: { isActive: true } } }), [])
  );
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const toast = useCustomToast();

  useEffect(() => {
    const formData = data ?? { isActive: true };
    setFormData(formData);
  }, [data]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.patch(`/users/${_id}`, data) : await api.put("/users", data);
        navigate(`/users/edit/${saved._id}`, { replace: true });
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        if (error.isHandled) return;
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        name: yup.string().required(messages.error.required),
        email: yup.string().required(messages.error.required).email(messages.error.invalidEmail),
        userGroup: yup.string().required(messages.error.required),
      });
      const data = { ...formData, userGroup: formData.userGroup?._id };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <>
      <Content>
        <HStack justify="space-between">
          <HStack spacing={{ base: "10px", lg: "20px" }}>
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "cadastros" },
                { to: "/users", label: "usuários" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          {_id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Usuário</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.name : "Novo cadastro"}</Text>
          </Box>
          <HStack>
            <FormControl display="flex" alignItems="center">
              <FormLabel fontSize="sm" mb="0">
                Ativo?
              </FormLabel>
              <Switch
                colorScheme="main"
                isChecked={formData.isActive}
                onChange={() => setFormData((state) => ({ ...state, isActive: !state.isActive }))}
              />
            </FormControl>
          </HStack>
        </HStack>

        <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
          <GridItem colSpan={{ base: 4, lg: 2 }}>
            <BoxData label="NID" value={formData.nid?.toLocaleString() ?? "-"} />
          </GridItem>
          <GridItem colSpan={{ base: 8, lg: 10 }}>
            <FormControl isRequired={true} isInvalid={formErrors.name}>
              <FormLabel fontSize="sm">Nome</FormLabel>
              <Input value={formData.name ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, name: target.value }))} />
              <FormErrorMessage>{formErrors.name}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={{ base: 12, lg: 6 }}>
            <FormControl isRequired={true} isInvalid={formErrors.email}>
              <FormLabel fontSize="sm">E-mail</FormLabel>
              <EmailAutocomplete value={formData.email ?? ""} onChange={(email) => setFormData((state) => ({ ...state, email }))} />
              <FormErrorMessage>{formErrors.email}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 6 }}>
            <FormControl isRequired={true} isInvalid={formErrors.userGroup}>
              <FormLabel fontSize="sm">Grupo de usuário</FormLabel>
              <HStack>
                <SyncSelect
                  value={formData.userGroup}
                  options={userGroups?.data}
                  placeholder="Selecione"
                  selectedOptionStyle="check"
                  onChange={(userGroup) => setFormData((state) => ({ ...state, userGroup }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ title }) => title}
                  isClearable={true}
                />
                <IconButton variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingUserGroups} onClick={refreshUserGroups} />
              </HStack>
              <FormErrorMessage>{formErrors.userGroup}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      </Content>

      <PermissionedContainer required={"users:".concat(_id ? "update" : "create")}>
        <Divider />
        <SlideFade in={true} offsetY="20px">
          <HStack p="20px">
            <Button size="sm" colorScheme="main" isLoading={isLoadingData || isLoadingSaveData} onClick={handleSubmit}>
              salvar
            </Button>
            <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
              voltar
            </Button>
          </HStack>
        </SlideFade>
      </PermissionedContainer>

      {_id && <DocumentHistory path={`/users/${_id}/history`} isOpen={isOpenDocumentHistory} onClose={onCloseDocumentHistory} />}
    </>
  );
};
