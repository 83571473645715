export class Consultant {
  constructor(entity, totalMinutesInMonth) {
    this._id = entity._id;
    this.name = entity.name;
    this.totalMinutesInMonth = totalMinutesInMonth;
    this.quantity = 0;
    this.duration = 0;
  }
  incQuantity() {
    this.quantity += 1;
  }
  incDuration(value) {
    this.duration += value;
  }
  get percentage() {
    return this.duration / this.totalMinutesInMonth;
  }
  get remainingPercentage() {
    return 1 - this.percentage;
  }
  get remainingDuration() {
    return this.totalMinutesInMonth - this.duration;
  }
  get avgDuration() {
    return this.duration / this.quantity;
  }
}
