import React, { useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { Box, Center, HStack, Icon, Button, Text, useDisclosure, IconButton, Tooltip } from "@chakra-ui/react";
import { useApiGet, usePermissioned } from "hooks";
import { FileList, FileUpload, PermissionedContainer } from "components";
import { MdAttachFile, MdRefresh } from "react-icons/md";
import { FaRegFolderOpen } from "react-icons/fa";
import CustomersDetailsContext from "../context";

const Files = () => {
  const { _id } = useParams();
  const { setCounters, setIsLoadingCounters } = useContext(CustomersDetailsContext);
  const [files, isLoadingFiles, refreshFiles] = useApiGet(
    useMemo(
      () => ({
        path: `/files`,
        params: { query: { owner: _id, group: { $ne: "logo" } }, perPage: -1 },
        options: { isEnabled: !!_id },
      }),
      [_id]
    )
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isAllowed = {
    filesUpdate: usePermissioned("customers:files:update"),
    filesDelete: usePermissioned("customers:files:delete"),
  };

  useEffect(() => {
    setCounters((state) => ({ ...state, files: files?.size ?? 0 }));
  }, [setCounters, files?.size]);

  useEffect(() => {
    setIsLoadingCounters((state) => ({ ...state, files: isLoadingFiles }));
  }, [setIsLoadingCounters, isLoadingFiles]);

  return (
    <>
      <HStack mb="20px">
        <Text fontSize="sm" fontWeight="semibold" flex="1">
          {files?.size ?? 0} arquivos adicionados
        </Text>
        <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingFiles} onClick={refreshFiles} />
        <PermissionedContainer required="customers:files:create">
          <Tooltip
            placement="left"
            isDisabled={_id}
            shouldWrapChildren
            label="Antes de adicionar arquivos você deve salvar o cliente atual."
          >
            <Button size="sm" colorScheme="main" rightIcon={<Icon as={MdAttachFile} />} isDisabled={!_id} onClick={onOpen}>
              incluir arquivos
            </Button>
          </Tooltip>
        </PermissionedContainer>
      </HStack>

      <FileList
        path="/files"
        data={files?.data}
        onChange={refreshFiles}
        isEditable={isAllowed.filesUpdate}
        isDeletable={isAllowed.filesDelete}
      />

      {_.size(files?.data) === 0 && (
        <Center paddingTop="40px" paddingBottom="20px">
          <Box textAlign="center">
            <Icon as={FaRegFolderOpen} boxSize={20} marginBottom="10px" />
            <Text fontSize="lg" fontWeight="bold">
              Nenhum arquivo adicionado
            </Text>
            <Text fontSize="sm">Você ainda não adicionou arquivos neste cliente.</Text>
          </Box>
        </Center>
      )}

      <FileUpload path="/files" ownerRef="Customer" owner={_id} isOpen={isOpen} onClose={onClose} onChange={refreshFiles} />
    </>
  );
};

export default Files;
