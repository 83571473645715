import moment from "moment";
import { HStack, Link, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { CustomBadge, CustomerLogo } from "components";
import { translator } from "lib";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "status",
    title: "Status",
    isVisible: true,
    formatter: ({ status }) => <CustomBadge.CustomerIndicators.Status status={status} />,
    exporter: ({ status }) => translator(status),
  },
  {
    accessor: "referenceDate",
    title: "Data de referência",
    isVisible: true,
    formatter: ({ referenceDate }) => moment(referenceDate).format("DD/MM/YYYY"),
  },
  {
    accessor: "periodType",
    title: "Tipo de período",
    isVisible: true,
    formatter: ({ periodType }) => translator(periodType),
  },
  {
    accessor: "periodStartDate",
    title: "Período (inicial)",
    isVisible: true,
    formatter: ({ periodStartDate }) => moment(periodStartDate).format("DD/MM/YYYY"),
  },
  {
    accessor: "periodEndDate",
    title: "Período (final)",
    isVisible: true,
    formatter: ({ periodEndDate }) => moment(periodEndDate).format("DD/MM/YYYY"),
  },
  {
    accessor: "customer",
    title: "Cliente",
    isVisible: true,
    isSortable: false,
    render: ({ customer }) => (
      <HStack>
        <CustomerLogo boxSize="30px" alt={customer?.tradingName} src={customer?.logoUrl} />
        <Text>{customer?.tradingName}</Text>
      </HStack>
    ),
  },
  {
    accessor: "createdBy",
    title: "Criado por",
    isVisible: true,
    isSortable: false,
    formatter: ({ createdBy }) => createdBy?.name,
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
