import React, { useCallback, useEffect, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import {
  Box,
  Button,
  Center,
  Divider,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  StackDivider,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useApiGet } from "hooks";
import { MdRefresh } from "react-icons/md";
import { TbCalendarDue, TbExternalLink } from "react-icons/tb";

const Delayeds = () => {
  const [data, isLoadingData, refreshData] = useApiGet(useMemo(() => ({ path: "/meetings/delayeds" }), []));
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (_.size(data) >= 1) {
      const dismissedAt = localStorage.getItem("delayeds-meetings-dismissed-at") || 0;
      const duration = moment.duration(moment().diff(parseInt(dismissedAt)));
      if (duration.asHours() >= 1) onOpen();
    }
  }, [data, onOpen]);

  const handleClose = useCallback(() => {
    localStorage.setItem("delayeds-meetings-dismissed-at", new Date().getTime());
    onClose();
  }, [onClose]);

  return (
    <Modal isOpen={isOpen} closeOnEsc={false} closeOnOverlayClick={false} isCentered scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent bg="red.600">
        <ModalHeader as={HStack}>
          <Center bg="red.500" w="50px" h="50px" borderRadius="full">
            <Icon as={TbCalendarDue} color="white" boxSize="30px" />
          </Center>
          <Box flex="1">
            <Text fontSize="md" color="white">
              Atrasados
            </Text>
            <Text fontSize="xs" color="white" fontWeight="normal">
              Estas são as suas reuniões em atraso segundo o horário de agendamento.
            </Text>
          </Box>
          <IconButton size="sm" colorScheme="red" icon={<Icon as={MdRefresh} />} isLoading={isLoadingData} onClick={refreshData} />
        </ModalHeader>
        <Divider borderColor="blackAlpha.200" />
        <ModalBody as={VStack} alignItems="stretch" divider={<StackDivider borderColor="blackAlpha.200" />}>
          {_.map(data, (item) => (
            <HStack key={item._id}>
              <HStack flex="1" spacing={2}>
                <Text color="white" fontSize="sm">
                  {item.nid}
                </Text>
                <Text color="white">•</Text>
                <Text color="white" fontSize="sm">
                  {item.customer.tradingName}
                </Text>
              </HStack>
              <Button
                as={RouterLink}
                to={`/meetings/edit/${item._id}`}
                target="_blank"
                size="sm"
                colorScheme="whiteAlpha"
                rightIcon={<Icon as={TbExternalLink} />}
              >
                abrir
              </Button>
            </HStack>
          ))}
        </ModalBody>
        <Divider borderColor="blackAlpha.200" />
        <ModalFooter as={HStack}>
          <Button size="sm" colorScheme="red" onClick={handleClose}>
            lembre-me mais tarde
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Delayeds;
