import { memo } from "react";
import ReactDOM from "react-dom";
import { Box, HStack, Icon, IconButton, Text, VStack, useColorModeValue } from "@chakra-ui/react";
import { MdClose } from "react-icons/md";

const portal = document.createElement("div");
document.body.appendChild(portal);

const ListItem = memo(({ provided, snapshot, index, title, onRemove }) => {
  const backgroundColor = useColorModeValue("white", "gray.700");
  const hoverBackgroundColor = useColorModeValue("gray.100", "gray.800");
  const activeBackgroundColor = useColorModeValue("gray.200", "gray.900");

  const child = (
    <HStack
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      py="5px"
      px="10px"
      borderRadius="md"
      borderWidth="1px"
      background={snapshot.isDragging ? activeBackgroundColor : backgroundColor}
      _hover={{ backgroundColor: hoverBackgroundColor }}
      _active={{ backgroundColor: activeBackgroundColor }}
    >
      <VStack w={`${15 * (index + 1)}px`} spacing="1px">
        {[0, 1].map((key) => (
          <Box key={key.toString()} w="100%" h="1px" _light={{ bg: "gray.900" }} _dark={{ bg: "white" }} borderRadius="1px" />
        ))}
      </VStack>
      <Text flex="1" fontSize="xs">
        {title}
      </Text>
      <IconButton size="xs" icon={<Icon as={MdClose} />} isRound onClick={onRemove} />
    </HStack>
  );

  if (!snapshot.isDragging) return child;
  return ReactDOM.createPortal(child, portal);
});

export default ListItem;
