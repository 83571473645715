import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { Flex, Box, useDisclosure } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { AppContext } from "AppProvider";
import Toolbar from "./toolbar";
import Sidebar from "./sidebar";

export const ContainerContext = createContext();

export const Content = ({ children, ...props }) => {
  const { setContentScrollTop } = useContext(ContainerContext);
  const contentRef = useRef();

  useEffect(() => {
    const handleScroll = () => setContentScrollTop(contentRef.current?.scrollTop || 0);
    contentRef.current?.addEventListener("scroll", handleScroll);
    return () => contentRef.current?.removeEventListener("scroll", handleScroll);
  }, [setContentScrollTop, contentRef.current]);

  return (
    <Box ref={contentRef} flex="1" p="10px" w="100%" overflow="auto" {...props}>
      {children}
    </Box>
  );
};

export const ContentHeader = ({ children, ...props }) => {
  return (
    <Box position="sticky" left="0" zIndex="1" {...props}>
      {children}
    </Box>
  );
};

export const ContentBody = ({ children, ...props }) => {
  return <Box {...props}>{children}</Box>;
};

const Container = () => {
  const { isMobileView } = useContext(AppContext);
  const { isOpen: isOpenSidebar, onOpen: onOpenSidebar, onClose: onCloseSidebar } = useDisclosure();
  const [innerHeight, setInnerHeight] = useState("100vh");
  const [contentScrollTop, setContentScrollTop] = useState(0);

  useEffect(() => {
    const onInnerHeight = () => setInnerHeight(`${window.innerHeight}px`);
    onInnerHeight();
    window.addEventListener("resize", onInnerHeight);
    window.addEventListener("orientationchange", onInnerHeight);
    return () => {
      window.removeEventListener("resize", onInnerHeight);
      window.removeEventListener("orientationchange", onInnerHeight);
    };
  }, []);

  return (
    <ContainerContext.Provider
      value={{
        isMobileView,
        isOpenSidebar,
        onOpenSidebar,
        onCloseSidebar,
        contentScrollTop,
        setContentScrollTop,
      }}
    >
      <Flex direction="column" w="100vw" h={innerHeight} _light={{ bg: "white" }} _dark={{ bg: "gray.800" }}>
        <Toolbar />
        <Flex flex="1" overflow="hidden">
          <Sidebar />
          <Flex flex="1" direction="column" overflow="hidden">
            <Outlet />
          </Flex>
        </Flex>
      </Flex>
    </ContainerContext.Provider>
  );
};

export default Container;
