import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import _ from "lodash";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SlideFade,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import * as yup from "yup";
import { api } from "lib";
import { useApiGet, useCustomToast, useDocumentTitle } from "hooks";
import { messages } from "consts";
import { PermissionedContainer, Breadcrumb, DocumentHistory, Portal } from "components";
import { Content } from "pages/Private/Container";
import { MdChevronLeft, MdHistory, MdOutlineCancel, MdRefresh } from "react-icons/md";
import MeetingsDetailsContext from "./context";
import General from "./general";
import StatusChange from "./statusChange";
import Tasks from "./tasks";
import { VscDebugStart } from "react-icons/vsc";
import useSchedules from "./useSchedules";
import ActiveNotes from "./activeNotes";
import Files from "./files";
import { TbExternalLink } from "react-icons/tb";

export const MeetingsDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar reunião" : "Nova reunião");
  const location = useLocation();
  const [data, isLoadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/meetings/${_id}`, options: { isEnabled: !!_id } }), [_id]));
  const [meetingRooms, isLoadingMeetingRooms, refreshMeetingRooms] = useApiGet(
    useMemo(
      () => ({
        path: "/meeting-rooms",
        params: {
          query: { isActive: true },
          sort: { title: 1 },
          isAutocomplete: true,
        },
      }),
      []
    )
  );
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [counters, setCounters] = useState({});
  const [isLoadingCounters, setIsLoadingCounters] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const [schedules, isLoadingSchedules, refreshSchedules, conflictingMeetings] = useSchedules(useMemo(() => formData, [formData]));
  const [conflictings, setConflictings] = useState([]);
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const statusChangeRef = useRef();
  const toast = useCustomToast();
  const navigate = useNavigate();

  useEffect(() => {
    const formData = data ?? {
      placement: "external",
      status: "scheduled",
      startDate: moment().startOf("day").toDate(),
      endDate: moment().startOf("day").toDate(),
    };
    if (formData.startDate) formData.startDate = moment(formData.startDate).toDate();
    if (formData.endDate) formData.endDate = moment(formData.endDate).toDate();
    if (formData.startedAt) formData.startedAt = moment(formData.startedAt).format("DD/MM/YYYY HH:mm");
    if (formData.finishedAt) formData.finishedAt = moment(formData.finishedAt).format("DD/MM/YYYY HH:mm");
    if (formData.canceledAt) formData.canceledAt = moment(formData.canceledAt).format("DD/MM/YYYY HH:mm");
    setFormData(formData);
    setFormErrors({});
  }, [data]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        _id ? await api.patch(`/meetings/${_id}`, data) : await api.put("/meetings", data);
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        navigate(-1);
      } catch (error) {
        if (error.isHandled) return;
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, toast]
  );

  const handleSubmit = useCallback(async () => {
    try {
      setConflictings([]);
      const isAvailableSchedule = function (startDate) {
        const availables = _.filter(schedules, (o) => o.isAvailable && startDate >= o.startDate && this.parent.endDate <= o.endDate);
        return availables.length >= 1;
      };
      const isEndDateValid = function (endDate) {
        return this.parent.startDate <= endDate;
      };
      const shape = {
        status: yup.string().required(messages.error.required),
        customer: yup.string().required(messages.error.required),
        meetingType: yup.string().required(messages.error.required),
        demands: yup.array().min(1, messages.error.required).required(messages.error.required),
        startDate: yup
          .date()
          .typeError(messages.error.invalidDate)
          .required(messages.error.required)
          .test("is-available-schedule", messages.error.unavailableSchedule, isAvailableSchedule),
        endDate: yup
          .date()
          .typeError(messages.error.invalidDate)
          .required(messages.error.required)
          .test("is-end-date-valid", messages.error.invalidDate, isEndDateValid),
        startedAt: formData.startedBy && yup.date().typeError(messages.error.invalidDate).required(messages.error.required),
        finishedAt: formData.finishedBy && yup.date().typeError(messages.error.invalidDate).required(messages.error.required),
        canceledAt: formData.canceledBy && yup.date().typeError(messages.error.invalidDate).required(messages.error.required),
      };
      const data = {
        ...formData,
        customer: formData.customer?._id,
        mainMeetingRoom: formData.mainMeetingRoom?._id,
        participants: _.map(formData.participants, (o) => ({ ...o, user: o.user?._id, meetingRoom: o.meetingRoom?._id })),
        meetingType: formData.meetingType?._id,
        demands: _.map(formData.demands, "_id"),
        startedAt: moment(formData.startedAt, "DD/MM/YYYY HH:mm").toDate(),
        finishedAt: moment(formData.finishedAt, "DD/MM/YYYY HH:mm").toDate(),
        canceledAt: moment(formData.canceledAt, "DD/MM/YYYY HH:mm").toDate(),
      };
      if (data.placement === "external") {
        const participantsShape = yup.object().shape({
          user: yup.string().required(messages.error.required),
        });
        shape.participants = yup.array().of(participantsShape).min(1, messages.error.required).required(messages.error.required);
        shape.address = yup.object().typeError(messages.error.required).required(messages.error.required);
      } else {
        const participantsShape = yup.object().shape({
          user: yup.string().required(messages.error.required),
          meetingRoom: yup.string().required(messages.error.required),
        });
        shape.participants = yup.array().of(participantsShape).min(1, messages.error.required).required(messages.error.required);
        shape.mainMeetingRoom = yup.string().required(messages.error.required);
      }
      const schema = yup.object().shape(shape);
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [formData, schedules, handleSaveData]);

  const handleSubmitProxy = useCallback(async () => {
    try {
      const conflictings = await (async () => {
        const keys = ["customer._id", "meetingType._id"];
        if (_.isEqual(_.pick(data, keys), _.pick(formData, keys))) return [];
        const query = { status: "unscheduled" };
        if (formData.customer?._id) query["customer._id"] = ["@ObjectId", formData.customer._id];
        if (formData.meetingType?._id) query["meetingType._id"] = ["@ObjectId", formData.meetingType._id];
        if (_id) query._id = { $ne: ["@ObjectId", _id] };
        const response = await api.post("/meetings", { query });
        return _.map(response.data, (meeting) => ({
          _id: meeting._id,
          nid: meeting.nid,
          customerTradingName: meeting.customer.tradingName,
          scheduledDate: [moment(meeting.startDate).format("DD/MM/YYYY [de] HH:mm"), moment(meeting.endDate).format("HH:mm")].join(" às "),
        }));
      })();
      if (conflictings.length >= 1) setConflictings(conflictings);
      else handleSubmit();
    } catch (error) {
      if (error.isHandled) return;
      toast({ description: error.message, status: "error", isClosable: true });
    }
  }, [_id, data, formData, handleSubmit]);

  return (
    <MeetingsDetailsContext.Provider
      value={{
        data,
        isLoadingData,
        refreshData,
        formData,
        setFormData,
        formErrors,
        counters,
        setCounters,
        setIsLoadingCounters,
        schedules,
        isLoadingSchedules,
        refreshSchedules,
        meetingRooms,
        isLoadingMeetingRooms,
        refreshMeetingRooms,
        conflictingMeetings,
      }}
    >
      <Content>
        <HStack>
          <HStack flex="1" spacing={{ base: "10px", lg: "20px" }}>
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "cadastros" },
                { to: "/meetings/list", label: "reuniões" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} onClick={refreshData} isLoading={isLoadingData} />
          {_id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">#{formData.nid?.toLocaleString()} | Reunião </Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.title : "Novo cadastro"}</Text>
          </Box>
        </HStack>

        <Tabs colorScheme="main">
          <TabList>
            <Tab>dados gerais</Tab>
            <Tab>
              <HStack>
                <Text>tarefas</Text>
                {isLoadingCounters.tasks ? (
                  <Spinner size="xs" />
                ) : (
                  <Text fontSize="sm" fontWeight="semibold">
                    ({counters.tasks ?? 0})
                  </Text>
                )}
              </HStack>
            </Tab>
            {formData.startedAt && (
              <Tab>
                <HStack>
                  <Text>arquivos de ata</Text>
                  {isLoadingCounters.files ? (
                    <Spinner size="xs" />
                  ) : (
                    <Text fontSize="sm" fontWeight="semibold">
                      ({counters.files ?? 0})
                    </Text>
                  )}
                </HStack>
              </Tab>
            )}
          </TabList>
          <TabPanels>
            <TabPanel px="0" py="30px">
              <General />
            </TabPanel>
            <TabPanel px="0" py="30px">
              <Tasks />
            </TabPanel>
            {formData.startedAt && (
              <TabPanel px="0" py="30px">
                <Files />
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </Content>

      <Divider />

      <SlideFade in={true} offsetY="20px">
        <HStack p="20px" justifyContent="space-between">
          <HStack>
            <PermissionedContainer required={"meetings:".concat(_id ? "update" : "create")}>
              <Button
                size="sm"
                colorScheme="main"
                isLoading={isLoadingData || isLoadingSaveData || isLoadingSchedules}
                onClick={handleSubmitProxy}
              >
                salvar
              </Button>
            </PermissionedContainer>
            <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
              voltar
            </Button>
          </HStack>
          {data?.status === "scheduled" ? (
            <HStack>
              <Box>
                <Menu>
                  <MenuButton as={Button} size="sm" variant="ghost" colorScheme="red" rightIcon={<Icon as={MdOutlineCancel} />}>
                    cancelar
                  </MenuButton>
                  <Portal>
                    <MenuList fontSize="sm">
                      <MenuItem onClick={() => statusChangeRef.current.open("canceled_by_customer")}>Cancelado pelo cliente</MenuItem>
                      <MenuItem onClick={() => statusChangeRef.current.open("canceled_by_consultant")}>Cancelado pelo consultor</MenuItem>
                      <MenuItem onClick={() => statusChangeRef.current.open("canceled_by_scheduling")}>Cancelado pelo agendamento</MenuItem>
                    </MenuList>
                  </Portal>
                </Menu>
              </Box>
              <Button
                size="sm"
                variant="solid"
                colorScheme="green"
                rightIcon={<Icon as={VscDebugStart} />}
                onClick={() => statusChangeRef.current.open("started")}
              >
                iniciar
              </Button>
            </HStack>
          ) : (
            data?.status === "started" && (
              <Button
                size="sm"
                variant="solid"
                colorScheme="blue"
                rightIcon={<Icon as={VscDebugStart} />}
                onClick={() => statusChangeRef.current.open("finished")}
              >
                finalizar
              </Button>
            )
          )}
        </HStack>
      </SlideFade>

      {_id && <DocumentHistory path={`/meetings/${_id}/history`} isOpen={isOpenDocumentHistory} onClose={onCloseDocumentHistory} />}

      <StatusChange ref={statusChangeRef} />

      <AlertDialog isOpen={_.size(conflictings) >= 1} onClose={() => setConflictings([])} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>
            <Text>Reunião existente</Text>
            <Text fontWeight="normal" fontSize="xs">
              Existe uma reunião deste tipo com status previsto para o cliente atual.
            </Text>
          </AlertDialogHeader>
          <AlertDialogBody fontSize="sm" as={VStack} alignItems="stretch">
            {_.map(conflictings, (item) => (
              <HStack p="10px" borderRadius="lg" borderWidth="1px">
                <Box flex="1">
                  <Text fontSize="xs">NID {item.nid}</Text>
                  <Text fontWeight="semibold">{item.customerTradingName}</Text>
                  <Text fontSize="sm">{item.scheduledDate}</Text>
                </Box>
                <IconButton
                  size="xs"
                  variant="outline"
                  icon={<Icon as={TbExternalLink} />}
                  onClick={() => {
                    setConflictings([]);
                    navigate(`/meetings/edit/${item._id}`, { replace: true });
                  }}
                />
              </HStack>
            ))}
          </AlertDialogBody>
          <AlertDialogFooter as={HStack} justifyContent="flex-end">
            <Button size="sm" variant="outline" onClick={() => setConflictings([])}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="yellow" onClick={handleSubmit}>
              continuar agendamento
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <ActiveNotes />
    </MeetingsDetailsContext.Provider>
  );
};

export default MeetingsDetails;
