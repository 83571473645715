import React, { useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { Box, Center, HStack, Icon, Button, Text, useDisclosure, IconButton, Tooltip } from "@chakra-ui/react";
import { FileList, FileUpload } from "components";
import { MdAttachFile, MdRefresh } from "react-icons/md";
import { FaRegFolderOpen } from "react-icons/fa";
import MeetingsDetailsContext from "../context";
import { useApiGet } from "hooks";
import { EventEmitter } from "lib";

const Files = () => {
  const { _id } = useParams();
  const { setCounters, setIsLoadingCounters } = useContext(MeetingsDetailsContext);
  const [files, isLoadingFiles, refreshFiles] = useApiGet(
    useMemo(
      () => ({
        path: `/files`,
        params: { query: { owner: _id }, perPage: -1 },
        options: { isEnabled: !!_id },
      }),
      [_id]
    )
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setCounters((state) => ({ ...state, files: files?.size ?? 0 }));
  }, [setCounters, files?.size]);

  useEffect(() => {
    setIsLoadingCounters((state) => ({ ...state, files: isLoadingFiles }));
  }, [setIsLoadingCounters, isLoadingFiles]);

  useEffect(() => {
    const listener = EventEmitter.addListener("files.create.".concat(_id), onOpen);
    return () => listener.remove();
  }, [_id, onOpen]);

  return (
    <>
      <HStack mb="20px">
        <Text fontSize="sm" fontWeight="semibold" flex="1">
          {_.size(files?.data)} arquivos adicionados
        </Text>
        <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingFiles} onClick={refreshFiles} />
        <Tooltip placement="left" isDisabled={_id} shouldWrapChildren label="Antes de adicionar arquivos você deve salvar a reunião atual.">
          <Button size="sm" colorScheme="main" rightIcon={<Icon as={MdAttachFile} />} isDisabled={!_id} onClick={onOpen}>
            incluir arquivos
          </Button>
        </Tooltip>
      </HStack>

      <FileList path="/files" data={files?.data} onChange={refreshFiles} />

      {_.size(files?.data) === 0 && (
        <Center paddingTop="40px" paddingBottom="20px">
          <Box textAlign="center">
            <Icon as={FaRegFolderOpen} boxSize={20} marginBottom="10px" />
            <Text fontSize="lg" fontWeight="bold">
              Nenhum arquivo adicionado
            </Text>
            <Text fontSize="sm">Você ainda não adicionou arquivos neste cliente.</Text>
          </Box>
        </Center>
      )}

      <FileUpload path="/files" ownerRef="Meeting" owner={_id} isOpen={isOpen} onClose={onClose} onChange={refreshFiles} />
    </>
  );
};

export default Files;
