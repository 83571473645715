import { HStack, Icon, IconButton, Text } from "@chakra-ui/react";
import { MdChevronRight } from "react-icons/md";

const InactiveItem = ({ title, onAdd }) => {
  return (
    <HStack py="5px" px="10px" borderRadius="md" borderWidth="1px">
      <Text fontSize="xs" flex="1">
        {title}
      </Text>
      <IconButton size="xs" icon={<Icon as={MdChevronRight} />} isRound onClick={onAdd} />
    </HStack>
  );
};

export default InactiveItem;
