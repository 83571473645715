import Dexie from "dexie";

export const db = new Dexie("formatar");

const keys = [
  "++id",
  "moduleName",
  "customer",
  "responsible",
  "participants",
  "mainMeetingRoom",
  "meetingType",
  "demand",
  "demands",
  "team",
  "startDate",
  "dueDate",
  "durationInMinutes",
];

db.version(1).stores({ activities: keys.join(",") });
