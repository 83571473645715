import moment from "moment";
import _ from "lodash";
import { Avatar, HStack, Link, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { translator } from "lib";
import { CustomBadge, CustomerLogo } from "components";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "status",
    title: "Status",
    isVisible: true,
    render: ({ status }) => <CustomBadge.Tasks.Status status={status} />,
    exporter: ({ status }) => translator(status),
  },
  {
    accessor: "title",
    title: "Título",
    isVisible: true,
    render: ({ _id, title }) => (
      <Link as={RouterLink} to={`/tasks/edit/${_id}`}>
        {title}
      </Link>
    ),
    exporter: ({ title }) => title,
  },
  {
    accessor: "customer.tradingName",
    title: "Cliente",
    isVisible: true,
    render: ({ customer }) => (
      <HStack>
        <CustomerLogo boxSize="30px" alt={customer?.tradingName} src={customer?.logoUrl} />
        <Text>{customer?.tradingName}</Text>
      </HStack>
    ),
    exporter: ({ customer }) => customer?.tradingName,
  },
  {
    accessor: "team.title",
    title: "Time",
    isVisible: true,
    formatter: ({ team }) => team?.title ?? "-",
  },
  {
    accessor: "responsible.name",
    title: "Responsável",
    isVisible: true,
    render: ({ responsible }) => (
      <HStack>
        <Avatar size="xs" name={responsible?.name} src={responsible?.avatarUrl} />
        <Text>{responsible?.name ?? "-"}</Text>
      </HStack>
    ),
    exporter: ({ responsible }) => responsible?.name ?? "-",
  },
  {
    accessor: "meeting.nid",
    title: "Reunião",
    isVisible: true,
    render: ({ meeting }) =>
      meeting ? (
        <Link as={RouterLink} to={`/meetings/edit/${meeting._id}`} target="_blank">
          {meeting.nid}
        </Link>
      ) : (
        "-"
      ),
    exporter: ({ meeting }) => meeting?.nid ?? "-",
  },
  {
    accessor: "startedAt",
    title: "Iniciada em",
    isVisible: true,
    formatter: ({ startedAt }) => (startedAt ? moment(startedAt).format("DD/MM/YYYY HH:mm") : "-"),
  },
  {
    accessor: "finishedAt",
    title: "Finalizada em",
    isVisible: true,
    formatter: ({ finishedAt }) => (finishedAt ? moment(finishedAt).format("DD/MM/YYYY HH:mm") : "-"),
  },
  {
    accessor: "pausesCount",
    title: "Pausas",
    isVisible: true,
    formatter: ({ pausesCount }) => pausesCount,
  },
  {
    accessor: "demand.informedAvgDurationInMinutes",
    title: "Demanda Infor.",
    isVisible: true,
    render: ({ demand }) => `${demand?.informedAvgDurationInMinutes?.toLocaleString() ?? "-"} minutos`,
    exporter: ({ demand }) => demand?.informedAvgDurationInMinutes?.toLocaleString() ?? "-",
  },
  {
    accessor: "demand.title",
    title: "Demanda",
    isVisible: true,
    formatter: ({ demand }) => demand?.title ?? "-",
  },
  {
    accessor: "referenceDate",
    title: "Referência",
    isVisible: true,
    formatter: ({ referenceDate }) => moment(referenceDate).format("MM/YYYY"),
  },
  {
    accessor: "dueDate",
    title: "Vencimento",
    isVisible: true,
    formatter: ({ dueDate }) => moment(dueDate).format("DD/MM/YYYY"),
  },
  {
    accessor: "pausedDurationInMinutes",
    title: "Tempo pausado",
    isVisible: false,
    render: ({ pausedDurationInMinutes }) => `${pausedDurationInMinutes?.toLocaleString() ?? 0} minutos`,
    exporter: ({ pausedDurationInMinutes }) => pausedDurationInMinutes?.toLocaleString() ?? 0,
  },
  {
    accessor: "durationInMinutes",
    title: "Duração",
    isVisible: true,
    render: ({ durationInMinutes }) => `${durationInMinutes?.toLocaleString() ?? 0} minutos`,
    exporter: ({ durationInMinutes }) => durationInMinutes?.toLocaleString() ?? 0,
  },
  {
    accessor: "createdBy.name",
    title: "Criado por",
    isVisible: false,
    formatter: ({ createdBy }) => createdBy.name ?? "-",
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
