import React, { Fragment, useContext, useMemo, useState } from "react";
import _ from "lodash";
import { Box, Center, HStack, Icon, IconButton, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { formatMinutes, percent, translator } from "lib";
import { statuses } from "consts";
import SearchInput from "../components/SearchInput";
import { ActivitiesContext } from "./index";
import { MdAdd, MdOutlineVisibility, MdRemove } from "react-icons/md";
import { ConsultantAndCustomer } from "./classes/ConsultantAndCustomer";
import SortButton from "../components/SortButton";
import { useSearch } from "hooks";
import MeetingsIcon from "assets/icons/meetings";
import TasksIcon from "assets/icons/tasks";

const TrChild = ({ isOpen, responsible, data }) => {
  const { remainingBusinessDaysPercentage, handleNavigate } = useContext(ActivitiesContext);
  const styleProps = useMemo(() => ({ _light: { bg: "gray.50" }, _dark: { bg: "gray.900" } }), []);
  const [searchText, setSearchText] = useState("");
  const [sort, setSort] = useState({ key: "name", direction: "asc" });
  const searched = useSearch(useMemo(() => ({ keys: ["name"], data, searchText }), [data, searchText]));
  const sorted = useMemo(() => _.orderBy(searched, [sort.key], [sort.direction]), [searched, sort]);

  return (
    isOpen && (
      <>
        <Tr {...styleProps}>
          <Td colSpan={19} p="0">
            <SearchInput value={searchText} onSubmit={(value) => setSearchText(value)} />
          </Td>
        </Tr>
        <Tr {...styleProps}>
          <Th textTransform="none" textAlign="left" fontSize="xs">
            <HStack>
              <Text>Tipo</Text>
              <SortButton keyName="moduleName" {...{ sort, setSort }} />
            </HStack>
          </Th>
          <Th textTransform="none" textAlign="left" fontSize="xs">
            <HStack>
              <Text>Cliente</Text>
              <SortButton keyName="name" {...{ sort, setSort }} />
            </HStack>
          </Th>
          <Th textTransform="none" fontSize="xs">
            <HStack justifyContent="center">
              <Text>Qtd</Text>
              <SortButton keyName="quantity" {...{ sort, setSort }} />
            </HStack>
          </Th>
          {statuses.tasks.map(({ value }) => (
            <Fragment key={value}>
              <Th textTransform="none" textAlign="center" fontSize="xs" borderLeftWidth="1px">
                <HStack justifyContent="center">
                  <Text>Qtd</Text>
                  <SortButton keyName={`statuses.${value}.quantity`} {...{ sort, setSort }} />
                </HStack>
              </Th>
              <Th textTransform="none" textAlign="center" fontSize="xs">
                <HStack justifyContent="center">
                  <Text>Tempo</Text>
                  <SortButton keyName={`statuses.${value}.duration`} {...{ sort, setSort }} />
                </HStack>
              </Th>
              <Th textTransform="none" textAlign="center" fontSize="xs">
                <HStack justifyContent="center">
                  <Text>%</Text>
                  <SortButton keyName={`statuses.${value}.percentage`} {...{ sort, setSort }} />
                </HStack>
              </Th>
            </Fragment>
          ))}
        </Tr>
        {_.map(sorted, (item) => (
          <Tr key={item._id} {...styleProps} _hover={{ _light: { bg: "gray.100" }, _dark: { bg: "blackAlpha.500" } }}>
            <Td>
              <HStack>
                {item.moduleName === "tasks" ? <TasksIcon /> : <MeetingsIcon />}
                <Text fontSize="xs">{item.moduleName === "tasks" ? "Tarefa" : "Reunião"}</Text>
              </HStack>
            </Td>
            <Td fontSize="xs">{item.name}</Td>
            <Td fontSize="xs" textAlign="center">
              {item.quantity}
            </Td>
            {statuses.tasks.map(({ color, value }) => {
              const isAlerted = item.statuses[value].percentage > remainingBusinessDaysPercentage;
              const props = isAlerted && { _light: { bg: "red.100", color: "red.500" }, _dark: { bg: "red.900" } };
              return (
                <Fragment key={value}>
                  <Td fontSize="xs" textAlign="center" borderLeftWidth="1px">
                    <HStack justifyContent="center">
                      <Text>{item.statuses[value].quantity}</Text>
                      <IconButton
                        size="xs"
                        variant="outline"
                        icon={<Icon as={MdOutlineVisibility} />}
                        onClick={() =>
                          handleNavigate(item.moduleName, {
                            responsible: [responsible],
                            customer: [{ _id: item._id, tradingName: item.name }],
                            status: [{ color, value }],
                          })
                        }
                      />
                    </HStack>
                  </Td>
                  <Td fontSize="xs" textAlign="center">
                    {formatMinutes(item.statuses[value].duration)}
                  </Td>
                  <Td fontSize="xs" textAlign="center" {...props}>
                    {percent.format(item.statuses[value].percentage)}
                  </Td>
                </Fragment>
              );
            })}
          </Tr>
        ))}
      </>
    )
  );
};

const ByConsultantAndCustomer = () => {
  const { query, activities, isLoadingActivities, totalMinutesInMonth, remainingBusinessDaysPercentage, handleNavigate } =
    useContext(ActivitiesContext);
  const data = useMemo(() => {
    const response = {};
    const createOrUpdateConsultantAndCustomer = ({ moduleName, ustatus, customer }, entity, durationInMinutes) => {
      const tmp = response[entity._id] || new ConsultantAndCustomer(moduleName, entity);
      tmp.incQuantity();
      tmp.incStatus(ustatus, durationInMinutes);

      const child = tmp.children[customer._id] || new ConsultantAndCustomer(moduleName, customer);
      child.incQuantity();
      child.incStatus(ustatus, durationInMinutes);

      tmp.children[customer._id] = child;
      response[entity._id] = tmp;
    };
    for (const activity of activities || []) {
      if (activity.moduleName === "tasks") {
        if (!activity.responsible) continue;
        createOrUpdateConsultantAndCustomer(activity, activity.responsible, activity.demand.informedAvgDurationInMinutes);
      } else {
        for (const participant of activity.participants) {
          createOrUpdateConsultantAndCustomer(activity, participant.user, activity.durationInMinutes);
        }
      }
    }
    const values = Object.values(response);
    for (const value of values) value.children = Object.values(value.children);
    return values;
  }, [activities, totalMinutesInMonth]);
  const [searchText, setSearchText] = useState("");
  const [sort, setSort] = useState({ key: "name", direction: "asc" });
  const searched = useSearch(useMemo(() => ({ keys: ["name"], data, searchText }), [data, searchText]));
  const sorted = useMemo(() => _.orderBy(searched, [sort.key], [sort.direction]), [searched, sort]);
  const [isOpen, setIsOpen] = useState({});

  return (
    <Box borderWidth="1px" borderRadius="lg" width="auto" overflowX="auto">
      <SearchInput value={searchText} onSubmit={(value) => setSearchText(value)} />
      <Table size="sm" whiteSpace="nowrap">
        <Thead>
          <Tr>
            <Th rowSpan={2} colSpan={2} textTransform="none" textAlign="left" fontSize="xs">
              <HStack>
                <Text>Consultor</Text>
                <SortButton keyName="name" {...{ sort, setSort }} />
              </HStack>
            </Th>
            <Th rowSpan={2} textTransform="none" fontSize="xs">
              <HStack justifyContent="center">
                <Text>Qtd</Text>
                <SortButton keyName="quantity" {...{ sort, setSort }} />
              </HStack>
            </Th>
            {statuses.tasks.map(({ color, value }) => (
              <Th key={value} borderBottomWidth="0" colSpan={3} textTransform="none" textAlign="center" fontSize="xs" borderLeftWidth="1px">
                <HStack justifyContent="center">
                  <Box w="10px" h="10px" borderRadius="full" bg={value === "pending" ? "red.500" : color} />
                  <Text>{translator(value)}</Text>
                </HStack>
              </Th>
            ))}
          </Tr>
          <Tr>
            {statuses.tasks.map(({ value }) => (
              <Fragment key={value}>
                <Th textTransform="none" textAlign="center" fontSize="xs" borderLeftWidth="1px">
                  <HStack justifyContent="center">
                    <Text>Qtd</Text>
                    <SortButton keyName={`statuses.${value}.quantity`} {...{ sort, setSort }} />
                  </HStack>
                </Th>
                <Th textTransform="none" textAlign="center" fontSize="xs">
                  <HStack justifyContent="center">
                    <Text>Tempo</Text>
                    <SortButton keyName={`statuses.${value}.duration`} {...{ sort, setSort }} />
                  </HStack>
                </Th>
                <Th textTransform="none" textAlign="center" fontSize="xs">
                  <HStack justifyContent="center">
                    <Text>%</Text>
                    <SortButton keyName={`statuses.${value}.percentage`} {...{ sort, setSort }} />
                  </HStack>
                </Th>
              </Fragment>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {_.map(sorted, (item) => (
            <Fragment key={item._id}>
              <Tr _hover={{ _light: { bg: "gray.100" }, _dark: { bg: "gray.900" } }}>
                <Td colSpan={2}>
                  <HStack>
                    <IconButton
                      size="xs"
                      variant="outline"
                      icon={<Icon as={isOpen[item._id] ? MdRemove : MdAdd} />}
                      onClick={() => setIsOpen((state) => ({ ...state, [item._id]: !state[item._id] }))}
                    />
                    <Text fontWeight="semibold" fontSize="xs">
                      {item.name}
                    </Text>
                  </HStack>
                </Td>
                <Td fontSize="xs" textAlign="center">
                  {item.quantity}
                </Td>
                {statuses.tasks.map(({ color, value }) => {
                  const isAlerted = item.statuses[value].percentage > remainingBusinessDaysPercentage;
                  const props = isAlerted && { _light: { bg: "red.100", color: "red.500" }, _dark: { bg: "red.900" } };
                  return (
                    <Fragment key={value}>
                      <Td fontSize="xs" textAlign="center" borderLeftWidth="1px">
                        <HStack justifyContent="center">
                          <Text>{item.statuses[value].quantity}</Text>
                          <IconButton
                            size="xs"
                            variant="outline"
                            icon={<Icon as={MdOutlineVisibility} />}
                            onClick={() => {
                              for (const moduleName of query.moduleNames)
                                handleNavigate(moduleName.value, {
                                  responsible: [{ _id: item._id, name: item.name }],
                                  status: [{ color, value }],
                                });
                            }}
                          />
                        </HStack>
                      </Td>
                      <Td fontSize="xs" textAlign="center">
                        <Text>{formatMinutes(item.statuses[value].duration)}</Text>
                      </Td>
                      <Td fontSize="xs" textAlign="center" {...props}>
                        <Text>{percent.format(item.statuses[value].percentage)}</Text>
                      </Td>
                    </Fragment>
                  );
                })}
              </Tr>
              <TrChild isOpen={isOpen[item._id]} responsible={{ _id: item._id, name: item.name }} data={item.children} />
            </Fragment>
          ))}
        </Tbody>
      </Table>
      {isLoadingActivities && (
        <Center p="40px">
          <Spinner />
        </Center>
      )}
    </Box>
  );
};

export default ByConsultantAndCustomer;
