import React, { useState, useCallback, useContext, useEffect, useMemo } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import _ from "lodash";
import { api } from "lib";
import { useApiGet, useCustomToast } from "hooks";
import { messages } from "consts";
import * as yup from "yup";
import CustomerNotesContext from "./context";
import { DocumentHistory, PermissionedContainer, SyncSelect } from "components";
import { MdHistory, MdRefresh } from "react-icons/md";

const Details = () => {
  const { _id } = useParams();
  const { editSelected, setEditSelected, refreshNotes } = useContext(CustomerNotesContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const toast = useCustomToast();
  const [teams, isLoadingTeams, refreshTeams] = useApiGet(
    useMemo(() => ({ path: "/teams", params: { query: { isActive: true }, sort: { title: 1 }, perPage: -1, isAutocomplete: true } }), [])
  );
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const { isOpen: isOpenConfirmDialog, onOpen: onOpenConfirmDialog, onClose: onCloseConfirmDialog } = useDisclosure();

  useEffect(() => {
    setFormData(editSelected?._id ? { ...editSelected } : { status: "active" });
    setFormErrors({});
  }, [editSelected]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        data._id ? await api.patch(`/customers/${_id}/notes/${data._id}`, data) : await api.put(`/customers/${_id}/notes`, data);
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        setEditSelected();
        refreshNotes();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshNotes, toast, setEditSelected]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        team: yup.string().required(messages.error.required),
        destModule: yup.string().required(messages.error.required),
        actionType: yup.string().required(messages.error.required),
        content: yup.string().required(messages.error.required),
      });
      const data = { ...formData, team: formData.team?._id };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  const handleSubmitConfirm = useCallback(() => {
    if (formData.status === "finished") return onOpenConfirmDialog();
    handleSubmit();
  }, [formData.status, onOpenConfirmDialog, handleSubmit]);

  return (
    <>
      <Modal size="6xl" isOpen={editSelected} onClose={() => setEditSelected()} closeOnEsc={false} closeOnOverlayClick={false} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader as={HStack}>
            <Text flex="1">Notas</Text>
            {formData._id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
          </ModalHeader>
          <Divider />
          <ModalBody py={4}>
            <Grid templateColumns="repeat(12, 1fr)" gap={4}>
              <GridItem colSpan={{ base: 12, lg: 3 }}>
                <FormControl isRequired={true} isInvalid={formErrors.status}>
                  <FormLabel fontSize="sm">Status</FormLabel>
                  <Select
                    value={formData.status || ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, status: target.value }))}
                  >
                    <option value="active">Ativa</option>
                    <option value="finished">Finalizada</option>
                  </Select>
                  <FormErrorMessage>{formErrors.status}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 3 }}>
                <FormControl isRequired={true} isInvalid={formErrors.team}>
                  <HStack mb="0.5em" justifyContent="space-between">
                    <FormLabel fontSize="sm" m="0">
                      Time
                    </FormLabel>
                    {formData.team ? (
                      <PermissionedContainer required="teams:update">
                        <Link as={RouterLink} to={`/settings/records/teams/edit/${formData.team._id}`} target="_blank" fontSize="xs">
                          editar cadastro
                        </Link>
                      </PermissionedContainer>
                    ) : (
                      <PermissionedContainer required="teams:create">
                        <Link as={RouterLink} to={`/settings/records/teams/new`} target="_blank" color="blue.500" fontSize="xs">
                          incluir cadastro
                        </Link>
                      </PermissionedContainer>
                    )}
                  </HStack>
                  <HStack>
                    <SyncSelect
                      value={formData.team}
                      options={teams?.data}
                      placeholder="Selecione"
                      onChange={(team) => setFormData((state) => ({ ...state, team, responsible: null, demand: null }))}
                      getOptionValue={({ _id }) => _id}
                      formatOptionLabel={({ title }) => title}
                      isClearable={true}
                    />
                    <IconButton variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingTeams} onClick={refreshTeams} />
                  </HStack>
                  <FormErrorMessage>{formErrors.team}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 3 }}>
                <FormControl isRequired={true} isInvalid={formErrors.destModule}>
                  <FormLabel fontSize="sm">Módulo de destino</FormLabel>
                  <Select
                    value={formData.destModule || ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, destModule: target.value }))}
                  >
                    <option value="">--Selecione</option>
                    <option value="tasks">Tarefas</option>
                    <option value="meetings">Reuniões</option>
                  </Select>
                  <FormErrorMessage>{formErrors.destModule}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 3 }}>
                <FormControl isRequired={true} isInvalid={formErrors.actionType}>
                  <FormLabel fontSize="sm">Tipo de ação</FormLabel>
                  <Select
                    value={formData.actionType || ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, actionType: target.value }))}
                  >
                    <option value="">--Selecione</option>
                    <option value="block">Bloqueio</option>
                    <option value="alert">Alerta</option>
                  </Select>
                  <FormErrorMessage>{formErrors.actionType}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={12}>
                <FormControl isRequired={true} isInvalid={formErrors.content}>
                  <Textarea
                    minH="200px"
                    value={formData.content ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, content: target.value }))}
                  />
                  <FormErrorMessage>{formErrors.content}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
          </ModalBody>
          <Divider />
          <ModalFooter as={HStack} justifyContent="flex-end">
            <Button size="sm" variant="outline" onClick={() => setEditSelected()}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="main" isLoading={isLoadingSaveData} onClick={handleSubmitConfirm}>
              salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog isOpen={isOpenConfirmDialog} onClose={onCloseConfirmDialog} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>
            Deseja realmente finalizar esta nota? <strong>Esta é uma ação irreversível.</strong>
          </AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button size="sm" variant="outline" onClick={onCloseConfirmDialog}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="yellow" onClick={() => Promise.all([onCloseConfirmDialog(), handleSubmit()])}>
              confirmar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      {formData._id && (
        <DocumentHistory path={`/customers/${formData._id}/history`} isOpen={isOpenDocumentHistory} onClose={onCloseDocumentHistory} />
      )}
    </>
  );
};
export default Details;
