import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import {
  Box,
  Button,
  Center,
  HStack,
  Icon,
  IconButton,
  Spinner,
  StackDivider,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useApiGet } from "hooks";
import { formatMinutes } from "lib";
import { RangeDateSelect } from "components";
import { MdPrint, MdRefresh } from "react-icons/md";
import { HiOutlinePresentationChartBar } from "react-icons/hi";
import { TbExternalLink } from "react-icons/tb";
import CustomersDetailsContext from "../context";
import PrintDetailing from "./printDetailing";
import ConsultationsContext from "./context";

export const parentRefs = {
  Task: { path: "/tasks/edit/", label: "Tarefa" },
  Meeting: { path: "/meetings/edit/", label: "Reunião" },
};

const ConsultingMovements = () => {
  const { _id } = useParams();
  const { setCounters, setIsLoadingCounters } = useContext(CustomersDetailsContext);
  const [query, setQuery] = useState();
  const [consultations, isLoadingConsultations, refreshConsultations] = useApiGet(
    useMemo(() => {
      const params = { query, sort: { createdAt: 1 }, perPage: -1 };
      return {
        path: `/customers/${_id}/consultations`,
        params,
        options: { isEnabled: _.isString(_id) && _.isObject(query) },
      };
    }, [_id, query])
  );
  const balanceInMinutes = useMemo(() => {
    const mapped = _.map(consultations?.data, (o) => o.totalDurationInMinutes);
    return _.sum(mapped);
  }, [consultations?.data]);
  const { isOpen: isOpenPrintDetailing, onOpen: onOpenPrintDetailing, onClose: onClosePrintDetailing } = useDisclosure();

  useEffect(() => {
    setCounters((state) => ({ ...state, consultations: consultations?.size ?? 0 }));
  }, [setCounters, consultations?.size]);

  useEffect(() => {
    setIsLoadingCounters((state) => ({ ...state, consultations: isLoadingConsultations }));
  }, [setIsLoadingCounters, isLoadingConsultations]);

  return (
    <ConsultationsContext.Provider value={{ query, consultations, balanceInMinutes }}>
      <HStack mb="20px">
        <Box flex="1">
          <Text fontSize="sm" fontWeight="semibold">
            {consultations?.size ?? 0} registros adicionados
          </Text>
          <Text fontSize="xs">
            {moment(query?.startDate).format("DD/MM/YYYY")} a {moment(query?.endDate).format("DD/MM/YYYY")}
          </Text>
        </Box>
        <RangeDateSelect selected={query} onChange={setQuery} />
        <IconButton
          size="sm"
          variant="outline"
          icon={<Icon as={MdPrint} />}
          onClick={onOpenPrintDetailing}
          isDisabled={_.size(consultations?.data) === 0}
        />
        <IconButton
          size="sm"
          variant="outline"
          icon={<Icon as={MdRefresh} />}
          isLoading={isLoadingConsultations}
          onClick={refreshConsultations}
        />
      </HStack>

      {_.size(consultations?.data) >= 1 && (
        <Table size="sm" variant="striped">
          <Thead>
            <Tr>
              <Th>Data</Th>
              <Th>Hora inicial</Th>
              <Th>Hora final</Th>
              <Th>Total horas</Th>
              <Th>Consultor(es)</Th>
              <Th>Demandas</Th>
              <Th>Origem</Th>
            </Tr>
          </Thead>
          <Tbody>
            {_.map(consultations?.data, (item) => (
              <Tr key={item._id}>
                <Td>{moment(item.startedAt).format("DD/MM/YYYY")}</Td>
                <Td>{moment(item.startedAt).format("HH:mm")}</Td>
                <Td>{moment(item.finishedAt).format("HH:mm")}</Td>
                <Td>{formatMinutes(item.totalDurationInMinutes)}</Td>
                <Td>{_.map(item.users, "name").join(", ")}</Td>
                <Td>{_.map(item.demands, "title").join(", ")}</Td>
                <Td>
                  <Button
                    as={RouterLink}
                    to={parentRefs[item.parentRef].path.concat(item.parent)}
                    target="_blank"
                    size="xs"
                    variant="outline"
                    rightIcon={<Icon as={TbExternalLink} />}
                  >
                    {parentRefs[item.parentRef].label}
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
          <Tfoot>
            <Tr fontWeight="semibold">
              <Td colSpan={3}>TOTAL</Td>
              <Td colSpan={4}>{formatMinutes(balanceInMinutes)}</Td>
            </Tr>
          </Tfoot>
        </Table>
      )}

      <VStack align="stretch" divider={<StackDivider />} spacing={4}></VStack>

      {isLoadingConsultations ? (
        <Center p="40px">
          <Spinner />
        </Center>
      ) : (
        _.size(consultations?.data) === 0 && (
          <Center paddingTop="40px" paddingBottom="20px">
            <Box textAlign="center">
              <Icon as={HiOutlinePresentationChartBar} boxSize={20} marginBottom="10px" />
              <Text fontSize="lg" fontWeight="bold">
                Nenhuma consultoria adicionada
              </Text>
              <Text fontSize="sm">Este cliente ainda não possui consultorias adicionadas.</Text>
            </Box>
          </Center>
        )
      )}

      <PrintDetailing isOpen={isOpenPrintDetailing} onClose={onClosePrintDetailing} />
    </ConsultationsContext.Provider>
  );
};

export default ConsultingMovements;
