import React, { useCallback, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  Button,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { api } from "lib";
import { useCustomToast } from "hooks";
import { messages } from "consts";
import { TbAlertCircle } from "react-icons/tb";
import CustomersDetailsContext from "../context";

const MinutesUsageAlert = () => {
  const { _id } = useParams();
  const { refreshData, isMinutesUsageAlerted } = useContext(CustomersDetailsContext);
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const toast = useCustomToast();

  const handleRememberLater = useCallback(
    async (key) => {
      try {
        setIsLoadingSaveData(true);
        const intervals = {
          tomorrow: moment().startOf("day").add(1, "day").toDate(),
          next_week: moment().startOf("week").add(1, "week").toDate(),
        };
        const saved = await api.patch(`/customers/${_id}/alerts/minutes-usage/dismiss`, {
          minutesUsageNextAlertAt: intervals[key],
        });
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        onClose();
        refreshData();
      } catch (error) {
        if (error.isHandled) return;
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, onClose]
  );

  return (
    isMinutesUsageAlerted && (
      <>
        <IconButton colorScheme="red" borderRadius="full" icon={<Icon as={TbAlertCircle} />} onClick={onOpen} />
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Desvio de hora contratada</ModalHeader>
            <ModalBody>A quantidade de horas utilizadas pelo cliente este mês é superior à quantidade horas contratada.</ModalBody>
            <ModalFooter as={HStack} justifyContent="flex-end">
              <Button size="sm" variant="outline" isLoading={isLoadingSaveData} onClick={() => handleRememberLater("tomorrow")}>
                lembrar amanhã
              </Button>
              <Button size="sm" variant="outline" isLoading={isLoadingSaveData} onClick={() => handleRememberLater("next_week")}>
                lembrar na próxima semana
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  );
};

export default MinutesUsageAlert;
