import { statuses } from "consts";

export class ConsultantAndCustomer {
  constructor(moduleName, entity) {
    this._id = entity._id;
    this.name = entity.tradingName || entity.companyName || entity.name;
    this.moduleName = moduleName;
    this.quantity = 0;
    this.statuses = {};
    this.children = {};
    const _self = this;
    for (const { value } of statuses.tasks)
      this.statuses[value] = {
        quantity: 0,
        duration: 0,
        get percentage() {
          return this.quantity / _self.quantity;
        },
      };
  }
  incQuantity() {
    this.quantity += 1;
  }
  incStatus(status, duration) {
    this.statuses[status].quantity += 1;
    this.statuses[status].duration += duration;
  }
}
