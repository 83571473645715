import moment from "moment";
import _ from "lodash";
import { HStack, Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { formatMinutes, translator } from "lib";
import { CustomerLogo } from "components";

const parentRefs = {
  Task: { label: "Tarefa", dateKey: "dueDate" },
  Meeting: { label: "Reunião", dateKey: "startDate" },
};

const defaultColumns = [
  {
    accessor: "parent.nid",
    title: "NID/Origem",
    isVisible: true,
    render: ({ parentRef, parent }) => (
      <Link as={RouterLink} to={`/${parentRef.toLowerCase()}s/edit/${parent._id}`}>
        {parent.nid}
      </Link>
    ),
    exporter: ({ parent }) => parent.nid,
  },
  {
    accessor: "parentRef",
    title: "Origem",
    isVisible: true,
    formatter: ({ parentRef }) => parentRefs[parentRef].label,
  },
  {
    accessor: "parent.placement",
    title: "Local",
    isVisible: true,
    formatter: ({ parent }) => translator(parent.placement),
  },
  {
    accessor: "customer.tradingName",
    title: "Cliente",
    isVisible: true,
    render: ({ customer }) => (
      <HStack>
        <CustomerLogo boxSize="30px" alt={customer?.tradingName} src={customer?.logoUrl} />
        <Link as={RouterLink} to={`/customers/edit/${customer._id}`}>
          {customer.tradingName}
        </Link>
      </HStack>
    ),
    exporter: ({ customer }) => customer.tradingName,
  },
  {
    accessor: "parent.meetingType",
    title: "Tipo de reunião",
    isVisible: true,
    formatter: ({ parent }) => parent.meetingType?.title || "-",
  },
  {
    accessor: "startedAt",
    title: "Iniciada em",
    isVisible: true,
    formatter: ({ startedAt }) => moment(startedAt).format("HH:mm"),
  },
  {
    accessor: "finishedAt",
    title: "Finalizada em",
    isVisible: true,
    formatter: ({ finishedAt }) => moment(finishedAt).format("HH:mm"),
  },
  {
    accessor: "totalDurationInMinutes",
    title: "Duração",
    isVisible: true,
    formatter: ({ totalDurationInMinutes }) => formatMinutes(totalDurationInMinutes),
    renderFoot: ({ totalDurationInMinutes }) => formatMinutes(totalDurationInMinutes),
  },
  {
    accessor: "users.name",
    title: "Consultor(es)",
    isVisible: true,
    formatter: ({ users }) => _.map(users, "name").join(", ") || "-",
  },
  {
    accessor: "date",
    title: "Data",
    isVisible: true,
    formatter: ({ parentRef, parent }) => moment(parent[parentRefs[parentRef].dateKey]).format("DD/MM/YYYY"),
  },

  {
    accessor: "parent.startDate",
    title: "Inicia às",
    isVisible: true,
    formatter: ({ parent }) => (parent.startDate ? moment(parent.startDate).format("HH:mm") : "-"),
  },
  {
    accessor: "parent.endDate",
    title: "Finaliza às",
    isVisible: true,
    formatter: ({ parent }) => (parent.endDate ? moment(parent.endDate).format("HH:mm") : "-"),
  },
  {
    accessor: "customer.classification",
    title: "Classificação",
    isVisible: true,
    formatter: ({ customer }) => customer.classification || "-",
  },
  {
    accessor: "parent.mainMeetingRoom",
    title: "Sala de reunião",
    isVisible: true,
    formatter: ({ parent }) => parent.mainMeetingRoom?.title || "-",
  },
  {
    accessor: "demands.title",
    title: "Demandas",
    isVisible: true,
    formatter: ({ demands }) => _.map(demands, "title").join(", "),
  },
  {
    accessor: "parent.customerParticipants",
    title: "Participantes/Cliente",
    isVisible: true,
    formatter: ({ parent }) => _.map(parent.customerParticipants, "name").join(", ") || "-",
  },
  {
    accessor: "parent.technicalNotes",
    title: "Notas técnicas",
    isVisible: false,
    formatter: ({ parent }) => parent.technicalNotes || "-",
  },
  {
    accessor: "parent.notes",
    title: "Notas",
    isVisible: false,
    formatter: ({ parent }) => parent.notes || "-",
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
