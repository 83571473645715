import React, { useContext, useMemo } from "react";
import _ from "lodash";
import { Box, Grid, GridItem, HStack, Heading, Text } from "@chakra-ui/react";
import { priorities } from "consts";
import { translator } from "lib";
import { SchedulingContext } from "./index";

const edges = {
  unknown: "-",
  very_low: "Abaixo de 0%",
  low: "Entre 0% e 25%",
  moderate: "Entre 25% e 50%",
  high: "Entre 50% e 75%",
  very_high: "Entre 75% e 100%",
  critical: "Acima de 100%",
};

const ByPriority = () => {
  const { scheduling } = useContext(SchedulingContext);
  const data = useMemo(() => {
    const response = {};
    _.forEach(scheduling, (item) => {
      if (response[item.priority]) response[item.priority] += 1;
      else response[item.priority] = 1;
    });
    return response;
  }, [scheduling]);

  return (
    <Grid templateColumns="repeat(12, 1fr)" gap={2} my={2}>
      {_.map(priorities.dashboardsScheduling, ({ color, value }, index) => (
        <GridItem
          key={value}
          colSpan={{ base: 12, lg: index < 4 ? 3 : 4 }}
          as={HStack}
          justifyContent="center"
          borderWidth="1px"
          borderRadius="lg"
          p="15px"
        >
          <Box w="10px" h="28px" borderRadius="full" bg={value === "pending" ? "red.500" : color} />
          <Box>
            <Text fontSize="xs">
              {translator(value)} ({edges[value]})
            </Text>
            <Heading size="xs">{data[value] || 0}</Heading>
          </Box>
        </GridItem>
      ))}
    </Grid>
  );
};

export default ByPriority;
