import moment from "moment";
import _ from "lodash";
import { Avatar, AvatarGroup, Box, HStack, Link, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "title",
    title: "Título",
    isVisible: true,
    render: ({ _id, title }) => (
      <Link as={RouterLink} to={`edit/${_id}`}>
        {title}
      </Link>
    ),
    exporter: ({ title }) => title,
  },
  {
    accessor: "responsible",
    title: "Responsável",
    isVisible: true,
    isSortable: false,
    render: ({ responsible }) => (
      <HStack>
        <Avatar size="xs" name={responsible?.name} src={responsible?.avatarUrl} />
        <Text>{responsible?.name ?? "-"}</Text>
      </HStack>
    ),
    exporter: ({ responsible }) => responsible?.name ?? "-",
  },
  {
    accessor: "users",
    title: "Usuários",
    isVisible: true,
    isSortable: false,
    render: ({ users }) => (
      <HStack>
        <AvatarGroup size="xs" max={2}>
          {_.map(users, (o) => (
            <Avatar name={o.name} src={o.avatarUrl} />
          ))}
        </AvatarGroup>
        <Text maxW="200px" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
          {_.map(users, "name").join(", ")}
        </Text>
      </HStack>
    ),
    exporter: ({ users }) => _.map(users, "name").join(", "),
  },
  {
    accessor: "demands",
    title: "Demandas",
    isVisible: true,
    isSortable: false,
    render: ({ demands }) => (
      <Text maxW="200px" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
        {_.map(demands, "title").join(", ")}
      </Text>
    ),
    exporter: ({ demands }) => _.map(demands, "title").join(", "),
  },
  {
    accessor: "isActive",
    title: "Ativo?",
    isVisible: true,
    formatter: ({ isActive }) => (isActive ? "Sim" : "Não"),
  },
  {
    accessor: "createdBy",
    title: "Criado por",
    isVisible: true,
    isSortable: false,
    formatter: ({ createdBy }) => createdBy?.name ?? "-",
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
