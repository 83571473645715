const terms = {
  consultant: "Consultor",
  manager: "Gerente",
  admin: "Administrador",
  textual: "Textual",
  numeric: "Numérico",
  monetary: "Monetário",
  percentual: "Percentual",
  prospect: "Prospecto",
  effective: "Efetivo",
  pending: "Pendente",
  unscheduled: "Previsto",
  scheduled: "Agendado",
  started: "Iniciado",
  paused: "Pausado",
  finished: "Finalizado",
  canceled: "Cancelado",
  canceled_by_customer: "Cancelado pelo cliente",
  canceled_by_consultant: "Cancelado pelo consultor",
  canceled_by_scheduling: "Cancelado pelo agendamento",
  closed: "Fechado",
  manual: "Manual",
  automatic: "Automático",
  day: "Diário",
  week: "Semanal",
  month: "Mensal",
  quarter: "Trimestral",
  year: "Anual",
  revenue: "Receita",
  expense: "Despesa",
  paid: "Pago",
  credit: "Crédito",
  debit: "Débito",
  charge: "Cobrança",
  discount: "Desconto",
  taxes: "Taxas",
  pix: "Pix",
  credit_card: "Cartão de crédito",
  debit_card: "Cartão de débito",
  boleto: "Boleto",
  deposit: "Depósito",
  transfer: "Transferência",
  cash: "Dinheiro",
  authorized: "Autorizada",
  refused: "Recusada",
  active: "Ativo",
  inactive: "Inativo",
  weekly: "Semanal",
  fortnightly: "Quinzenal",
  monthly: "Mensal",
  bimonthly: "Bimestral",
  quarterly: "Trimestral",
  semesterly: "Semestral",
  yearly: "Anual",
  tasks: "Tarefas",
  meetings: "Reuniões",
  block: "Bloqueio",
  alert: "Alerta",
  warning: "Atenção",
  normal: "Normal",
  urgent: "Urgente",
  very_low: "Muito baixa",
  low: "Baixa",
  moderate: "Moderada",
  high: "Alta",
  very_high: "Muito alta",
  critical: "Crítica",
  unknown: "Desconhecida",
  internal_remote: "Interno remoto",
  internal_in_person: "Interno presencial",
  external: "Externo",
  analytical: "Analítica",
  synthetic: "Sintética",
  fixed: "Fixo",
  variable: "Variável",
  waiting_approval: "Aguardando aprovação",
};

export const translator = (word) => {
  const term = word?.toLowerCase?.();
  if (terms[term]) return terms[term];
  return word;
};
