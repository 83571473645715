import { useMemo } from "react";
import moment from "moment-business-days";

moment.updateLocale("br", { workingWeekdays: [1, 2, 3, 4, 5] });

export const useMonthBusinessDays = (startDate) => {
  const businessDaysInMonth = useMemo(() => moment(startDate).monthBusinessDays().length, [startDate]);
  const pastBusinessDays = useMemo(() => {
    const todayDate = moment().startOf("month").toDate();
    if (startDate < todayDate) return businessDaysInMonth;
    if (startDate > todayDate) return 0;
    return moment().businessDaysIntoMonth();
  }, [startDate]);
  const pastBusinessDaysPercentage = useMemo(() => pastBusinessDays / businessDaysInMonth, [businessDaysInMonth, pastBusinessDays]);
  const remainingBusinessDaysPercentage = useMemo(() => 1 - pastBusinessDaysPercentage, [pastBusinessDaysPercentage]);
  const totalMinutesInMonth = useMemo(() => businessDaysInMonth * 60 * 8, [businessDaysInMonth]);

  return { businessDaysInMonth, pastBusinessDays, pastBusinessDaysPercentage, remainingBusinessDaysPercentage, totalMinutesInMonth };
};
