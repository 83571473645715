import React, { memo, useMemo } from "react";
import { useLocation, Link as RouterLink } from "react-router-dom";
import { Heading, HStack, Tab, TabList, TabPanel, TabPanels, Tabs, Icon, Link, Divider } from "@chakra-ui/react";
import { Breadcrumb } from "components";
import { useDocumentTitle } from "hooks";
import { Content } from "pages/Private/Container";
import { MdRemove } from "react-icons/md";
import { PermissionedContainer } from "components";

const tabs = [{ hash: "#records", title: "cadastros" }];

const ListItem = memo(({ to, title }) => {
  return (
    <HStack p="10px" transition="200ms" fontSize="sm">
      <Icon as={MdRemove} />
      <Link as={RouterLink} to={to}>
        {title}
      </Link>
    </HStack>
  );
});

const Container = () => {
  useDocumentTitle("Configurações");
  const location = useLocation();
  const activeIndex = useMemo(() => {
    const index = tabs.findIndex((o) => o.hash === location.hash);
    if (index !== -1) return index;
    return 0;
  }, [location.hash]);

  return (
    <Content>
      <HStack justify="space-between">
        <HStack spacing="20px">
          <Breadcrumb items={[{ label: "configurações" }]} />
        </HStack>
      </HStack>

      <Heading size="md" my="15px">
        Configurações
      </Heading>

      <Tabs colorScheme="main" defaultIndex={activeIndex}>
        <TabList>
          {tabs.map((tab) => (
            <Tab key={tab.hash} as={RouterLink} to={tab.hash}>
              {tab.title}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          <TabPanel p="0" pt="10px">
            <PermissionedContainer required="teams:read">
              <ListItem to="/settings/records/teams" title="Times" />
              <Divider />
            </PermissionedContainer>
            <PermissionedContainer required="demands:read">
              <ListItem to="/settings/records/demands" title="Demandas" />
              <Divider />
            </PermissionedContainer>
            <PermissionedContainer required="meeting-rooms:read">
              <ListItem to="/settings/records/meeting-rooms" title="Salas de reunião" />
              <Divider />
            </PermissionedContainer>
            <PermissionedContainer required="meeting-types:read">
              <ListItem to="/settings/records/meeting-types" title="Tipos de reunião" />
              <Divider />
            </PermissionedContainer>
            <PermissionedContainer required="indicator-types:read">
              <ListItem to="/settings/records/indicator-types" title="Tipos de indicadores" />
              <Divider />
            </PermissionedContainer>
            <PermissionedContainer required="user-groups:read">
              <ListItem to="/settings/records/user-groups" title="Grupos de usuário" />
              <Divider />
            </PermissionedContainer>
            <PermissionedContainer required="segments:read">
              <ListItem to="/settings/records/segments" title="Segmentos" />
              <Divider />
            </PermissionedContainer>
            <PermissionedContainer required="companies:read">
              <ListItem to="/settings/records/companies" title="Empresas" />
              <Divider />
            </PermissionedContainer>
            <PermissionedContainer required="cost-centers:read">
              <ListItem to="/settings/records/cost-centers" title="Centros de custos" />
              <Divider />
            </PermissionedContainer>
            <PermissionedContainer required="accounts:read">
              <ListItem to="/settings/records/accounts" title="Contas" />
              <Divider />
            </PermissionedContainer>
            <PermissionedContainer required="crons:read">
              <ListItem to="/settings/records/crons" title="Crons" />
              <Divider />
            </PermissionedContainer>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Content>
  );
};

export default Container;
