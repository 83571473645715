import React from "react";

const Icon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 0 10.109 10.109">
      <path
        id="Caminho_101"
        data-name="Caminho 101"
        d="M1855.866,470.661a1.9,1.9,0,1,1,1.9-1.9A1.9,1.9,0,0,1,1855.866,470.661Zm0-2.948a1.053,1.053,0,1,0,1.053,1.053A1.052,1.052,0,0,0,1855.866,467.713Zm3.159,7.16v-.21a3.159,3.159,0,1,0-6.318,0v.21a.421.421,0,1,0,.842,0v-.21a2.317,2.317,0,1,1,4.633,0v.21a.422.422,0,1,0,.843,0Zm3.791-2.106a2.949,2.949,0,0,0-4.915-2.2.421.421,0,0,0,.556.633l.006-.006a2.106,2.106,0,0,1,3.51,1.57.422.422,0,1,0,.843,0Zm-2.738-3.791a1.9,1.9,0,1,1,1.9-1.9A1.9,1.9,0,0,1,1860.078,468.976Zm0-2.948a1.053,1.053,0,1,0,1.053,1.053A1.052,1.052,0,0,0,1860.078,466.028Z"
        transform="translate(-1852.707 -465.186)"
        fill="#cc7729"
      />
    </svg>
  );
};

export default Icon;
