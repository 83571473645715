import React, { useMemo } from "react";
import _ from "lodash";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Button, Divider, Heading, HStack, Icon, SlideFade, Spinner, Text } from "@chakra-ui/react";
import { Breadcrumb } from "components";
import { useApiGet, useDocumentTitle } from "hooks";
import { MdChevronLeft } from "react-icons/md";
import { Content } from "pages/Private/Container";

export const VersionsView = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar versão" : "Nova versão");
  const navigate = useNavigate();
  const location = useLocation();
  const [data, isLoadingData] = useApiGet(useMemo(() => ({ path: `/versions/${_id}` }), [_id]));

  return (
    <>
      <Content>
        <HStack justify="space-between">
          <HStack spacing={{ base: "10px", lg: "20px" }}>
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { to: "/versions", label: "versões" },
                { to: location.pathname, label: "detalhamento" },
              ]}
            />
          </HStack>
        </HStack>

        <Box my="20px">
          <HStack>
            <Heading>Versão {data?.versionCode}</Heading>
            {isLoadingData && <Spinner />}
          </HStack>
          {/* <Text fontSize="sm">Publicada em {moment(data?.publishedAt).format("DD/MM/YYYY [às] HH:mm")}</Text> */}
        </Box>
        <Text whiteSpace="pre-line">{data?.description}</Text>
      </Content>

      <Divider />
      <SlideFade in={true} offsetY="20px">
        <HStack p="20px">
          <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
            voltar
          </Button>
        </HStack>
      </SlideFade>
    </>
  );
};
