import React, { memo } from "react";
import { Box, Button, Center, Heading, HStack, Icon, Text, useToken } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Lottie } from "components";
import { MdSearch } from "react-icons/md";
import animationData from "assets/lottie/empty-box.json";

export const TableEmpty = memo(({ isLoading, size, hasNewButton = true, onNew }) => {
  const navigate = useNavigate();
  const [main100, main300, main500, main600, main800] = useToken("colors", ["main.100", "main.300", "main.500", "main.600", "main.800"]);

  return !isLoading && size === 0 ? (
    <HStack justifyContent="center" w="800px" m="30px auto">
      <Box w="300px">
        <Center mb="10px">
          <Icon as={MdSearch} boxSize="80px" color="gray.200" />
        </Center>
        <Heading textAlign="center" size="md">
          Nenhum registro encontrado
        </Heading>
        <Text textAlign="center" fontSize="sm">
          Não encontramos nenhum registro correspondente à busca informada.
        </Text>
        {hasNewButton && (
          <Center mt="20px">
            <Button colorScheme="main" size="sm" onClick={() => (onNew ? onNew() : navigate("new"))}>
              incluir cadastro
            </Button>
          </Center>
        )}
      </Box>
      <Box>
        <Lottie
          replaceColors={[
            { from: [0, 38, 125], to: main800 },
            { from: [8, 69, 221], to: main600 },
            { from: [82, 130, 250], to: main300 },
            { from: [130, 166, 253], to: main100 },
            { from: [34, 95, 247], to: main500 },
          ]}
          animationData={animationData}
          style={{ width: 300, height: 300 }}
        />
      </Box>
    </HStack>
  ) : null;
});
