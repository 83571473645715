import { useEffect, useMemo } from "react";
import _ from "lodash";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { useApiGet } from "hooks";
import { useParams } from "react-router-dom";

const moment = extendMoment(Moment);

const useSchedules = ({ _id, mainMeetingRoom, participants, startDate, endDate, placement }) => {
  const params = useParams();
  const [data, isLoadingData, refreshData] = useApiGet(
    useMemo(() => {
      const query = {
        status: { $nin: ["canceled_by_customer", "canceled_by_consultant", "canceled_by_scheduling"] },
        startDate: {
          $gte: ["@ISODate", moment(startDate).startOf("day").toDate()],
          $lte: ["@ISODate", moment(startDate).endOf("day").toDate()],
        },
        $or: [],
      };
      if (params._id) query._id = { $ne: ["@ObjectId", params._id] };
      const users = _.map(participants, "user._id").filter((o) => o);
      if (_.size(participants) >= 1) query.$or.push({ "participants.user._id": { $in: users.map((_id) => ["@ObjectId", _id]) } });
      if (placement !== "external") {
        let $in = _.map(participants, (o) => o.meetingRoom?._id);
        $in.unshift(mainMeetingRoom?._id);
        $in = $in.filter((o) => o).map((_id) => ["@ObjectId", _id]);
        query.$or = _.concat(query.$or, [{ "mainMeetingRoom._id": { $in } }, { "participants.meetingRoom._id": { $in } }]);
      }
      if (query.$or.length === 0) delete query.$or;
      const options = { isEnabled: _.isDate(startDate) };
      if (params._id) options.isEnabled = _.isString(_id);
      return {
        path: "/meetings",
        params: { query, sort: { startDate: 1 }, perPage: -1 },
        options,
      };
    }, [params._id, _id, mainMeetingRoom, participants, startDate, placement])
  );
  const schedules = useMemo(() => {
    if (isLoadingData) return [];
    const unavailables = [];
    for (const item of data?.data ?? []) {
      const startDate = moment(item.startDate).toDate();
      const endDate = moment(item.endDate).toDate();
      if (unavailables.length === 0) {
        unavailables.push({ startDate, endDate, isAvailable: false });
        continue;
      } else {
        const index = unavailables.length - 1;
        if (startDate < unavailables[index].startDate) unavailables[index].startDate = startDate;
        else if (startDate > unavailables[index].endDate) unavailables.push({ startDate, endDate, isAvailable: false });
        else if (endDate > unavailables[index].endDate) unavailables[index].endDate = endDate;
      }
    }
    const availables = [
      {
        startDate: moment(startDate).startOf("day").toDate(),
        endDate: unavailables?.[0]?.startDate ?? moment(startDate).endOf("day").toDate(),
        isAvailable: true,
      },
    ];
    for (let i = 0; i < unavailables.length; i++) {
      availables.push({
        startDate: unavailables[i].endDate,
        endDate: unavailables?.[i + 1]?.startDate ?? moment(startDate).endOf("day").toDate(),
        isAvailable: true,
      });
    }
    const response = _.concat(availables, unavailables).map((o) => ({
      ...o,
      durationInMinutes: moment.duration(moment(o.endDate).diff(o.startDate)).asMinutes(),
    }));
    return _.sortBy(response, (o) => o.startDate);
  }, [startDate, data, isLoadingData]);
  const conflictingMeetings = useMemo(() => {
    const meetings = _.filter(data?.data, (item) => {
      const meetingScheduleRange = moment.range([moment(item.startDate), moment(item.endDate)]);
      const currentScheduleRange = moment.range([moment(startDate), moment(endDate)]);
      return currentScheduleRange.overlaps(meetingScheduleRange);
    });
    if (meetings.length === 0) return {};
    const response = {};
    const pushKey = (path, key, data) => {
      if (!response[path]) response[path] = { [key]: [data] };
      else if (!response[path][key]) response[path][key] = [data];
      else {
        const index = response[path][key].findIndex((o) => o._id === data._id);
        if (index === -1) response[path][key].push(data);
      }
    };
    for (const meeting of meetings) {
      const meetingDetails = {
        _id: meeting._id,
        nid: meeting.nid,
        customerTradingName: meeting.customer.tradingName,
        scheduledDate: [moment(meeting.startDate).format("DD/MM/YYYY [de] HH:mm"), moment(meeting.endDate).format("HH:mm")].join(" às "),
        startDate: meeting.startDate,
        endDate: meeting.endDate,
      };
      if (meeting.mainMeetingRoom?._id) pushKey("meetingRooms", meeting.mainMeetingRoom._id, meetingDetails);
      for (const participant of meeting.participants) {
        if (participant.user?._id) pushKey("participants", participant.user._id, meetingDetails);
        if (participant.meetingRoom?._id) pushKey("meetingRooms", participant.meetingRoom._id, meetingDetails);
      }
    }
    return response;
  }, [data, startDate, endDate]);

  return [schedules, isLoadingData, refreshData, conflictingMeetings];
};

export default useSchedules;
