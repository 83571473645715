import { useContext, useEffect, useState } from "react";
import moment from "moment";
import { api } from "lib";
import { SocketContext } from "SocketProvider";
import { PrivateContext } from "pages/Private";
import { usePermissioned } from "hooks";

const useCounters = () => {
  const { currentUser } = useContext(PrivateContext);
  const { socket } = useContext(SocketContext);
  const [counters, setCounters] = useState({});
  const isAllowedReadCustomers = usePermissioned("customers:read");
  const isAllowedReadReceivableBills = usePermissioned("bills:receivable:read");
  const isAllowedReadPayableBills = usePermissioned("bills:payable:read");

  useEffect(() => {
    if (socket) {
      const listeners = {
        "@counters_receivable_bills": {
          isAllowed: isAllowedReadReceivableBills,
          fetchData: async () => {
            const params = { query: { isDelayed: true }, isCount: true };
            const receivableBills = await api.post("/bills/receivable", params);
            setCounters((state) => ({ ...state, receivableBills }));
          },
        },
        "@counters_payable_bills": {
          isAllowed: isAllowedReadPayableBills,
          fetchData: async () => {
            const params = { query: { isDelayed: true }, isCount: true };
            const payableBills = await api.post("/bills/payable", params);
            setCounters((state) => ({ ...state, payableBills }));
          },
        },
        "@counters_customers": {
          isAllowed: isAllowedReadCustomers,
          fetchData: async () => {
            const params = {
              query: {
                "prefs.consultations.isMinutesUsageAlerted": true,
                "prefs.consultations.minutesUsageNextAlertAt": { $lte: moment().startOf("day").toDate() },
              },
              isCount: true,
            };
            const customers = await api.post("/customers", params);
            setCounters((state) => ({ ...state, customers }));
          },
        },
        "@counters_solicitations": {
          isAllowed: true,
          fetchData: async () => {
            const params = { query: { status: "pending" }, isCount: true };
            const solicitations = await api.post("/solicitations", params);
            setCounters((state) => ({ ...state, solicitations }));
          },
        },
        "@counters_meetings": {
          isAllowed: true,
          fetchData: () => {
            (async () => {
              const params = { query: { status: "unscheduled" }, isCount: true };
              const unscheduledMeetings = await api.post("/meetings", params);
              setCounters((state) => ({ ...state, unscheduledMeetings }));
            })();
            (async () => {
              const params = { query: { status: "scheduled", isDelayed: false }, isCount: true };
              const scheduledMeetings = await api.post("/meetings", params);
              setCounters((state) => ({ ...state, scheduledMeetings }));
            })();
            (async () => {
              const params = { query: { isDelayed: true }, isCount: true };
              const delayedMeetings = await api.post("/meetings", params);
              setCounters((state) => ({ ...state, delayedMeetings }));
            })();
          },
        },
        "@counters_tasks": {
          isAllowed: true,
          fetchData: () => {
            (async () => {
              const params = { query: { status: "unscheduled" }, isCount: true };
              const unscheduledTasks = await api.post("/tasks", params);
              setCounters((state) => ({ ...state, unscheduledTasks }));
            })();
            (async () => {
              const params = { query: { status: "pending", isDelayed: false }, isCount: true };
              const pendingTasks = await api.post("/tasks", params);
              setCounters((state) => ({ ...state, pendingTasks }));
            })();
            (async () => {
              const params = { query: { isDelayed: true }, isCount: true };
              const delayedTasks = await api.post("/tasks", params);
              setCounters((state) => ({ ...state, delayedTasks }));
            })();
          },
        },
      };
      for (const key of Object.keys(listeners)) {
        if (!listeners[key].isAllowed) continue;
        listeners[key].fetchData();
        socket.on(key, listeners[key].fetchData);
      }
      return () => {
        for (const key of Object.keys(listeners)) {
          if (!listeners[key].isAllowed) continue;
          socket.removeListener(key);
        }
      };
    }
  }, [socket, currentUser._id, isAllowedReadCustomers, isAllowedReadReceivableBills, isAllowedReadPayableBills]);

  return counters;
};

export default useCounters;
