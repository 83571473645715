import React, { useContext } from "react";
import _ from "lodash";
import ReactInputMask from "react-input-mask";
import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  StackDivider,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { BoxData } from "components";
import { useArrayItemHandlers } from "hooks";
import { MdAddCircleOutline, MdCalendarToday, MdOutlineDelete } from "react-icons/md";
import { TbPlayerPause } from "react-icons/tb";
import TasksDetailsContext from "./context";
import { PrivateContext } from "pages/Private";

const Pauses = () => {
  const { currentUser } = useContext(PrivateContext);
  const { isLoadingData, formData, setFormData, formErrors, isAllowedAction } = useContext(TasksDetailsContext);
  const { handleChangeArrayItem, handleAddArrayItem, handleDeleteArrayItem } = useArrayItemHandlers(setFormData);

  return (
    <>
      <VStack align="stretch" divider={<StackDivider />} spacing={8}>
        {_.map(formData.pauses, (pause, index) => (
          <Grid key={pause._id} templateColumns="repeat(12, 1fr)" gap={4}>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <BoxData label="Pausado por" value={pause.startedBy?.name ?? "-"} />
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl isRequired={true} isInvalid={formErrors.pauses?.[index]?.startedAt}>
                <FormLabel fontSize="sm">Pausado em</FormLabel>
                <InputGroup>
                  <InputLeftElement>
                    <Icon as={MdCalendarToday} />
                  </InputLeftElement>
                  <Input
                    as={ReactInputMask}
                    mask="99/99/9999 99:99"
                    value={pause.startedAt ?? ""}
                    onChange={({ target }) => handleChangeArrayItem("pauses", index, { startedAt: target.value })}
                  />
                </InputGroup>
                <FormErrorMessage>{formErrors.pauses?.[index]?.startedAt}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <BoxData label="Retomado por" value={pause.finishedBy?.name ?? "-"} />
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: isAllowedAction ? 2 : 3 }}>
              <FormControl isRequired={true} isInvalid={formErrors.pauses?.[index]?.finishedAt}>
                <FormLabel fontSize="sm">Retomado em</FormLabel>
                <InputGroup>
                  <InputLeftElement>
                    <Icon as={MdCalendarToday} />
                  </InputLeftElement>
                  <Input
                    as={ReactInputMask}
                    mask="99/99/9999 99:99"
                    value={pause.finishedAt ?? ""}
                    onChange={({ target }) => handleChangeArrayItem("pauses", index, { finishedAt: target.value })}
                  />
                </InputGroup>
                <FormErrorMessage>{formErrors.pauses?.[index]?.finishedAt}</FormErrorMessage>
              </FormControl>
            </GridItem>
            {isAllowedAction && (
              <GridItem colSpan={{ base: 12, lg: 1 }}>
                <FormLabel fontSize="sm">&nbsp;</FormLabel>
                <Button
                  w="100%"
                  size="md"
                  variant="outline"
                  rightIcon={<Icon as={MdOutlineDelete} />}
                  onClick={() => handleDeleteArrayItem("pauses", index)}
                >
                  deletar
                </Button>
              </GridItem>
            )}

            <GridItem colSpan={{ base: 12, lg: 12 }}>
              <FormControl isRequired={true} isInvalid={formErrors.pauses?.[index]?.notes}>
                <FormLabel fontSize="sm">Notas da pausa</FormLabel>
                <Textarea
                  value={pause.notes ?? ""}
                  onChange={({ target }) => handleChangeArrayItem("pauses", index, { notes: target.value })}
                />
                <FormErrorMessage>{formErrors.pauses?.[index]?.notes}</FormErrorMessage>
              </FormControl>
            </GridItem>
          </Grid>
        ))}
      </VStack>

      {isLoadingData ? (
        <Center p="40px">
          <Spinner />
        </Center>
      ) : (
        _.size(formData.pauses) === 0 && (
          <Center paddingTop="40px" paddingBottom="20px">
            <Box textAlign="center">
              <Icon as={TbPlayerPause} boxSize={20} marginBottom="10px" />
              <Text fontSize="lg" fontWeight="bold">
                Nenhuma pausa adicionada
              </Text>
              <Text fontSize="sm">Esta tarefa ainda não possui pausas adicionadas.</Text>
            </Box>
          </Center>
        )
      )}

      {isAllowedAction && formData.finishedAt && (
        <Button
          size="sm"
          colorScheme="main"
          variant="ghost"
          mt={4}
          leftIcon={<Icon as={MdAddCircleOutline} />}
          onClick={() =>
            handleAddArrayItem("pauses", {
              startedBy: { _id: currentUser._id, name: currentUser.name },
              startedAt: formData.startedAt,
              finishedBy: { _id: currentUser._id, name: currentUser.name },
              finishedAt: formData.finishedAt,
            })
          }
        >
          adicionar pausa
        </Button>
      )}
    </>
  );
};

export default Pauses;
