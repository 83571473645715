import React, { useMemo, useState, useCallback, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactJson from "react-json-view";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { api } from "lib";
import { Breadcrumb, CustomBadge, RangeDateInput, TableEmpty } from "components";
import { messages } from "consts";
import { useApiGet, useCustomToast, useDocumentTitle } from "hooks";
import { MdChevronLeft, MdRefresh, MdSearch } from "react-icons/md";
import { Content } from "pages/Private/Container";
import { HiPlay } from "react-icons/hi";
import { LuListChecks } from "react-icons/lu";

export const CronsDetails = () => {
  const { topic } = useParams();
  useDocumentTitle("Detalhamento do cron");
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({ isLoading: true });
  const [filters, setFilters] = useState({ startDate: moment().startOf("day").toDate(), endDate: moment().endOf("day").toDate() });
  const [query, setQuery] = useState(filters);
  const [data, isLoadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/crons/${topic}`, params: { query } }), [topic, query]));
  const toast = useCustomToast();

  useEffect(() => {
    setFormData(data || { isLoading: true });
  }, [data]);

  const handleExecute = useCallback(async () => {
    try {
      setFormData((state) => ({ ...state, isLoading: true }));
      await api.put(`/crons/${topic}`, data);
      toast({ description: messages.success.executedCron, status: "success", isClosable: true });
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      refreshData();
    }
  }, [topic, refreshData]);

  const handleSubmit = useCallback(() => {
    setQuery({ startDate: filters.startDate, endDate: filters.endDate });
  }, [filters]);

  return (
    <Content>
      <HStack justify="space-between">
        <HStack>
          <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate("/settings/records/crons")}>
            voltar
          </Button>
          <Breadcrumb
            items={[
              { label: "configurações" },
              { to: "/settings/records/crons", label: "crons" },
              { to: location.pathname, label: "detalhamento" },
            ]}
          />
        </HStack>
        <HStack>
          <Button
            size="sm"
            colorScheme="green"
            leftIcon={<Icon as={HiPlay} boxSize="15px" />}
            isLoading={formData.isLoading}
            onClick={handleExecute}
          >
            executar
          </Button>
          <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingData} onClick={refreshData} />
        </HStack>
      </HStack>

      <HStack my="15px" justify="space-between">
        <Box>
          <HStack>
            <Heading size="md">{topic}</Heading>
            {isLoadingData && <Spinner size="sm" />}
          </HStack>
          <Text fontSize="sm">{_.size(formData.executions)} execuções no período selecionado.</Text>
        </Box>

        <HStack>
          <RangeDateInput
            defaultStartDate={filters.startDate}
            defaultEndDate={filters.endDate}
            onChange={(startDate, endDate) => setFilters((state) => ({ ...state, startDate, endDate }))}
          />
          <IconButton size="sm" variant="outline" icon={<Icon as={MdSearch} />} isLoading={isLoadingData} onClick={handleSubmit} />
        </HStack>
      </HStack>

      <Table size="sm" whiteSpace="nowrap">
        <Thead>
          <Tr>
            <Th>Status</Th>
            <Th>Início</Th>
            <Th>Fim</Th>
            <Th>Duração</Th>
            <Th>Criador</Th>
            <Th>#</Th>
          </Tr>
        </Thead>
        <Tbody>
          {_.map(formData.executions, (item) => (
            <Tr key={item._id} _hover={{ _light: { bg: "gray.50" }, _dark: { bg: "gray.700" } }}>
              <Td>
                <CustomBadge.Crons.Status status={item.status || "-"} />
              </Td>
              <Td>{item.startedAt ? moment(item.startedAt).format("DD/MM/YYYY [às] HH:mm") : "-"}</Td>
              <Td>{item.finishedAt ? moment(item.finishedAt).format("DD/MM/YYYY [às] HH:mm") : "-"}</Td>
              <Td>{item.durationInSeconds ? item.durationInSeconds.toLocaleString().concat(" segundos") : "-"}</Td>
              <Td>{item.createdBy?.name || "-"}</Td>
              <Td>
                <Popover placement="left">
                  <PopoverTrigger>
                    <Button size="xs" variant="outline" leftIcon={<Icon as={LuListChecks} />}>
                      log de execução
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent onClick={(e) => e.stopPropagation()}>
                    <PopoverArrow />
                    {_.isObject(item.err) && (
                      <PopoverHeader>
                        <Alert status="error" p="10px" borderRadius="lg">
                          <AlertIcon />
                          <AlertDescription fontSize="xs">{item.err.message}</AlertDescription>
                        </Alert>
                      </PopoverHeader>
                    )}
                    <PopoverBody>
                      <ReactJson src={item.data} name={false} />
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {isLoadingData && (
        <Center p="30px">
          <Spinner />
        </Center>
      )}
      <TableEmpty isLoading={isLoadingData} size={_.size(formData.executions)} hasNewButton={false} />
    </Content>
  );
};
