import React, { useMemo, useState, useCallback, useContext } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  SlideFade,
  Spinner,
  StackDivider,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import _ from "lodash";
import { api } from "lib";
import { Breadcrumb, Paginator } from "components";
import { useApiGet, useCustomToast, useDocumentTitle } from "hooks";
import { MdEdit, MdOutlineInfo, MdRefresh, MdSearch } from "react-icons/md";
import { FiTrash } from "react-icons/fi";
import { Content, ContentBody, ContentHeader } from "pages/Private/Container";
import { PrivateContext } from "pages/Private";
import moment from "moment";

export const VersionsList = () => {
  useDocumentTitle("Versões");
  const { currentUser } = useContext(PrivateContext);
  const [page, setPage] = useState(0);
  const [sort] = useState({ versionCode: -1 });
  const [perPage] = useState(100);
  const [search, setSearch] = useState("");
  const [data, isLoadingData, refreshData] = useApiGet(
    useMemo(() => ({ path: "/versions", params: { sort, page, perPage, search } }), [sort, page, perPage, search])
  );
  const [checkeds, setCheckeds] = useState([]);
  const [isLoadingDeleteData, setIsLoadingDeleteData] = useState(false);
  const { isOpen: isOpenDeleteDialog, onOpen: onOpenDeleteDialog, onClose: onCloseDeleteDialog } = useDisclosure();
  const toast = useCustomToast();

  const handleSearch = useCallback((e) => {
    e.preventDefault();
    const [{ value }] = e.target;
    setSearch(value);
    setPage(0);
  }, []);

  const handleDeleteData = useCallback(async () => {
    try {
      setIsLoadingDeleteData(true);
      await api.delete("/versions", { data: checkeds });
      setCheckeds([]);
      refreshData();
    } catch (error) {
      if (error.isHandled) return;
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingDeleteData(false);
      onCloseDeleteDialog();
    }
  }, [checkeds, onCloseDeleteDialog, toast, refreshData]);

  return (
    <>
      <Content>
        <ContentHeader>
          <HStack justify="space-between">
            <Breadcrumb items={[{ label: "versões" }]} />
            <SlideFade in={checkeds.length === 0} hidden={checkeds.length} offsetY="-20px">
              {currentUser.isOmniscient && (
                <Button as={RouterLink} to="new" size="sm" colorScheme="main">
                  incluir cadastro
                </Button>
              )}
            </SlideFade>
          </HStack>
          <Heading my="15px" size="md">
            Versões
          </Heading>
          <HStack mb="30px">
            <form onSubmit={handleSearch}>
              <InputGroup width="xs">
                <Input variant="filled" placeholder="Pesquisar..." />
                <InputRightElement>
                  <Icon as={MdSearch} />
                </InputRightElement>
              </InputGroup>
            </form>
            <HStack flex="1" justify="flex-end">
              <IconButton variant="outline" icon={<Icon as={MdRefresh} />} fontSize="sm" isLoading={isLoadingData} onClick={refreshData} />
            </HStack>
          </HStack>
        </ContentHeader>

        <ContentBody>
          <VStack align="stretch" divider={<StackDivider />} spacing={4}>
            {_.map(data?.data, (item) => (
              <HStack key={item._id}>
                <Box flex="1">
                  <Link as={RouterLink} to={`view/${item._id}`} color="blue.500" fontSize="lg" fontWeight="semibold">
                    {item.versionCode}
                  </Link>
                  {/* <Text fontSize="xs" opacity="0.6">
                    Publicada em {moment(item.publishedAt).format("DD/MM/YYYY [às] HH:mm")}
                  </Text> */}
                </Box>
                {currentUser.isOmniscient && (
                  <>
                    <Button size="xs" as={RouterLink} to={`edit/${item._id}`} variant="outline" leftIcon={<Icon as={MdEdit} />}>
                      editar
                    </Button>
                    <Button
                      size="xs"
                      variant="outline"
                      colorScheme="red"
                      leftIcon={<Icon as={FiTrash} />}
                      onClick={() => {
                        setCheckeds([item._id]);
                        onOpenDeleteDialog();
                      }}
                    >
                      excluir
                    </Button>
                  </>
                )}
              </HStack>
            ))}
          </VStack>

          {isLoadingData ? (
            <Center p="40px">
              <Spinner />
            </Center>
          ) : (
            _.size(data?.data) === 0 && (
              <Center paddingTop="40px" paddingBottom="20px">
                <Box textAlign="center">
                  <Icon as={MdOutlineInfo} boxSize={20} marginBottom="10px" />
                  <Text fontSize="lg" fontWeight="bold">
                    Nenhuma versão encontrada
                  </Text>
                  <Text fontSize="sm">Não foram encontradas versões correspondentes à sua busca.</Text>
                </Box>
              </Center>
            )
          )}
        </ContentBody>
      </Content>

      <Divider />

      <SlideFade in={checkeds.length} hidden={checkeds.length === 0} offsetY="20px">
        <HStack p="20px" justify="space-between">
          <Text>{checkeds.length} selecionados</Text>
          <Button size="sm" variant="outline" leftIcon={<Icon as={FiTrash} />} onClick={onOpenDeleteDialog}>
            excluir cadastros
          </Button>
        </HStack>
      </SlideFade>

      <SlideFade in={checkeds.length === 0} hidden={checkeds.length} offsetY="20px">
        <Box p="20px">
          <Paginator loading={isLoadingData} page={page} size={data?.size} perPage={perPage} onPaginate={setPage} />
        </Box>
      </SlideFade>

      <AlertDialog isOpen={isOpenDeleteDialog} onClose={onCloseDeleteDialog} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>Deseja realmente excluir os registros selecionados?</AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button onClick={onCloseDeleteDialog}>Cancelar</Button>
            <Button colorScheme="red" onClick={handleDeleteData} isLoading={isLoadingDeleteData}>
              Excluir
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
