import React, { useCallback, useState } from "react";
import { HStack, Icon, IconButton, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { MdSearch } from "react-icons/md";

const SearchInput = ({ value = "", onSubmit }) => {
  const [searchText, setSearchText] = useState(value);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      onSubmit(searchText);
    },
    [searchText]
  );

  return (
    <HStack p="10px" position="sticky" left="0">
      <form onSubmit={handleSubmit}>
        <InputGroup size="sm" variant="filled" width={{ base: "100%", lg: "400px" }}>
          <Input placeholder="Pesquisar..." value={searchText} onChange={({ target }) => setSearchText(target.value)} />
          <InputRightElement>
            <IconButton type="submit" size="xs" variant="outline" icon={<Icon as={MdSearch} />} />
          </InputRightElement>
        </InputGroup>
      </form>
    </HStack>
  );
};

export default SearchInput;
