import React, { useContext, useMemo } from "react";
import _ from "lodash";
import { Box, Grid, GridItem, HStack, Heading, Text } from "@chakra-ui/react";
import { statuses } from "consts";
import { percent, translator } from "lib";
import { ActivitiesContext } from "./index";
import { Status } from "./classes/Status";

const ByStatus = () => {
  const { activities } = useContext(ActivitiesContext);
  const data = useMemo(() => {
    const response = {};
    for (const { value } of statuses.tasks) response[value] = new Status(_.size(activities));
    for (const { ustatus } of activities || []) response[ustatus].incQuantity();
    return response;
  }, [activities]);

  return (
    <Grid templateColumns="repeat(12, 1fr)" gap={2}>
      {_.map(statuses.tasks, ({ color, value }) => (
        <GridItem
          key={value}
          colSpan={{ base: 12, lg: 2 }}
          as={HStack}
          justifyContent="center"
          borderWidth="1px"
          borderRadius="lg"
          p="15px"
        >
          <Box w="10px" h="28px" borderRadius="full" bg={value === "pending" ? "red.500" : color} />
          <Box>
            <Text fontSize="xs">{translator(value)}</Text>
            <Heading size="xs">
              {data[value].quantity} ({percent.format(data[value].percentage || 0)})
            </Heading>
          </Box>
        </GridItem>
      ))}
    </Grid>
  );
};

export default ByStatus;
