import React, { useState, useEffect, useMemo, useCallback, useContext } from "react";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Icon,
  SlideFade,
  Spinner,
  StackDivider,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import { MdChevronLeft } from "react-icons/md";
import * as yup from "yup";
import { messages } from "consts";
import { Breadcrumb } from "components";
import { api } from "lib";
import { useApiGet, useCustomToast, useDocumentTitle } from "hooks";
import { Content } from "pages/Private/Container";
import { PrivateContext } from "pages/Private";

export const UserPreferences = () => {
  useDocumentTitle("Preferências do usuário");
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useContext(PrivateContext);
  const [data, isLoadingData, refreshData] = useApiGet(
    useMemo(() => ({ path: `/users/${currentUser._id}/preferences` }), [currentUser._id])
  );
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const toast = useCustomToast();

  useEffect(() => {
    const formData = data ?? {};
    setFormData(formData);
  }, [data]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        await api.patch(`/users/${currentUser._id}/preferences`, data);
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        if (error.isHandled) return;
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [currentUser._id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        isAllowedEmailNotificationsForMeetingsStart: yup.boolean().required(messages.error.required),
        isAllowedEmailNotificationsForMeetingsFinish: yup.boolean().required(messages.error.required),
        isAllowedEmailNotificationsForFinishedTasks: yup.boolean().required(messages.error.required),
      });
      await schema.validate(formData, { abortEarly: false });
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <>
      <Content>
        <HStack justify="space-between">
          <HStack spacing={{ base: "10px", lg: "20px" }}>
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
              voltar
            </Button>
            <Breadcrumb items={[{ to: location.pathname, label: "preferências" }]} />
          </HStack>
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Preferências do usuário</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{currentUser._id}</Text>
          </Box>
        </HStack>

        <VStack alignItems="center" spacing="20px" divider={<StackDivider />}>
          <FormControl as={HStack} isRequired={true} isInvalid={formErrors.isAllowedEmailNotificationsForMeetingsStart}>
            <Box flex="1">
              <FormLabel mb="0">Notificações via e-mail para início de reunião</FormLabel>
              <FormHelperText mt="0">
                Permitir que sejam enviadas notificações via e-mail alertando o usuário sobre o horário de início da reunião.
              </FormHelperText>
            </Box>
            <Box>
              <Switch
                isChecked={formData.isAllowedEmailNotificationsForMeetingsStart}
                onChange={() =>
                  setFormData((state) => ({
                    ...state,
                    isAllowedEmailNotificationsForMeetingsStart: !state.isAllowedEmailNotificationsForMeetingsStart,
                  }))
                }
              />
              <FormErrorMessage>{formErrors.isAllowedEmailNotificationsForMeetingsStart}</FormErrorMessage>
            </Box>
          </FormControl>

          <FormControl as={HStack} isRequired={true} isInvalid={formErrors.isAllowedEmailNotificationsForMeetingsFinish}>
            <Box flex="1">
              <FormLabel mb="0">Notificações via e-mail para fim de reunião</FormLabel>
              <FormHelperText mt="0">
                Permitir que sejam enviadas notificações via e-mail alertando o usuário sobre o horário de fim da reunião.
              </FormHelperText>
            </Box>
            <Box>
              <Switch
                isChecked={formData.isAllowedEmailNotificationsForMeetingsFinish}
                onChange={() =>
                  setFormData((state) => ({
                    ...state,
                    isAllowedEmailNotificationsForMeetingsFinish: !state.isAllowedEmailNotificationsForMeetingsFinish,
                  }))
                }
              />
              <FormErrorMessage>{formErrors.isAllowedEmailNotificationsForMeetingsFinish}</FormErrorMessage>
            </Box>
          </FormControl>

          <FormControl as={HStack} isRequired={true} isInvalid={formErrors.isAllowedEmailNotificationsForFinishedTasks}>
            <Box flex="1">
              <FormLabel mb="0">Notificações via e-mail para tarefas finalizadas</FormLabel>
              <FormHelperText mt="0">
                Permitir que sejam enviadas notificações via e-mail quando uma tarefa relacionada ao usuário for finalizada.
              </FormHelperText>
            </Box>
            <Box>
              <Switch
                isChecked={formData.isAllowedEmailNotificationsForFinishedTasks}
                onChange={() =>
                  setFormData((state) => ({
                    ...state,
                    isAllowedEmailNotificationsForFinishedTasks: !state.isAllowedEmailNotificationsForFinishedTasks,
                  }))
                }
              />
              <FormErrorMessage>{formErrors.isAllowedEmailNotificationsForFinishedTasks}</FormErrorMessage>
            </Box>
          </FormControl>
        </VStack>
      </Content>

      <Divider />
      <SlideFade in={true} offsetY="20px">
        <HStack p="20px">
          <Button size="sm" colorScheme="main" isLoading={isLoadingData || isLoadingSaveData} onClick={handleSubmit}>
            salvar
          </Button>
          <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
            voltar
          </Button>
        </HStack>
      </SlideFade>
    </>
  );
};
