import { useMemo } from "react";
import Fuse from "fuse.js";

export const useSearch = ({ keys, data, searchText }) => {
  return useMemo(() => {
    if (!searchText) return data;
    const splitted = searchText.split(",");
    const query = (() => {
      if (splitted.length === 1) return searchText;
      const $or = splitted.map((value) => {
        const obj = {};
        for (const key of keys) obj[key] = value;
        return obj;
      });
      return { $or };
    })();
    const fuse = new Fuse(data, { keys, includeScore: true, threshold: 0.1 });
    const results = fuse.search(query);
    return results.map((o) => o.item);
  }, [keys, data, searchText]);
};
