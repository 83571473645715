import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useNewTabState = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const state = useMemo(() => {
    if (location.hash) {
      const value = localStorage.getItem(location.hash);
      if (value) {
        const state = JSON.parse(value);
        localStorage.removeItem(location.hash);
        return state;
      }
    }
  }, [location.pathname, location.hash]);

  useEffect(() => {
    if (state) navigate(location.pathname, { state, replace: true });
  }, [state]);

  return state;
};
