import React, { useContext, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import _ from "lodash";
import {
  Avatar,
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  Select,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useArrayItemHandlers, useApiGet } from "hooks";
import { SyncSelect, PermissionedContainer } from "components";
import { MdOutlineDelete, MdAddCircleOutline, MdRefresh } from "react-icons/md";
import { TbUsers } from "react-icons/tb";
import MeetingsDetailsContext from "../context";
import ConflictingMeetings from "./conflictingMeetings";

const Participants = () => {
  const { formData, setFormData, formErrors, meetingRooms, isLoadingMeetingRooms, refreshMeetingRooms } =
    useContext(MeetingsDetailsContext);
  const { handleChangeArrayItem, handleAddArrayItem, handleDeleteArrayItem } = useArrayItemHandlers(setFormData);
  const [users, isLoadingUsers, refreshUsers] = useApiGet(
    useMemo(
      () => ({
        path: "/users",
        params: {
          query: { isActive: true },
          sort: { name: 1 },
          perPage: -1,
          isAutocomplete: true,
        },
      }),
      []
    )
  );
  const participants = useMemo(() => {
    const ids = _.map(formData.participants, (o) => o.user?._id);
    return _.map(users?.data, (o) => ({ ...o, isDisabled: ids.indexOf(o._id) !== -1 }));
  }, [formData.participants, users?.data]);

  return (
    <>
      <Box mb={6}>
        <Heading size="sm">Participantes</Heading>
        {_.isString(formErrors.participants) && (
          <Text fontSize="sm" color="red.500">
            {formErrors.participants}
          </Text>
        )}
      </Box>
      <VStack align="stretch" divider={<StackDivider opacity={{ base: "1", lg: "0" }} />} spacing={{ base: "30px", lg: "10px" }}>
        {_.map(formData.participants, (participant, index) => (
          <Grid key={index} templateColumns="repeat(12, 1fr)" gap={4}>
            <GridItem colSpan={{ base: 12, lg: formData.placement === "external" ? 9 : 5 }}>
              <FormControl isRequired={true} isInvalid={formErrors.participants?.[index]?.user}>
                <HStack mb="0.5em" justifyContent="space-between">
                  <FormLabel fontSize="sm" m="0">
                    Usuário
                  </FormLabel>
                  {participant.user ? (
                    <PermissionedContainer required="users:update">
                      <Link as={RouterLink} to={`/users/edit/${participant.user._id}`} target="_blank" fontSize="xs">
                        editar cadastro
                      </Link>
                    </PermissionedContainer>
                  ) : (
                    <PermissionedContainer required="users:create">
                      <Link as={RouterLink} to={`/users/new`} target="_blank" color="blue.500" fontSize="xs">
                        incluir cadastro
                      </Link>
                    </PermissionedContainer>
                  )}
                </HStack>
                <HStack>
                  <SyncSelect
                    value={participant.user}
                    options={participants}
                    placeholder="Selecione"
                    selectedOptionStyle="check"
                    onChange={(user) => handleChangeArrayItem("participants", index, { user })}
                    getOptionValue={({ _id }) => _id}
                    formatOptionLabel={({ _id, avatarUrl, name }) => (
                      <HStack w="100%">
                        <Avatar size="xs" name={name} src={avatarUrl} />
                        <Text flex="1">{name}</Text>
                        <ConflictingMeetings path="participants" selected={_id} isButtonOnly={true} />
                      </HStack>
                    )}
                    isClearable={true}
                  />
                  <IconButton variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingUsers} onClick={refreshUsers} />
                  <ConflictingMeetings path="participants" selected={participant.user?._id} />
                </HStack>
                <FormErrorMessage>{formErrors.participants?.[index]?.user}</FormErrorMessage>
              </FormControl>
            </GridItem>
            {formData.placement !== "external" && (
              <GridItem colSpan={{ base: 12, lg: 4 }}>
                <FormControl isRequired={true} isInvalid={formErrors.participants?.[index]?.meetingRoom}>
                  <HStack mb="0.5em" justifyContent="space-between">
                    <FormLabel fontSize="sm" m="0">
                      Sala de reunião
                    </FormLabel>
                    {participant.meetingRoom ? (
                      <PermissionedContainer required="meeting-rooms:update">
                        <Link as={RouterLink} to={`/meeting-rooms/edit/${participant.meetingRoom._id}`} target="_blank" fontSize="xs">
                          editar cadastro
                        </Link>
                      </PermissionedContainer>
                    ) : (
                      <PermissionedContainer required="meeting-rooms:create">
                        <Link as={RouterLink} to={`/meeting-rooms/new`} target="_blank" color="blue.500" fontSize="xs">
                          incluir cadastro
                        </Link>
                      </PermissionedContainer>
                    )}
                  </HStack>
                  <HStack>
                    <SyncSelect
                      value={participant.meetingRoom}
                      options={meetingRooms?.data}
                      placeholder="Selecione"
                      selectedOptionStyle="check"
                      onChange={(meetingRoom) => handleChangeArrayItem("participants", index, { meetingRoom })}
                      getOptionValue={({ _id }) => _id}
                      getOptionLabel={({ title }) => title}
                      formatOptionLabel={({ _id, title }) => (
                        <HStack w="100%">
                          <Text flex="1">{title}</Text>
                          <ConflictingMeetings path="meetingRooms" selected={_id} isButtonOnly={true} />
                        </HStack>
                      )}
                      isClearable={true}
                    />
                    <IconButton
                      variant="outline"
                      icon={<Icon as={MdRefresh} />}
                      isLoading={isLoadingMeetingRooms}
                      onClick={refreshMeetingRooms}
                    />
                    <ConflictingMeetings path="meetingRooms" selected={participant.meetingRoom?._id} />
                  </HStack>
                  <FormErrorMessage>{formErrors.participants?.[index]?.meetingRoom}</FormErrorMessage>
                </FormControl>
              </GridItem>
            )}
            <GridItem colSpan={{ base: 8, lg: 2 }}>
              <FormControl isInvalid={formErrors.participants?.[index]?.isObserver}>
                <FormLabel fontSize="sm">Acompanhante?</FormLabel>
                <Select
                  value={participant.isObserver ? "yes" : "no"}
                  onChange={({ target }) => handleChangeArrayItem("participants", index, { isObserver: target.value === "yes" })}
                >
                  <option value="yes">Sim</option>
                  <option value="no">Não</option>
                </Select>
                <FormErrorMessage>{formErrors.participants?.[index]?.isObserver}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 4, lg: 1 }}>
              <FormLabel fontSize="sm">&nbsp;</FormLabel>
              <Button
                w="100%"
                rightIcon={<Icon as={MdOutlineDelete} />}
                size="md"
                variant="outline"
                onClick={() => handleDeleteArrayItem("participants", index)}
              >
                deletar
              </Button>
            </GridItem>
          </Grid>
        ))}
      </VStack>

      {_.size(formData.participants) === 0 && (
        <Center paddingTop="40px" paddingBottom="20px">
          <Box textAlign="center">
            <Icon as={TbUsers} boxSize={20} marginBottom="10px" />
            <Text fontSize="lg" fontWeight="bold">
              Nenhum participante
            </Text>
            <Text fontSize="sm">Esta reunião ainda não possui participantes.</Text>
          </Box>
        </Center>
      )}

      <Button
        size="sm"
        colorScheme="main"
        variant="ghost"
        mt={4}
        leftIcon={<Icon as={MdAddCircleOutline} />}
        onClick={() => handleAddArrayItem("participants", { meetingRoom: formData.mainMeetingRoom, isObserver: false })}
      >
        adicionar participante
      </Button>
    </>
  );
};

export default Participants;
