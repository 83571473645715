import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { Box, Button, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, HStack, Icon, Input, Select } from "@chakra-ui/react";
import { useStickyState } from "hooks";
import { MdSearch } from "react-icons/md";
import { AsyncSelect, SyncSelect } from "components";
import { api, yup } from "lib";
import ReactInputMask from "react-input-mask";
import { messages } from "consts";

let loadDemandsTimeout = {};
let loadMeetingTypesTimeout;

const moduleNames = [
  { value: "tasks", label: "Tarefas" },
  { value: "meetings", label: "Reuniões" },
];

const Filters = ({ onQuery, isLoading }) => {
  const location = useLocation();
  const [query, setQuery] = useStickyState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters"),
        defaultValue: {
          moduleNames,
          baseDate: moment().format("MM/YYYY"),
          startDate: moment().startOf("month").toDate(),
          endDate: moment().endOf("month").format(),
        },
        useCached: true,
        _v: 4,
        processor: (data) => ({
          ...data,
          startDate: data.startDate && moment(data.startDate).toDate(),
          endDate: data.endDate && moment(data.endDate).toDate(),
        }),
      }),
      [location.pathname]
    )
  );
  const [formData, setFormData] = useState(query);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    onQuery(query);
  }, [query]);

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        startDate: yup.date().typeError(messages.error.invalidDate).required(messages.error.required),
        endDate: yup.date().typeError(messages.error.invalidDate).required(messages.error.required),
      });
      const data = {
        ...formData,
        startDate: moment(formData.baseDate, "MM/YYYY").startOf("month").toDate(),
        endDate: moment(formData.baseDate, "MM/YYYY").endOf("month").toDate(),
      };
      await schema.validate(data, { abortEarly: false });
      setQuery(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [setQuery, formData]);

  const handleLoadDemands = useCallback((key, search, cb) => {
    clearTimeout(loadDemandsTimeout[key]);
    loadDemandsTimeout[key] = setTimeout(async () => {
      const response = await api.post("/demands", {
        search,
        query: { isActive: true },
        sort: { title: 1 },
        perPage: 20,
        isAutocomplete: true,
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleLoadMeetingType = useCallback((search, cb) => {
    clearTimeout(loadMeetingTypesTimeout);
    loadMeetingTypesTimeout = setTimeout(async () => {
      const response = await api.post("/meeting-types", {
        search,
        query: { isActive: true },
        sort: { title: 1 },
        perPage: 20,
        isAutocomplete: true,
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  return (
    <Box
      mb={8}
      padding={{ base: "10px", lg: "20px" }}
      _light={{ bg: "gray.50" }}
      _dark={{ bg: "gray.900" }}
      borderRadius="lg"
      transition="400ms"
    >
      <Grid templateColumns="repeat(12, 1fr)" gap={2} mb={4}>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl>
            <FormLabel fontSize="xs" mb="5px">
              Módulos
            </FormLabel>
            <SyncSelect
              size="sm"
              variant="filled"
              isMulti
              value={formData.moduleNames ?? []}
              placeholder="Selecione"
              options={moduleNames}
              onChange={(moduleNames) => setFormData((state) => ({ ...state, moduleNames }))}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={true} isInvalid={formErrors.startDate || formErrors.endDate}>
            <FormLabel fontSize="xs" mb="5px">
              Data base
            </FormLabel>
            <Input
              as={ReactInputMask}
              mask="99/9999"
              size="sm"
              variant="filled"
              value={formData.baseDate || ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, baseDate: target.value }))}
            />
            <FormErrorMessage>{formErrors.startDate || formErrors.endDate}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl>
            <FormLabel fontSize="xs" mb="5px">
              Atrasadas?
            </FormLabel>
            <Select
              size="sm"
              variant="filled"
              value={formData.isDelayed || ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, isDelayed: target.value }))}
            >
              <option value="">Todas</option>
              <option value="yes">Sim</option>
              <option value="no">Não</option>
            </Select>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl>
            <FormLabel fontSize="xs" mb="5px">
              Demandas
            </FormLabel>
            <AsyncSelect
              size="sm"
              variant="filled"
              isMulti
              value={formData.demands ?? []}
              defaultOptions
              loadOptions={(search, cb) => handleLoadDemands("demands", search, cb)}
              placeholder="Selecione"
              onChange={(demands) => setFormData((state) => ({ ...state, demands }))}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ title }) => title}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl>
            <FormLabel fontSize="xs" mb="5px">
              Demandas (exceto)
            </FormLabel>
            <AsyncSelect
              size="sm"
              variant="filled"
              isMulti
              value={formData.demandsExcept ?? []}
              defaultOptions
              loadOptions={(search, cb) => handleLoadDemands("demandsExcept", search, cb)}
              placeholder="Selecione"
              onChange={(demandsExcept) => setFormData((state) => ({ ...state, demandsExcept }))}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ title }) => title}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl>
            <FormLabel fontSize="xs" mb="5px">
              Tipo de reunião
            </FormLabel>
            <AsyncSelect
              size="sm"
              variant="filled"
              isMulti
              value={formData.meetingType ?? []}
              defaultOptions
              loadOptions={handleLoadMeetingType}
              placeholder="Selecione"
              onChange={(meetingType) => setFormData((state) => ({ ...state, meetingType }))}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ title }) => title}
            />
          </FormControl>
        </GridItem>
      </Grid>
      <HStack justifyContent="flex-end">
        <Button
          size="sm"
          colorScheme="main"
          rightIcon={<Icon as={MdSearch} />}
          isLoading={isLoading}
          onClick={handleSubmit}
          isDisabled={_.size(formData.moduleNames) === 0}
        >
          aplicar
        </Button>
      </HStack>
    </Box>
  );
};

export default Filters;
