export const colorScale = (value) => {
  const intervals = [
    [0, 0.05, "white", "green.700"],
    [0.05, 0.1, "white", "green.700"],
    [0.1, 0.15, "white", "green.600"],
    [0.15, 0.2, "white", "green.600"],
    [0.2, 0.25, "white", "green.500"],
    [0.25, 0.3, "white", "green.500"],
    [0.3, 0.35, "white", "green.400"],
    [0.35, 0.4, "white", "green.400"],

    [0.4, 0.45, "yellow.800", "yellow.400"],
    [0.45, 0.5, "yellow.800", "yellow.400"],
    [0.5, 0.55, "white", "yellow.500"],
    [0.55, 0.6, "white", "yellow.500"],
    [0.6, 0.65, "white", "orange.500"],
    [0.65, 0.7, "white", "orange.500"],

    [0.7, 0.75, "white", "red.500"],
    [0.75, 0.8, "white", "red.500"],
    [0.8, 0.85, "white", "red.600"],
    [0.85, 0.9, "white", "red.600"],
    [0.9, 0.95, "white", "red.700"],
    [0.95, 1, "white", "red.700"],
    [1, Infinity, "white", "red.800"],
  ];

  for (const [start, end, color, bg] of intervals) if (value >= start && value < end) return { color, bg };
};
