import React from "react";

const Icon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 0 10.08 12.097">
      <path
        id="Caminho_99"
        data-name="Caminho 99"
        d="M1860.281,441.133a.5.5,0,0,1-.5.5h-4.032a.5.5,0,1,1,0-1.008h4.032A.5.5,0,0,1,1860.281,441.133Zm-2.016,1.512h-2.52a.5.5,0,1,0,0,1.008h2.52a.5.5,0,1,0,0-1.008Zm4.536-3.284v4.292a2.523,2.523,0,0,1-2.52,2.52h-5.04a2.523,2.523,0,0,1-2.52-2.52V436.6a2.523,2.523,0,0,1,2.52-2.521h2.276a3.51,3.51,0,0,1,2.495,1.034l1.756,1.757A3.5,3.5,0,0,1,1862.8,439.361Zm-3.5-3.539a2.582,2.582,0,0,0-.53-.393v2.176a.5.5,0,0,0,.5.5h2.176a2.555,2.555,0,0,0-.394-.53Zm2.494,3.539c0-.083-.016-.163-.023-.244h-2.5a1.512,1.512,0,0,1-1.512-1.512v-2.5c-.082-.007-.162-.024-.244-.024h-2.276a1.512,1.512,0,0,0-1.512,1.513v7.056a1.512,1.512,0,0,0,1.512,1.512h5.04a1.512,1.512,0,0,0,1.512-1.512Z"
        transform="translate(-1852.721 -434.076)"
        fill="#cc7729"
      />
    </svg>
  );
};

export default Icon;
