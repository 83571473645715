import React from "react";
import { Icon, IconButton } from "@chakra-ui/react";
import { TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted } from "react-icons/ti";

const SortButton = ({ keyName, sort, setSort }) => {
  if (keyName !== sort.key)
    return (
      <IconButton
        size="xs"
        opacity="0.4"
        variant="ghost"
        icon={<Icon as={TiArrowUnsorted} />}
        onClick={() => setSort({ key: keyName, direction: "asc" })}
      />
    );
  return (
    <IconButton
      size="xs"
      variant="solid"
      icon={<Icon as={sort.direction === "asc" ? TiArrowSortedDown : TiArrowSortedUp} />}
      onClick={() => setSort({ key: keyName, direction: sort.direction === "asc" ? "desc" : "asc" })}
    />
  );
};

export default SortButton;
