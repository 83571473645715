import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { Box, Button, FormControl, FormLabel, Grid, GridItem, HStack, Icon, IconButton, useColorModeValue, Text } from "@chakra-ui/react";
import { AsyncSelect, SyncSelect, RangeDatePicker, CustomerLogo } from "components";
import { useCacheState, useStickyState } from "hooks";
import { api, masks, translator } from "lib";
import { statuses } from "consts";
import { MdClose, MdRefresh, MdSearch } from "react-icons/md";
import { HiOutlineFilter } from "react-icons/hi";
import { BiCog } from "react-icons/bi";

const periodTypes = ["day", "week", "month", "quarter", "year"];

let loadCustomersTimeout;

const Filters = ({ onQuery, onRefresh, onPage, isLoading, onTableDrawer }) => {
  const location = useLocation();
  const [query, setQuery] = useStickyState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.query"),
        defaultValue: location.state ?? { status: [] },
        useCached: _.isObject(location.state) === false,
        _v: 1,
        processor: (data) => ({
          ...data,
          referenceDateStartDate: data.referenceDateStartDate && moment(data.referenceDateStartDate).toDate(),
          referenceDateEndDate: data.referenceDateEndDate && moment(data.referenceDateEndDate).toDate(),
        }),
      }),
      [location.pathname, location.state]
    )
  );
  const [isOpen, setIsOpen] = useCacheState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.isOpen"),
        defaultValue: false,
      }),
      []
    )
  );
  const [formData, setFormData] = useState(query);
  const [isFiltering, setIsFiltering] = useState(false);
  const backgroundColor = useColorModeValue("gray.50", "blackAlpha.300");
  const containerProps = useMemo(
    () => isOpen && { padding: { base: "10px", lg: "20px" }, backgroundColor, marginBottom: 8 },
    [isOpen, backgroundColor]
  );

  useEffect(() => {
    const response = {};
    if (query.status?.length) _.set(response, "status.$in", _.map(query.status, "value"));
    if (query.customer?._id) _.set(response, "customer", query.customer._id);
    if (query.customer?.length) _.set(response, "customer.$in", _.map(query.customer, "_id"));
    if (query.periodType?.length) _.set(response, "periodType.$in", query.periodType);
    if (query.referenceDateStartDate) _.set(response, "referenceDateStartDate.$gte", query.referenceDateStartDate);
    if (query.referenceDateEndDate) _.set(response, "referenceDateEndDate.$lte", query.referenceDateEndDate);
    setIsFiltering(Object.keys(response).length > 0);
    if (location.state?.customer) response.customer = location.state?.customer?._id;
    onQuery(response);
    onPage(0);
  }, [onQuery, onPage, query, location.state]);

  const handleSubmit = useCallback(() => {
    setQuery(formData);
  }, [setQuery, formData]);

  const handleClean = useCallback(() => {
    setQuery({});
    setFormData({});
  }, [setQuery, setFormData]);

  const handleLoadCustomers = useCallback((search, cb) => {
    clearTimeout(loadCustomersTimeout);
    loadCustomersTimeout = setTimeout(async () => {
      const params = { search, query: { status: { $ne: "inactive" } }, isAutocomplete: true, perPage: 20 };
      const response = await api.post("/customers", params);
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  return (
    <Box {...containerProps} borderRadius="lg" transition="400ms">
      <Grid templateColumns="repeat(12,1fr)" gap={2} mb={8}>
        <GridItem as={HStack} justifyContent="space-between" colSpan={{ base: 12, lg: 12 }}>
          <HStack>
            <Button
              colorScheme={isFiltering ? "main" : "gray"}
              variant="outline"
              rightIcon={<Icon as={HiOutlineFilter} />}
              onClick={() => setIsOpen((state) => !state)}
            >
              filtros
            </Button>
            {isFiltering && (
              <Button variant="outline" rightIcon={<Icon as={MdClose} />} onClick={handleClean}>
                limpar filtros
              </Button>
            )}
          </HStack>
          <HStack>
            <IconButton variant="outline" icon={<Icon as={MdRefresh} />} fontSize="sm" isLoading={isLoading} onClick={onRefresh} />
            <IconButton fontSize="sm" variant="outline" icon={<Icon as={BiCog} />} onClick={onTableDrawer} />
          </HStack>
        </GridItem>
      </Grid>
      {isOpen && (
        <>
          <Grid templateColumns="repeat(12, 1fr)" gap={2} mb={4}>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Status
                </FormLabel>
                <SyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.status ?? []}
                  placeholder="Selecione"
                  options={statuses.customerIndicators}
                  onChange={(status) => setFormData((state) => ({ ...state, status }))}
                  formatOptionLabel={({ color, value }) => (
                    <HStack>
                      <Box bg={color} w="10px" h="10px" borderRadius="full" />
                      <Text>{translator(value)}</Text>
                    </HStack>
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 8 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Cliente
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.customer ?? []}
                  defaultOptions
                  loadOptions={handleLoadCustomers}
                  placeholder="Selecione"
                  onChange={(customer) => setFormData((state) => ({ ...state, customer }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ logoUrl, tradingName, segment }) => (
                    <HStack>
                      <CustomerLogo alt={tradingName} src={logoUrl} boxSize="35px" />
                      <Box flex="1">
                        <Text fontSize="sm" noOfLines={1}>
                          {tradingName}
                        </Text>
                        <Text fontSize="xs">{segment?.title || "-"}</Text>
                      </Box>
                    </HStack>
                  )}
                  isClearable={true}
                  isDisabled={location.state?.customer}
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Tipo de período
                </FormLabel>
                <SyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={_.map(formData.periodType, (value) => ({ value, label: translator(value) }))}
                  placeholder="Selecione"
                  options={_.map(periodTypes, (value) => ({ value, label: translator(value) }))}
                  onChange={(periodType) => setFormData((state) => ({ ...state, periodType: _.map(periodType, ({ value }) => value) }))}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 8 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Data de referência
                </FormLabel>
                <HStack>
                  <RangeDatePicker
                    key={formData.referenceDateStartDateTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    selectedDates={[formData.referenceDateStartDate, formData.referenceDateEndDate]}
                    onDateChange={([referenceDateStartDate, referenceDateEndDate]) =>
                      setFormData((state) => ({ ...state, referenceDateStartDate, referenceDateEndDate }))
                    }
                  />
                  {formData.referenceDateStartDate && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          referenceDateStartDate: null,
                          referenceDateEndDate: null,
                          referenceDateStartDateTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>
          </Grid>
          <HStack justifyContent="flex-end">
            <Button size="sm" colorScheme="main" rightIcon={<Icon as={MdSearch} />} isLoading={isLoading} onClick={handleSubmit}>
              aplicar
            </Button>
          </HStack>
        </>
      )}
    </Box>
  );
};

export default Filters;
