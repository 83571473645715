import React from "react";

const Icon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 0 10.118 10.118">
      <path
        id="Caminho_89"
        data-name="Caminho 89"
        d="M1857.761,396.985a1.686,1.686,0,1,1,1.686-1.686A1.686,1.686,0,0,1,1857.761,396.985Zm0-2.53a.844.844,0,1,0,.843.844A.843.843,0,0,0,1857.761,394.455Zm2.53,5.481a2.529,2.529,0,1,0-5.059,0,.421.421,0,1,0,.843,0,1.686,1.686,0,0,1,3.372,0,.422.422,0,1,0,.844,0Zm0-6.324a1.686,1.686,0,1,1,1.686-1.686A1.686,1.686,0,0,1,1860.291,393.612Zm0-2.529a.843.843,0,1,0,.843.843A.843.843,0,0,0,1860.291,391.083Zm2.529,5.48a2.531,2.531,0,0,0-2.529-2.529.422.422,0,1,0,0,.843,1.686,1.686,0,0,1,1.686,1.686.421.421,0,1,0,.843,0Zm-7.588-2.951a1.686,1.686,0,1,1,1.686-1.686A1.686,1.686,0,0,1,1855.232,393.612Zm0-2.529a.843.843,0,1,0,.843.843A.843.843,0,0,0,1855.232,391.083Zm-1.687,5.48a1.686,1.686,0,0,1,1.687-1.686.422.422,0,0,0,0-.843,2.531,2.531,0,0,0-2.53,2.529.422.422,0,1,0,.843,0Z"
        transform="translate(-1852.702 -390.24)"
        fill="#cc7729"
      />
    </svg>
  );
};

export default Icon;
