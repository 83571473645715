import React, { useState, useEffect, useMemo, useCallback } from "react";
import _ from "lodash";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SlideFade,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { api } from "lib";
import { useApiGet, useCustomToast, useDocumentTitle, usePermissioned } from "hooks";
import { Breadcrumb, DocumentHistory, Portal } from "components";
import * as yup from "yup";
import { messages } from "consts";
import { PermissionedContainer } from "components";
import { Content } from "pages/Private/Container";
import General from "./general";
import Notes from "./notes";
import MeetingsNotes from "./meetingsNotes";
import MeetingsTechnicalNotes from "./meetingsTechnicalNotes";
import Files from "./files";
import CustomersDetailsContext from "./context";
import Preferences from "./preferences";
import Consultations from "./consultations";
import { MdChevronLeft, MdHistory, MdMoreHoriz } from "react-icons/md";
import { TbAlertCircle, TbExternalLink } from "react-icons/tb";
import Tasks from "./tasks";
import Logo from "./logo";

const setupFormData = (data) => {
  const formData = data ?? {
    status: "prospect",
    phone1Category: "cellphone",
    phone2Category: "cellphone",
    phone3Category: "cellphone",
  };
  for (const path of ["prefs.monthlyPayments", "prefs.consultations", "prefs.travelCosts"])
    if (_.has(formData, path) === false) _.set(formData, path, {});
  if (_.size(formData.contacts) === 0) formData.contacts = [{ phoneCategory: "cellphone" }];
  if (_.size(formData.socialNetworks) === 0) formData.socialNetworks = [{}];
  if (_.size(formData.frequentMeetings) === 0) formData.frequentMeetings = [{}];
  return formData;
};

export const CustomersDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar cliente" : "Novo cliente");
  const navigate = useNavigate();
  const location = useLocation();
  const [data, isLoadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/customers/${_id}` }), [_id]));
  const [counters, setCounters] = useState({});
  const [isLoadingCounters, setIsLoadingCounters] = useState({});
  const [formData, setFormData] = useState(setupFormData());
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const isActive = useMemo(() => formData.status === "active", [formData.status]);
  const isMinutesUsageAlerted = useMemo(() => {
    const nextAlertAt = moment(formData.prefs?.consultations?.minutesUsageNextAlertAt).startOf("day").toDate();
    const today = moment().startOf("day").toDate();
    return formData.prefs?.consultations?.isMinutesUsageAlerted && nextAlertAt <= today;
  }, [formData.prefs?.consultations?.isMinutesUsageAlerted, formData.prefs?.consultations?.minutesUsageNextAlertAt]);
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const toast = useCustomToast();
  const isAllowed = {
    preferencesRead: usePermissioned("customers:preferences:read"),
    filesRead: usePermissioned("customers:files:read"),
    tasksRead: usePermissioned("customers:tasks:read"),
    notesRead: usePermissioned("customers:notes:read"),
    meetingsNotesRead: usePermissioned("customers:meetingsNotes:read"),
    meetingsTechnicalNotesRead: usePermissioned("customers:meetingsTechnicalNotes:read"),
    consultationsRead: usePermissioned("customers:consultations:read"),
  };

  useEffect(() => {
    const formData = setupFormData(data);
    setFormData(formData);
  }, [data]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.patch(`/customers/${_id}`, data) : await api.put("/customers", data);
        navigate(`/customers/edit/${saved._id}`, { replace: true });
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        if (error.isHandled) return;
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const data = {
        ...formData,
        segment: formData.segment?._id,
        contacts: _.filter(formData.contacts, ({ phoneCategory, ...rest }) => _.isEmpty(rest) === false),
        socialNetworks: _.filter(formData.socialNetworks, (value) => _.isEmpty(value) === false),
        frequentMeetings: _.filter(formData.frequentMeetings, (value) => _.isEmpty(value) === false).map((o) => ({
          ...o,
          meetingType: o.meetingType?._id,
          frequency: o.frequency,
        })),
        prefs: {
          ...formData.prefs,
          monthlyPayments: {
            ...formData.prefs.monthlyPayments,
            chartOfAccount: formData.prefs.monthlyPayments?.chartOfAccount?._id,
            costCenter: formData.prefs.monthlyPayments?.costCenter?._id,
          },
          travelCosts: {
            ...formData.prefs.travelCosts,
            chartOfAccount: formData.prefs.travelCosts?.chartOfAccount?._id,
            costCenter: formData.prefs.travelCosts?.costCenter?._id,
          },
        },
      };
      let shape = {
        status: yup.string().required(messages.error.required),
        tradingName: yup.string().required(messages.error.required),
        phone1: yup.string().required(messages.error.required),
        contacts: yup.array().of(
          yup.object().shape({
            name: yup.string().required(messages.error.required),
            office: yup.string().required(messages.error.required),
            email: yup.string().email(messages.error.invalidEmail).required(messages.error.required),
          })
        ),
        frequentMeetings: yup.array().of(
          yup.object().shape({
            meetingType: yup.string().required(messages.error.required),
            frequency: yup.string().required(messages.error.required),
          })
        ),
      };
      if (isActive)
        shape = _.merge(shape, {
          companyName: yup.string().required(messages.error.required),
          email: yup.string().email(messages.error.invalidEmail).required(messages.error.required),
          document: yup.string().required(messages.error.required),
          prefs: yup.object().shape({
            monthlyPayments: yup.object().shape({
              amount: yup.number().min(0, messages.error.moreThan.concat(" R$0,00.")).required(messages.error.required),
              dueDay: yup
                .number()
                .min(0, messages.error.moreThan.concat(" 0."))
                .max(28, messages.error.lessThan.concat(" 28."))
                .required(messages.error.required),
              paymentMethod: yup.string().required(messages.error.required),
              chartOfAccount: yup.string().required(messages.error.required),
              costCenter: yup.string().required(messages.error.required),
            }),
            consultations: yup.object().shape({
              hourPrice: yup.number().min(0, messages.error.moreThan.concat(" R$0,00.")).required(messages.error.required),
              minutesUsageAvailableMonthly: yup
                .number()
                .min(0, messages.error.moreThan.concat(" R$0,00."))
                .required(messages.error.required),
            }),
            travelCosts: yup.object().shape({
              paymentMethod: yup.string().required(messages.error.required),
              chartOfAccount: yup.string().required(messages.error.required),
              costCenter: yup.string().required(messages.error.required),
            }),
          }),
        });
      const schema = yup.object().shape(shape);
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [formData, isActive, handleSaveData]);

  return (
    <CustomersDetailsContext.Provider
      value={{
        formData,
        formErrors,
        setFormData,
        setCounters,
        setIsLoadingCounters,
        isActive,
        refreshData,
        isMinutesUsageAlerted,
      }}
    >
      <Content>
        <HStack justify="space-between">
          <HStack spacing={{ base: "10px", lg: "20px" }}>
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "cadastros" },
                { to: "/customers", label: "clientes" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          {_id && (
            <HStack>
              <Box>
                <Menu>
                  <MenuButton as={Button} size="sm" variant="outline" rightIcon={<Icon as={MdMoreHoriz} />}>
                    mais ações
                  </MenuButton>
                  <Portal>
                    <MenuList fontSize="sm">
                      <MenuItem as={HStack} cursor="pointer" onClick={() => navigate("/meetings/list", { state: { customer: formData } })}>
                        <Icon as={TbExternalLink} />
                        <Text>abrir reuniões do cliente</Text>
                      </MenuItem>
                      <MenuItem as={HStack} cursor="pointer" onClick={() => navigate("/solicitations", { state: { customer: formData } })}>
                        <Icon as={TbExternalLink} />
                        <Text>abrir solicitações do cliente</Text>
                      </MenuItem>
                      <MenuItem as={HStack} cursor="pointer" onClick={() => navigate("/tasks/list", { state: { customer: formData } })}>
                        <Icon as={TbExternalLink} />
                        <Text>abrir tarefas do cliente</Text>
                      </MenuItem>
                      <MenuItem
                        as={HStack}
                        cursor="pointer"
                        onClick={() => navigate("/customer-indicators", { state: { customer: formData } })}
                      >
                        <Icon as={TbExternalLink} />
                        <Text>abrir indicadores do cliente</Text>
                      </MenuItem>
                    </MenuList>
                  </Portal>
                </Menu>
              </Box>

              <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />
            </HStack>
          )}
        </HStack>

        <HStack my="15px" spacing={4} justify="space-between">
          <Logo />
          <Box flex="1">
            <HStack>
              <Heading size="md">Cliente</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.tradingName : "Novo cadastro"}</Text>
          </Box>
        </HStack>
        <Tabs index={activeIndex} onChange={(index) => setActiveIndex(index)} colorScheme="main">
          <TabList overflowX="auto" overflowY="hidden" whiteSpace="nowrap">
            <Tab>dados gerais</Tab>
            {isAllowed.preferencesRead && (
              <Tab>
                <HStack>
                  <Text>preferências</Text>
                  {isMinutesUsageAlerted && (
                    <Center bg="red.500" w="24px" h="24px" borderRadius="full">
                      <Icon as={TbAlertCircle} color="white" />
                    </Center>
                  )}
                </HStack>
              </Tab>
            )}
            {isAllowed.filesRead && (
              <Tab>
                <HStack>
                  <Text>arquivos</Text>
                  {isLoadingCounters.files ? (
                    <Spinner size="xs" />
                  ) : (
                    <Text fontSize="sm" fontWeight="semibold">
                      ({counters.files ?? 0})
                    </Text>
                  )}
                </HStack>
              </Tab>
            )}
            {isAllowed.tasksRead && (
              <Tab>
                <HStack>
                  <Text>tarefas recentes</Text>
                  {isLoadingCounters.tasks ? (
                    <Spinner size="xs" />
                  ) : (
                    <Text fontSize="sm" fontWeight="semibold">
                      ({counters.tasks ?? 0})
                    </Text>
                  )}
                </HStack>
              </Tab>
            )}
            {isAllowed.notesRead && (
              <Tab>
                <HStack>
                  <Text>notas do cliente</Text>
                  {isLoadingCounters.notes ? (
                    <Spinner size="xs" />
                  ) : (
                    <Text fontSize="sm" fontWeight="semibold">
                      ({counters.notes ?? 0})
                    </Text>
                  )}
                </HStack>
              </Tab>
            )}
            {isAllowed.meetingsNotesRead && (
              <Tab>
                <HStack>
                  <Text>notas de reunião</Text>
                  {isLoadingCounters.meetingsNotes ? (
                    <Spinner size="xs" />
                  ) : (
                    <Text fontSize="sm" fontWeight="semibold">
                      ({counters.meetingsNotes ?? 0})
                    </Text>
                  )}
                </HStack>
              </Tab>
            )}
            {isAllowed.meetingsTechnicalNotesRead && (
              <Tab>
                <HStack>
                  <Text>notas técnicas</Text>
                  {isLoadingCounters.meetingsTechnicalNotes ? (
                    <Spinner size="xs" />
                  ) : (
                    <Text fontSize="sm" fontWeight="semibold">
                      ({counters.meetingsTechnicalNotes ?? 0})
                    </Text>
                  )}
                </HStack>
              </Tab>
            )}
            {isAllowed.consultationsRead && (
              <Tab>
                <HStack>
                  <Text>consultorias</Text>
                  {isLoadingCounters.consultations ? (
                    <Spinner size="xs" />
                  ) : (
                    <Text fontSize="sm" fontWeight="semibold">
                      ({counters.consultations ?? 0})
                    </Text>
                  )}
                </HStack>
              </Tab>
            )}
          </TabList>
          <TabPanels>
            <TabPanel px="0" py="30px">
              <General />
            </TabPanel>
            {isAllowed.preferencesRead && (
              <TabPanel px="0" py="30px">
                <Preferences />
              </TabPanel>
            )}
            {isAllowed.filesRead && (
              <TabPanel px="0" py="30px">
                <Files />
              </TabPanel>
            )}
            {isAllowed.tasksRead && (
              <TabPanel px="0" py="30px">
                <Tasks />
              </TabPanel>
            )}
            {isAllowed.notesRead && (
              <TabPanel px="0" py="30px">
                <Notes />
              </TabPanel>
            )}
            {isAllowed.meetingsNotesRead && (
              <TabPanel px="0" py="30px">
                <MeetingsNotes />
              </TabPanel>
            )}
            {isAllowed.meetingsTechnicalNotesRead && (
              <TabPanel px="0" py="30px">
                <MeetingsTechnicalNotes />
              </TabPanel>
            )}
            {isAllowed.consultationsRead && (
              <TabPanel px="0" py="30px">
                <Consultations />
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </Content>

      <PermissionedContainer required={"customers:".concat(_id ? "update" : "create")}>
        <Divider />
        <SlideFade in={activeIndex <= 1} hidden={activeIndex > 1} offsetY="20px">
          <HStack p="20px">
            <Button size="sm" colorScheme="main" isLoading={isLoadingData || isLoadingSaveData} onClick={handleSubmit}>
              salvar
            </Button>
            <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
              voltar
            </Button>
          </HStack>
        </SlideFade>
        <SlideFade in={activeIndex > 1} hidden={activeIndex <= 1} offsetY="20px">
          <HStack p="20px">
            <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
              voltar
            </Button>
          </HStack>
        </SlideFade>
      </PermissionedContainer>

      {_id && <DocumentHistory path={`/customers/${_id}/history`} isOpen={isOpenDocumentHistory} onClose={onCloseDocumentHistory} />}
    </CustomersDetailsContext.Provider>
  );
};
