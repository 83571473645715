import { useEffect, useState } from "react";
import { SingleDatepicker, RangeDatepicker } from "chakra-dayzed-datepicker";
import moment from "moment";

const dateFormat = "dd/MM/yyyy";
const dayNames = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
const monthNames = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

const _propsConfigs = {
  dateNavBtnProps: {
    colorScheme: "gray",
    variant: "ghost",
  },
  dayOfMonthBtnProps: {
    defaultBtnProps: {
      borderColor: "red.300",
      fontWeight: "normal",
      _hover: {
        background: "gray.200",
      },
    },
    isInRangeBtnProps: {
      background: "main.300",
      color: "white",
    },
    selectedBtnProps: {
      background: "main.500",
      color: "white",
    },
    todayBtnProps: {
      background: "blue.500",
      color: "white",
    },
  },
  popoverCompProps: {
    popoverBodyProps: {
      padding: "0",
      borderWidth: "0",
    },
  },
};

export const SingleDatePicker = ({ propsConfigs, ...props }) => {
  return (
    <SingleDatepicker
      configs={{
        dateFormat,
        dayNames,
        monthNames,
      }}
      propsConfigs={{ ..._propsConfigs, ...propsConfigs }}
      {...props}
    />
  );
};

export const RangeDatePicker = ({ selectedDates, onDateChange, propsConfigs, ...props }) => {
  const [_selectedDates, _setSelectedDates] = useState(selectedDates);

  useEffect(() => {
    const [startedDate, endDate] = _selectedDates;
    onDateChange([
      startedDate ? moment(startedDate).startOf("day").toDate() : undefined,
      endDate ? moment(endDate).endOf("day").toDate() : undefined,
    ]);
  }, [_selectedDates]);

  return (
    <RangeDatepicker
      configs={{
        dateFormat,
        dayNames,
        monthNames,
      }}
      propsConfigs={{ ..._propsConfigs, ...propsConfigs }}
      selectedDates={_selectedDates}
      onDateChange={_setSelectedDates}
      {...props}
    />
  );
};
