import React, { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Heading, HStack, Box, Highlight, Text, VStack, Button, LightMode, Icon } from "@chakra-ui/react";
import { useDocumentTitle } from "hooks";
import { PrivateContext } from "pages/Private";
import { MdChevronRight } from "react-icons/md";
import Img from "assets/imgs/home.jpg";

export const Home = () => {
  useDocumentTitle("Página inicial");
  const { currentUser } = useContext(PrivateContext);

  return (
    <Box overflowY="auto" h="100%" p="20px">
      <Box position="relative" w="100%" h="100%" overflow="hidden" borderRadius="lg" role="group">
        <Box
          position="absolute"
          top="0"
          left="0"
          w="100%"
          h="100%"
          bgImg={Img}
          bgSize="cover"
          transition="2000ms"
          _groupHover={{ transform: "scale(1.2)" }}
        />
        <VStack
          alignItems="flex-start"
          justifyContent="center"
          spacing="0"
          p={{ base: "30px", lg: "60px" }}
          w="100%"
          h="100%"
          minH="400px"
          bg="blackAlpha.600"
          color="white"
          position="relative"
          zIndex="1"
        >
          <Heading size="md">Olá, {currentUser.name}.</Heading>
          <Heading lineHeight="tall">
            <Highlight query="bem-vindo(a)" styles={{ px: "2", py: "1", rounded: "xl", bg: "main.500", color: "white" }}>
              Seja bem-vindo(a)
            </Highlight>
          </Heading>
          <Text fontWeight="semibold" maxW="2xl">
            O novo painel de administração foi desenvolvido para lhe ajudar a gerenciar suas demandas. Fique à vontade e bom trabalho!
          </Text>
          <Box h="20px" />
          <LightMode>
            <HStack>
              <Button as={RouterLink} to="/meetings/grid" colorScheme="whiteAlpha" rightIcon={<Icon as={MdChevronRight} />}>
                minha agenda
              </Button>
            </HStack>
          </LightMode>
        </VStack>
      </Box>
    </Box>
  );
};
