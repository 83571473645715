import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { useApiGet, useCustomToast } from "hooks";
import { SyncSelect } from "components";
import { api, yup } from "lib";
import { messages } from "consts";
import { MdClose, MdEdit, MdErrorOutline, MdRefresh, MdSave } from "react-icons/md";
import { TbExternalLink, TbStatusChange } from "react-icons/tb";
import MeetingsDetailsContext from "../context";

const ConflictingMeetingRoom = () => {
  const { formData: meeting, refreshSchedules } = useContext(MeetingsDetailsContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, isLoadingData, refreshData] = useApiGet(
    useMemo(() => {
      const query = {
        status: { $nin: ["canceled_by_customer", "canceled_by_consultant", "canceled_by_scheduling"] },
        startDate: {
          $gte: ["@ISODate", moment(meeting.startDate).startOf("day").toDate()],
          $lte: ["@ISODate", moment(meeting.startDate).endOf("day").toDate()],
        },
        placement: { $ne: "external" },
      };
      return {
        path: "/meetings",
        params: { query, perPage: -1, sort: { startDate: 1 } },
        options: { isEnabled: isOpen && meeting.startDate && meeting.endDate },
      };
    }, [meeting.startDate, meeting.endDate, isOpen])
  );
  const [_meetingRooms, isLoadingMeetingRooms, refreshMeetingRooms] = useApiGet(
    useMemo(
      () => ({
        path: "/meeting-rooms",
        params: { isAutocomplete: true },
        options: { isEnabled: isOpen },
      }),
      [isOpen]
    )
  );
  const [formData, setFormData] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const menuPortalTarget = useMemo(() => {
    const [element] = document.getElementsByClassName("dialog-base");
    return element;
  }, []);
  const toast = useCustomToast();
  const meetingRooms = useMemo(() => {
    const blockedMeetingRooms = _.filter(formData, (o) => o.isBlocked).map((o) => o.mainMeetingRoom?._id);
    return _.filter(_meetingRooms?.data, (item) => blockedMeetingRooms.indexOf(item._id) === -1);
  }, [formData, _meetingRooms?.data]);
  const isChanged = useMemo(() => _.filter(formData, (o) => o.isChanged).length >= 1, [formData]);

  useEffect(() => {
    const formData = _.filter(data?.data, (item) => {
      const meetingScheduleRange = moment.range([moment(item.startDate), moment(item.endDate)]);
      const currentScheduleRange = moment.range([moment(meeting.startDate), moment(meeting.endDate)]);
      return currentScheduleRange.overlaps(meetingScheduleRange);
    }).map((item) => ({
      ...item,
      isBlocked: _.includes(["started", "finished"], item.status),
    }));
    setFormData(formData);
    setFormErrors({});
  }, [meeting.startDate, meeting.endDate, data]);

  const handleChangeMainMeetingRoom = useCallback(({ _id }, mainMeetingRoom) => {
    setFormData((state) => {
      const meetings = [...state];
      const changeMeetingRoom = (meeting, meetingRoom) => {
        meeting.mainMeetingRoom = meetingRoom;
        for (const participant of meeting.participants) participant.meetingRoom = meetingRoom;
        meeting.isChanged = true;
      };
      for (const meeting of meetings) {
        if (meeting._id === _id) changeMeetingRoom(meeting, mainMeetingRoom);
        else if (meeting.mainMeetingRoom?._id === mainMeetingRoom._id) changeMeetingRoom(meeting, null);
      }
      return meetings;
    });
  }, []);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        await api.patch("/meetings", data);
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        onClose();
        refreshSchedules();
      } catch (error) {
        if (error.isHandled) return;
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [toast, refreshSchedules]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.array().of(
        yup.object().shape({
          mainMeetingRoom: yup.string().required(messages.error.required),
        })
      );
      const data = _(formData)
        .filter((item) => item.isChanged)
        .map((item) => ({
          _id: item._id,
          mainMeetingRoom: item.mainMeetingRoom?._id,
          participants: _.map(item.participants, (participant) => ({
            ...participant,
            user: participant.user._id,
            meetingRoom: participant.meetingRoom?._id,
          })),
        }))
        .value();
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <>
      <Button size="sm" w="100%" leftIcon={<Icon as={MdEdit} />} onClick={onOpen}>
        alterar salas
      </Button>
      <Modal size="6xl" isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader as={HStack}>
            <Box flex="1">
              <Text fontSize="md">Reuniões no mesmo horário</Text>
              <Text fontSize="xs" fontWeight="normal">
                As reuniões abaixo estão agendadas na mesma janela de horário.
              </Text>
            </Box>
            <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingData} onClick={refreshData} />
            <IconButton size="sm" variant="outline" icon={<Icon as={MdClose} />} onClick={onClose} />
          </ModalHeader>
          <Divider />
          <ModalBody>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>NID</Th>
                  <Th>Cliente</Th>
                  <Th>Tipo</Th>
                  <Th>Horário</Th>
                  <Th>Sala principal</Th>
                  <Th>#</Th>
                </Tr>
              </Thead>
              <Tbody>
                {_.map(formData, (item, index) => (
                  <Tr>
                    <Td>{item.nid}</Td>
                    <Td>{item.customer.tradingName}</Td>
                    <Td>{item.meetingType.title}</Td>
                    <Td>{[moment(item.startDate).format("DD/MM/YYYY [de] HH:mm"), moment(item.endDate).format("HH:mm")].join(" às ")}</Td>
                    <Td>
                      <FormControl isRequired={true} isInvalid={formErrors[index]?.mainMeetingRoom}>
                        <HStack>
                          <SyncSelect
                            menuPortalTarget={menuPortalTarget}
                            menuPosition="fixed"
                            size="sm"
                            variant="filled"
                            value={item.mainMeetingRoom}
                            placeholder="Selecione"
                            options={meetingRooms || []}
                            onChange={(meetingRoom) => handleChangeMainMeetingRoom(item, meetingRoom)}
                            getOptionValue={({ _id }) => _id}
                            formatOptionLabel={({ title }) => title}
                            isDisabled={item.isBlocked}
                          />
                          <IconButton
                            size="sm"
                            icon={<Icon as={MdRefresh} />}
                            isLoading={isLoadingMeetingRooms}
                            onClick={refreshMeetingRooms}
                          />
                          {item.isChanged && (
                            <Tooltip label="Sala alterada">
                              <IconButton size="sm" colorScheme="blue" icon={<Icon as={TbStatusChange} />} />
                            </Tooltip>
                          )}
                          {item.isBlocked && (
                            <Tooltip label="Reuniões iniciadas/finalizadas não podem receber alterações.">
                              <IconButton size="sm" colorScheme="purple" icon={<Icon as={MdErrorOutline} />} />
                            </Tooltip>
                          )}
                        </HStack>

                        <FormErrorMessage>{formErrors[index]?.mainMeetingRoom}</FormErrorMessage>
                      </FormControl>
                    </Td>
                    <Td>
                      <IconButton
                        as={RouterLink}
                        to={`/meetings/edit/${item._id}`}
                        target="_blank"
                        size="sm"
                        variant="outline"
                        icon={<Icon as={TbExternalLink} />}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            {isLoadingData && (
              <Center p="40px">
                <Spinner />
              </Center>
            )}
          </ModalBody>
          <Divider />
          <ModalFooter as={HStack} justifyContent="flex-end">
            <Button size="sm" variant="outline" onClick={onClose}>
              cancelar
            </Button>
            <Button
              size="sm"
              colorScheme="green"
              leftIcon={<Icon as={MdSave} />}
              isLoading={isLoadingSaveData}
              onClick={handleSubmit}
              isDisabled={isChanged === false}
            >
              salvar alterações
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConflictingMeetingRoom;
