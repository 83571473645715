import React, { useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import ReactJson from "react-json-view";
import {
  Box,
  Button,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Icon,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useApiGet } from "hooks";
import { Paginator } from "components";
import { MdClose, MdHistory, MdRefresh } from "react-icons/md";

const actionTypes = {
  create: "Criação",
  update: "Alteração",
  delete: "Exclusão",
  restore: "Restauração",
};

export const DocumentHistory = ({ path, isOpen, onClose }) => {
  const [page, setPage] = useState(0);
  const [perPage] = useState(50);
  const [changes, setChanges] = useState();
  const [data, isLoadingData, refreshData] = useApiGet(
    useMemo(
      () => ({
        path,
        params: { page, perPage, sort: { createdAt: -1 } },
        options: { isEnabled: isOpen },
      }),
      [path, isOpen, page, perPage]
    )
  );

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader as={HStack}>
            <Text flex="1" fontSize="md">
              Histórico de alterações
            </Text>
            <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingData} onClick={refreshData} />
            <IconButton size="sm" variant="outline" icon={<Icon as={MdClose} />} onClick={onClose} />
          </DrawerHeader>
          <Divider />
          <DrawerBody>
            {isLoadingData ? (
              <Center p="40px">
                <Spinner />
              </Center>
            ) : (
              _.size(data?.data) === 0 && (
                <Center paddingTop="40px" paddingBottom="20px">
                  <Box textAlign="center">
                    <Icon as={MdHistory} boxSize={20} marginBottom="10px" />
                    <Text fontSize="lg" fontWeight="bold">
                      Nenhum histórico disponível
                    </Text>
                    <Text fontSize="sm">Ainda não existem alterações disponíveis para exibição neste documento.</Text>
                  </Box>
                </Center>
              )
            )}
            <VStack align="stretch" divider={<StackDivider />}>
              {_.map(data?.data, (item) => (
                <HStack key={item._id} py="10px">
                  <IconButton variant="solid" icon={<Icon as={MdHistory} />} isRound onClick={() => setChanges(item.changes)} />
                  <Box flex="1">
                    <Text display="inline-block" fontSize="xs">
                      {actionTypes[item.actionType]} realizada em <strong>{moment(item.createdAt).format("DD/MM/YYYY")}</strong> às{" "}
                      <strong>{moment(item.createdAt).format("HH:mm")}</strong> por{" "}
                      <Link as={RouterLink} to={`/users/edit/${item.createdBy._id}`} target="_blank">
                        <strong>{item.createdBy.name}</strong>
                      </Link>
                    </Text>
                  </Box>
                </HStack>
              ))}
            </VStack>
          </DrawerBody>
          <Divider />
          <DrawerFooter>
            <Paginator
              loading={isLoadingData}
              page={page}
              size={data?.size}
              perPage={perPage}
              onPaginate={setPage}
              isResultSizeVisible={false}
            />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <ViewChanges changes={changes} onClose={() => setChanges()} />
    </>
  );
};

const ViewChanges = ({ changes, onClose }) => {
  return (
    <Modal size="2xl" isOpen={_.isObject(changes)} onClose={onClose} isCentered scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader as={HStack} justifyContent="space-between">
          <Text>Alterações</Text>
          <IconButton size="sm" variant="outline" icon={<Icon as={MdClose} />} onClick={onClose} />
        </ModalHeader>
        <Divider />
        <ModalBody>{_.isObject(changes) && <ReactJson src={changes} name={false} />}</ModalBody>
        <Divider />
        <ModalFooter as={HStack}>
          <Button size="sm" variant="outline" onClick={onClose}>
            fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
