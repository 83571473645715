import React, { useContext, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Td,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  Center,
  Spinner,
  HStack,
  Text,
  IconButton,
  Icon,
  Badge,
  Link,
  Tooltip,
} from "@chakra-ui/react";
import { percent, translator } from "lib";
import SearchInput from "../components/SearchInput";
import { SchedulingContext } from "./index";
import { MdOutlineVisibility, MdQuestionAnswer } from "react-icons/md";
import SortButton from "../components/SortButton";
import { useSearch } from "hooks";
import { BsQuestionCircle } from "react-icons/bs";
import { CustomBadge } from "components";

const ByCustomer = () => {
  const { scheduling, isLoadingScheduling } = useContext(SchedulingContext);
  const [searchText, setSearchText] = useState("");
  const data = useMemo(() => {
    return _.map(scheduling, (item) => {
      if (item.lastMeeting?.startDate) item.lastMeeting.startDate = new Date(item.lastMeeting.startDate);
      if (item.lastFinishedMeeting?.startDate) item.lastFinishedMeeting.startDate = new Date(item.lastFinishedMeeting.startDate);
      return item;
    });
  }, [scheduling]);
  const [sort, setSort] = useState({ key: "nextDate.pastPercentage", direction: "desc" });
  const searched = useSearch(useMemo(() => ({ keys: ["customer.tradingName"], data, searchText }), [data, searchText]));
  const sorted = useMemo(() => _.orderBy(searched, [sort.key], [sort.direction]), [searched, sort]);

  return (
    <Box borderWidth="1px" borderRadius="lg" overflowX="auto">
      <SearchInput value={searchText} onSubmit={(value) => setSearchText(value)} />
      <Table size="sm" whiteSpace="nowrap">
        <Thead>
          <Tr>
            <Th textTransform="none" fontSize="xs" textAlign="center">
              <HStack justifyContent="center">
                <Text>Prioridade</Text>
                <SortButton keyName="priority" {...{ sort, setSort }} />
              </HStack>
            </Th>
            <Th textTransform="none" fontSize="xs" textAlign="center">
              <HStack justifyContent="center">
                <Tooltip label="Tempo decorrido desde a última reunião agendada ou finalizada.">
                  <IconButton size="xs" icon={<Icon as={BsQuestionCircle} />} />
                </Tooltip>
                <Text>Tempo decorrido</Text>
                <SortButton keyName="nextDate.pastPercentage" {...{ sort, setSort }} />
              </HStack>
            </Th>
            <Th textTransform="none" fontSize="xs">
              <HStack>
                <Text>Cliente</Text>
                <SortButton keyName="customer.tradingName" {...{ sort, setSort }} />
              </HStack>
            </Th>
            <Th textTransform="none" fontSize="xs" textAlign="center">
              <HStack>
                <Text>Tipo de reunião</Text>
                <SortButton keyName="frequentMeeting.meetingType.title" {...{ sort, setSort }} />
              </HStack>
            </Th>
            <Th textTransform="none" fontSize="xs" textAlign="center">
              <HStack justifyContent="center">
                <Text>Frequência</Text>
                <SortButton keyName="frequentMeeting.frequency" {...{ sort, setSort }} />
              </HStack>
            </Th>
            <Th textTransform="none" fontSize="xs" textAlign="center">
              <HStack justifyContent="center">
                <Text>Últ. reunião fin.</Text>
                <SortButton keyName="lastFinishedMeeting.startDate" {...{ sort, setSort }} />
              </HStack>
            </Th>
            <Th textTransform="none" fontSize="xs" textAlign="center">
              <HStack justifyContent="center">
                <Text>Últ. reunião prev.</Text>
                <SortButton keyName="lastUnscheduledMeeting.startDate" {...{ sort, setSort }} />
              </HStack>
            </Th>
            <Th textTransform="none" fontSize="xs" textAlign="center">
              <HStack justifyContent="center">
                <Tooltip label="Última reunião confirmada para o cliente dentro do período, seja finalizada ou agendada.">
                  <IconButton size="xs" icon={<Icon as={BsQuestionCircle} />} />
                </Tooltip>
                <Text>Últ. reunião</Text>
                <SortButton keyName="lastMeeting.startDate" {...{ sort, setSort }} />
              </HStack>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {_.map(sorted, (item) => (
            <Tr key={item.frequentMeeting._id} _hover={{ _light: { bg: "gray.100" }, _dark: { bg: "gray.900" } }}>
              <Td fontSize="xs" textAlign="center">
                <CustomBadge.DashboardsScheduling.Priority priority={item.priority} />
              </Td>
              <Td fontSize="xs" textAlign="center">
                {item.nextDate ? percent.format(item.nextDate.pastPercentage) : "?"}
              </Td>
              <Td fontSize="xs">
                <Box maxW="200px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                  <Link as={RouterLink} to={`/customers/edit/${item.customer._id}`} target="_blank">
                    {item.customer.tradingName}
                  </Link>
                </Box>
              </Td>
              <Td fontSize="xs">{item.frequentMeeting.meetingType.title}</Td>
              <Td fontSize="xs" textAlign="center">
                {translator(item.frequentMeeting.frequency)}
              </Td>
              <Td fontSize="xs" textAlign="center">
                <HStack justifyContent="center">
                  <Text>
                    {item.lastFinishedMeeting?.startDate ? moment(item.lastFinishedMeeting?.startDate).format("DD/MM/YYYY") : "-"}
                  </Text>
                  {item.lastFinishedMeeting && (
                    <IconButton
                      as={RouterLink}
                      to={`/meetings/edit/${item.lastFinishedMeeting._id}`}
                      target="_blank"
                      size="xs"
                      variant="outline"
                      icon={<Icon as={MdOutlineVisibility} />}
                    />
                  )}
                </HStack>
              </Td>
              <Td fontSize="xs" textAlign="center">
                <HStack justifyContent="center">
                  <Text>
                    {item.lastUnscheduledMeeting?.startDate ? moment(item.lastUnscheduledMeeting?.startDate).format("DD/MM/YYYY") : "-"}
                  </Text>
                  {item.lastUnscheduledMeeting && (
                    <IconButton
                      as={RouterLink}
                      to={`/meetings/edit/${item.lastUnscheduledMeeting._id}`}
                      target="_blank"
                      size="xs"
                      variant="outline"
                      icon={<Icon as={MdOutlineVisibility} />}
                    />
                  )}
                </HStack>
              </Td>
              <Td fontSize="xs" textAlign="center">
                <HStack justifyContent="center">
                  <Text>{item.lastMeeting?.startDate ? moment(item.lastMeeting?.startDate).format("DD/MM/YYYY") : "-"}</Text>
                  {item.lastMeeting && (
                    <IconButton
                      as={RouterLink}
                      to={`/meetings/edit/${item.lastMeeting._id}`}
                      target="_blank"
                      size="xs"
                      variant="outline"
                      icon={<Icon as={MdOutlineVisibility} />}
                    />
                  )}
                </HStack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {isLoadingScheduling && (
        <Center p="40px">
          <Spinner />
        </Center>
      )}
    </Box>
  );
};

export default ByCustomer;
