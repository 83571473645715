import React, { Fragment, useCallback, useContext, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import _ from "lodash";
import ReactInputMask from "react-input-mask";
import {
  Box,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Select,
  Text,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import { AsyncSelect, BoxData, SyncSelect, PermissionedContainer, CustomerLogo } from "components";
import { api, masks, translator } from "lib";
import { usePermissioned } from "hooks";
import { MdCalendarToday, MdRefresh } from "react-icons/md";
import MeetingsDetailsContext from "../context";
import DateTime from "./datetime";
import Participants from "./participants";
import CustomerParticipants from "./customerParticipants";
import ConflictingMeetings from "./conflictingMeetings";

let loadCustomersTimeout, loadDemandsTimeout, loadMeetingTypesTimeout;

const General = () => {
  const { formData, setFormData, formErrors, meetingRooms, isLoadingMeetingRooms, refreshMeetingRooms, conflictingMeetings } =
    useContext(MeetingsDetailsContext);
  const isAllowedUpdateDemands = usePermissioned("demands:update");

  useEffect(() => {
    setFormData((state) => {
      const participants = _.map(state.participants, ({ meetingRoom, ...rest }) => ({
        ...rest,
        meetingRoom: meetingRoom ?? formData.mainMeetingRoom,
      }));
      return { ...state, participants };
    });
  }, [formData.mainMeetingRoom]);

  const handleLoadCustomers = useCallback((search, cb) => {
    clearTimeout(loadCustomersTimeout);
    loadCustomersTimeout = setTimeout(async () => {
      const params = {
        search,
        query: { status: { $ne: "inactive" } },
        sort: { tradingName: 1 },
        perPage: 20,
        isAutocomplete: true,
      };
      const response = await api.post("/customers", params);
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleLoadMeetingTypes = useCallback((search, cb) => {
    clearTimeout(loadMeetingTypesTimeout);
    loadMeetingTypesTimeout = setTimeout(async () => {
      const response = await api.post("/meeting-types", {
        search,
        query: { isActive: true },
        sort: { title: 1 },
        perPage: 20,
        isAutocomplete: true,
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleLoadDemands = useCallback((search, cb) => {
    clearTimeout(loadDemandsTimeout);
    loadDemandsTimeout = setTimeout(async () => {
      const response = await api.post("/demands", {
        search,
        query: { isActive: true },
        sort: { title: 1 },
        perPage: 20,
        isAutocomplete: true,
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleChangeMeetingType = useCallback((meetingType) => {
    setFormData((state) => {
      const frequentMeeting = _.find(state.customer?.frequentMeetings, (o) => o.meetingType === meetingType?._id);
      return {
        ...state,
        meetingType,
        participants: _.map(meetingType?.users, (user) => ({ user, meetingRoom: state.mainMeetingRoom })),
        customerParticipants: frequentMeeting?.participants || [],
        demands: meetingType?.demands ?? [],
        predictedDurationInMinutes: meetingType?.informedAvgDurationInMinutes ?? 0,
      };
    });
  }, []);

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 12, lg: 2 }}>
          {["unscheduled", "scheduled"].indexOf(formData.status) !== -1 ? (
            <FormControl isRequired={true} isInvalid={formErrors.status}>
              <FormLabel fontSize="sm">Status</FormLabel>
              <Select value={formData.status ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, status: target.value }))}>
                <option value="unscheduled">Previsto</option>
                <option value="scheduled">Agendado</option>
              </Select>
              <FormErrorMessage>{formErrors.status}</FormErrorMessage>
            </FormControl>
          ) : (
            <BoxData label="Status" value={translator(formData.status)} />
          )}
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 2 }}>
          <FormControl isRequired={true} isInvalid={formErrors.placement}>
            <FormLabel fontSize="sm">Local</FormLabel>
            <Select
              value={formData.placement ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, placement: target.value }))}
            >
              <option value="external">Externo</option>
              <option value="internal_remote">Interno remoto</option>
              <option value="internal_in_person">Interno presencial</option>
            </Select>
            <FormErrorMessage>{formErrors.placement}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 3 }}>
          <FormControl isRequired={true} isInvalid={formErrors.customer}>
            <HStack mb="0.5em" justifyContent="space-between">
              <FormLabel fontSize="sm" m="0">
                Cliente
              </FormLabel>
              {formData.customer ? (
                <PermissionedContainer required="customers:update">
                  <Link as={RouterLink} to={`/customers/edit/${formData.customer._id}`} target="_blank" fontSize="xs">
                    editar cadastro
                  </Link>
                </PermissionedContainer>
              ) : (
                <PermissionedContainer required="customers:create">
                  <Link as={RouterLink} to={`/customers/new`} target="_blank" color="blue.500" fontSize="xs">
                    incluir cadastro
                  </Link>
                </PermissionedContainer>
              )}
            </HStack>
            <AsyncSelect
              value={formData.customer}
              defaultOptions
              loadOptions={handleLoadCustomers}
              placeholder="Selecione"
              selectedOptionStyle="check"
              onChange={(customer) => setFormData((state) => ({ ...state, customer, address: null, customerParticipants: [] }))}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ logoUrl, tradingName, segment }) => (
                <HStack>
                  <CustomerLogo alt={tradingName} src={logoUrl} boxSize="35px" />
                  <Box flex="1">
                    <Text fontSize="sm" noOfLines={1}>
                      {tradingName}
                    </Text>
                    <Text fontSize="xs">{segment?.title || "-"}</Text>
                  </Box>
                </HStack>
              )}
              isClearable={true}
            />
            <FormErrorMessage>{formErrors.customer}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 5 }}>
          {formData.placement === "external" ? (
            <FormControl isRequired={true} isInvalid={formErrors.address}>
              <HStack mb="0.5em" justifyContent="space-between">
                <FormLabel fontSize="sm" m="0">
                  Endereço
                </FormLabel>
                {formData.address?.location?.lat && formData.address?.location?.lng && (
                  <Link
                    href={`https://maps.google.com/?q=${formData.address.location.lat},${formData.address.location.lng}`}
                    target="_blank"
                    color="blue.500"
                    fontSize="xs"
                  >
                    abrir no mapa
                  </Link>
                )}
              </HStack>
              <SyncSelect
                value={formData.address}
                options={formData.customer?.addresses}
                placeholder="Selecione"
                selectedOptionStyle="check"
                onChange={(address) => setFormData((state) => ({ ...state, address }))}
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ title, line1, line2 }) => (
                  <Box>
                    <Text fontSize="sm">{title}</Text>
                    <Text fontSize="xs">
                      {line1} - {line2}
                    </Text>
                  </Box>
                )}
                isClearable={true}
              />
              <FormErrorMessage>{formErrors.address}</FormErrorMessage>
            </FormControl>
          ) : (
            <FormControl isRequired={true} isInvalid={formErrors.mainMeetingRoom}>
              <HStack mb="0.5em" justifyContent="space-between">
                <FormLabel fontSize="sm" m="0">
                  Sala de reunião principal
                </FormLabel>
                {formData.mainMeetingRoom ? (
                  <PermissionedContainer required="meeting-rooms:update">
                    <Link
                      as={RouterLink}
                      to={`/settings/records/meeting-rooms/edit/${formData.mainMeetingRoom._id}`}
                      target="_blank"
                      fontSize="xs"
                    >
                      editar cadastro
                    </Link>
                  </PermissionedContainer>
                ) : (
                  <PermissionedContainer required="meeting-rooms:create">
                    <Link as={RouterLink} to={`/settings/records/meeting-rooms/new`} target="_blank" color="blue.500" fontSize="xs">
                      incluir cadastro
                    </Link>
                  </PermissionedContainer>
                )}
              </HStack>
              <HStack>
                <SyncSelect
                  value={formData.mainMeetingRoom}
                  options={meetingRooms?.data}
                  placeholder="Selecione"
                  selectedOptionStyle="check"
                  onChange={(mainMeetingRoom) => setFormData((state) => ({ ...state, mainMeetingRoom }))}
                  getOptionValue={({ _id }) => _id}
                  getOptionLabel={({ title }) => title}
                  isClearable={true}
                  formatOptionLabel={({ _id, title }) => (
                    <HStack w="100%">
                      <Text flex="1">{title}</Text>
                      <ConflictingMeetings path="meetingRooms" selected={_id} isButtonOnly={true} />
                    </HStack>
                  )}
                />
                <IconButton
                  variant="outline"
                  icon={<Icon as={MdRefresh} />}
                  isLoading={isLoadingMeetingRooms}
                  onClick={refreshMeetingRooms}
                />
                <ConflictingMeetings path="meetingRooms" selected={formData.mainMeetingRoom?._id} />
              </HStack>
              <FormErrorMessage>{formErrors.mainMeetingRoom}</FormErrorMessage>
            </FormControl>
          )}
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isInvalid={formErrors.meetingType}>
            <HStack mb="0.5em" justifyContent="space-between">
              <FormLabel fontSize="sm" m="0">
                Tipo de reunião
              </FormLabel>
              {formData.meetingType ? (
                <PermissionedContainer required="meeting-types:update">
                  <Link
                    as={RouterLink}
                    to={`/settings/records/meeting-types/edit/${formData.meetingType._id}`}
                    target="_blank"
                    fontSize="xs"
                  >
                    editar cadastro
                  </Link>
                </PermissionedContainer>
              ) : (
                <PermissionedContainer required="meeting-types:create">
                  <Link as={RouterLink} to={`/settings/records/meeting-types/new`} target="_blank" color="blue.500" fontSize="xs">
                    incluir cadastro
                  </Link>
                </PermissionedContainer>
              )}
            </HStack>
            <AsyncSelect
              defaultOptions
              value={formData.meetingType}
              loadOptions={handleLoadMeetingTypes}
              placeholder="Selecione"
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ title, informedAvgDurationInMinutes, calculatedAvgDurationInMinutes }) => (
                <Box>
                  <Text fontSize="sm">{title}</Text>
                  <Text fontSize="xs">
                    Infor. {informedAvgDurationInMinutes?.toLocaleString() ?? "-"} minutos | Calc.{" "}
                    {calculatedAvgDurationInMinutes?.toLocaleString() ?? "-"} minutos
                  </Text>
                </Box>
              )}
              onChange={handleChangeMeetingType}
              isClearable={true}
            />
            <FormErrorMessage>{formErrors.meetingType}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 8 }}>
          <FormControl isInvalid={formErrors.demands}>
            <HStack mb="0.5em" justifyContent="space-between">
              <FormLabel fontSize="sm" m="0">
                Demandas
              </FormLabel>
              <PermissionedContainer required="demands:create">
                <Link as={RouterLink} to={`/settings/records/demands/new`} target="_blank" color="blue.500" fontSize="xs">
                  incluir cadastro
                </Link>
              </PermissionedContainer>
            </HStack>
            <AsyncSelect
              isMulti
              defaultOptions
              placeholder="Selecione"
              value={formData.demands ?? []}
              loadOptions={handleLoadDemands}
              onChange={(demands) => setFormData((state) => ({ ...state, demands }))}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ title }) => title}
              components={{
                MultiValueLabel: ({ data: { _id, title } }) => (
                  <Link as={isAllowedUpdateDemands && RouterLink} to={`/settings/records/demands/edit/${_id}`} target="_blank">
                    {title}
                  </Link>
                ),
              }}
            />
            <FormErrorMessage>{formErrors.demands}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <Divider my={8} />

      <Participants />

      <Divider my={8} />

      <CustomerParticipants />

      <Divider my={8} />

      <DateTime />

      <Divider my={8} />

      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 12, lg: 12 }}>
          <FormControl isInvalid={formErrors.title}>
            <FormLabel fontSize="sm">Título</FormLabel>
            <Input value={formData.title ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))} />
            <FormErrorMessage>{formErrors.title}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 12 }}>
          <FormControl isInvalid={formErrors.description}>
            <FormLabel fontSize="sm">Descrição</FormLabel>
            <Textarea
              value={formData.description ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, description: target.value }))}
            />
            <FormErrorMessage>{formErrors.description}</FormErrorMessage>
          </FormControl>
        </GridItem>

        {["started", "finished", "canceled"].map((status) => {
          const keys = { by: status.concat("By"), at: status.concat("At") };
          return (
            formData[keys.by] && (
              <Fragment key={status}>
                <GridItem colSpan={{ base: 12, lg: 6 }}>
                  <BoxData label={`${translator(status)} por`} value={formData[keys.by]?.name ?? "-"} />
                </GridItem>
                <GridItem colSpan={{ base: 12, lg: 6 }}>
                  <FormControl isRequired={true} isInvalid={formErrors[keys.at]}>
                    <FormLabel fontSize="sm">{translator(status)} em</FormLabel>
                    <InputGroup>
                      <InputLeftElement>
                        <Icon as={MdCalendarToday} />
                      </InputLeftElement>
                      <Input
                        as={ReactInputMask}
                        mask="99/99/9999 99:99"
                        value={formData[keys.at] ?? ""}
                        onChange={({ target }) => setFormData((state) => ({ ...state, [keys.at]: target.value }))}
                      />
                    </InputGroup>
                    <FormErrorMessage>{formErrors[keys.at]}</FormErrorMessage>
                  </FormControl>
                </GridItem>
              </Fragment>
            )
          );
        })}

        {["finished", "canceled"].indexOf(formData.status) !== -1 && (
          <>
            <GridItem colSpan={{ base: 12, lg: 12 }}>
              <FormControl isRequired={true} isInvalid={formErrors.notes}>
                <FormLabel fontSize="sm">Notas</FormLabel>
                <Textarea
                  minH="200px"
                  value={formData.notes ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, notes: target.value }))}
                />
                <FormErrorMessage>{formErrors.notes}</FormErrorMessage>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 12 }}>
              <FormControl isRequired={true} isInvalid={formErrors.technicalNotes}>
                <FormLabel fontSize="sm">Notas técnicas</FormLabel>
                <Textarea
                  minH="200px"
                  value={formData.technicalNotes ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, technicalNotes: target.value }))}
                />
                <FormErrorMessage>{formErrors.technicalNotes}</FormErrorMessage>
              </FormControl>
            </GridItem>
          </>
        )}
      </Grid>
    </>
  );
};

export default General;
