import React, { useCallback, useContext, useEffect } from "react";
import { getAuth, signOut } from "firebase/auth";
import _ from "lodash";
import { Link as RouterLink } from "react-router-dom";
import {
  Avatar,
  Box,
  Divider,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  VStack,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  useToken,
} from "@chakra-ui/react";
import { Portal, Logo } from "components";
import { MdBrightness7, MdBrightness2, MdMenu, MdEdit, MdOutlineInfo } from "react-icons/md";
import { IoLogOutOutline, IoLockClosedOutline } from "react-icons/io5";
import { PrivateContext } from "pages/Private";
import { ContainerContext } from "./index";
import Notifications from "./notifications";
import AvatarChange from "./avatarChange";
import PasswordChange from "./passwordChange";
import { RiUserSettingsLine } from "react-icons/ri";

const Toolbar = () => {
  const { currentUser } = useContext(PrivateContext);
  const { isMobileView, onOpenSidebar } = useContext(ContainerContext);
  const { colorMode, toggleColorMode } = useColorMode();
  const iconButtonColor = useColorModeValue("gray.500", "white");
  const { isOpen: isOpenPasswordChange, onOpen: onOpenPasswordChange, onClose: onClosePasswordChange } = useDisclosure();
  const bg = useColorModeValue("gray.50", "gray.900");
  const [bgColor] = useToken("colors", [bg]);

  useEffect(() => {
    document.querySelector("meta[name='theme-color']").setAttribute("content", bgColor);
  }, [bgColor]);

  const handleSignOut = useCallback(() => {
    const auth = getAuth();
    signOut(auth);
  }, []);

  return (
    <>
      <HStack h="50px" px="20px" justifyContent="space-between" bg={bg}>
        <HStack>
          {isMobileView && <IconButton variant="ghost" isRound icon={<Icon as={MdMenu} />} onClick={onOpenSidebar} />}
          <Logo width={120} />
        </HStack>
        <HStack>
          <Notifications />
          <Tooltip label={`Tema ${colorMode === "light" ? "escuro" : "claro"}`}>
            <IconButton
              icon={<Icon as={colorMode === "light" ? MdBrightness7 : MdBrightness2} boxSize="20px" />}
              variant="ghost"
              color={iconButtonColor}
              onClick={toggleColorMode}
              isRound
            />
          </Tooltip>
          <Box>
            <Menu>
              <MenuButton as={IconButton} variant="ghost" isRound>
                <Avatar size="sm" name={currentUser.name} src={currentUser.avatarUrl} />
              </MenuButton>
              <Portal>
                <MenuList>
                  <VStack textAlign="center" p="10px" spacing="0">
                    <AvatarChange />
                    <Text fontWeight="bold" maxW="250px" noOfLines={1}>
                      {currentUser.name}
                    </Text>
                    {currentUser.email && <Text fontSize="xs">{currentUser.email}</Text>}
                    <Text fontSize="xs" opacity="0.6">
                      v{process.env.REACT_APP_VERSION}
                    </Text>
                  </VStack>
                  <Divider my={2} />
                  {/* <MenuItem as={RouterLink} to="/versions">
                    <Icon as={MdOutlineInfo} marginRight="10px" />
                    <Text fontSize="xs" flex="1">
                      Versões
                    </Text>
                    <Text fontSize="xs" opacity="0.6">
                      v{process.env.REACT_APP_VERSION}
                    </Text>
                  </MenuItem> */}
                  <MenuItem onClick={onOpenPasswordChange}>
                    <Icon as={IoLockClosedOutline} marginRight="10px" />
                    <Text fontSize="xs">Alterar senha</Text>
                  </MenuItem>
                  <MenuItem as={RouterLink} to="/users/preferences">
                    <Icon as={RiUserSettingsLine} marginRight="10px" />
                    <Text fontSize="xs">Preferências</Text>
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem onClick={handleSignOut}>
                    <Icon as={IoLogOutOutline} marginRight="10px" />
                    <Text fontSize="xs">Sair da minha conta</Text>
                  </MenuItem>
                </MenuList>
              </Portal>
            </Menu>
          </Box>
        </HStack>
      </HStack>
      <PasswordChange isOpen={isOpenPasswordChange} onClose={onClosePasswordChange} />
    </>
  );
};

export default Toolbar;
