import React, { createContext, useMemo, useState, useCallback } from "react";
import _ from "lodash";
import moment from "moment-business-days";
import { Box, Center, Grid, GridItem, Heading, HStack, Icon, IconButton, Text } from "@chakra-ui/react";
import { Breadcrumb } from "components";
import { useApiGet, useDocumentTitle, useMonthBusinessDays } from "hooks";
import { Content } from "pages/Private/Container";
import ByTeams from "./byTeams";
import { formatMinutes, percent } from "lib";
import { statuses } from "consts";
import { LuCalendarClock } from "react-icons/lu";
import ByStatus from "./byStatus";
import { FiCalendar } from "react-icons/fi";
import ByConsultant from "./byConsultant";
import ByConsultantAndCustomer from "./byConsultantAndCustomer";
import ObjectID from "bson-objectid";
import Filters from "./filters";
import { MdRefresh } from "react-icons/md";

moment.updateLocale("br", { workingWeekdays: [1, 2, 3, 4, 5] });

export const ActivitiesContext = createContext();

export const Activities = () => {
  useDocumentTitle("Painel de controle de atividades");
  const [query, setQuery] = useState();
  const [activities, isLoadingActivities, refreshActivities] = useApiGet(
    useMemo(
      () => ({
        path: "/dashboards/activities",
        params: { query },
        options: { isEnabled: _.isObject(query) },
      }),
      [query]
    )
  );
  const { businessDaysInMonth, pastBusinessDays, pastBusinessDaysPercentage, remainingBusinessDaysPercentage, totalMinutesInMonth } =
    useMonthBusinessDays(query?.startDate);

  const handleNavigate = useCallback(
    (moduleName, data) => {
      const hash = ObjectID().toHexString();
      const merged = _.merge(data, { isDelayed: query.isDelayed });
      switch (moduleName) {
        case "tasks":
          merged.dueDateStartDate = query.startDate;
          merged.dueDateEndDate = query.endDate;
          merged.demand = query.demands;
          merged.demandExcept = query.demandsExcept;
          break;
        case "meetings":
          merged.startDate = query.startDate;
          merged.endDate = query.endDate;
          merged.meetingType = query.meetingType;
          merged.demands = query.demands;
          merged.demandsExcept = query.demandsExcept;
          if (merged.responsible) {
            merged.users = _.assign([], merged.responsible);
            delete merged.responsible;
          }
          if (merged.status) {
            merged.status = _.flatMap(merged.status, (status) => {
              switch (status.value) {
                case "pending":
                  return statuses.meetings.find((o) => o.value === "scheduled");
                case "canceled":
                  return statuses.meetings.filter((o) => o.value.search("canceled") !== -1);
                default:
                  return status;
              }
            });
          }
          break;
      }
      localStorage.setItem("#".concat(hash), JSON.stringify(merged));
      window.open(`/${moduleName}/list#${hash}`);
    },
    [query]
  );

  return (
    <ActivitiesContext.Provider
      value={{
        query,
        activities,
        isLoadingActivities,
        refreshActivities,
        totalMinutesInMonth,
        remainingBusinessDaysPercentage,
        handleNavigate,
      }}
    >
      <Content>
        <HStack justify="space-between">
          <Breadcrumb items={[{ label: "painéis de controle" }, { to: "/dashboards/tasks", label: "atividades" }]} />
        </HStack>

        <HStack my="15px" justifyContent="space-between">
          <Heading size="md">Painel de controle de atividades</Heading>
          <IconButton
            size="sm"
            variant="outline"
            icon={<Icon as={MdRefresh} />}
            fontSize="sm"
            isLoading={isLoadingActivities}
            onClick={refreshActivities}
          />
        </HStack>

        <Filters onQuery={setQuery} onRefresh={refreshActivities} isLoading={isLoadingActivities} />

        <Grid templateColumns="repeat(12, 1fr)" gap={2}>
          <GridItem colSpan={{ base: 12, lg: 3 }} borderWidth="1px" borderRadius="lg" p="15px">
            <HStack>
              <Center w="40px" h="40px" borderRadius="full" _light={{ bg: "gray.100" }} _dark={{ bg: "gray.900" }}>
                <Icon as={FiCalendar} />
              </Center>
              <Box>
                <Text fontSize="xs" whiteSpace="nowrap">
                  Último dia do mês
                </Text>
                <Heading size="sm">{moment(query?.endDate).format("DD")}</Heading>
              </Box>
            </HStack>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 3 }} borderWidth="1px" borderRadius="lg" p="15px">
            <HStack>
              <Center w="40px" h="40px" borderRadius="full" _light={{ bg: "gray.100" }} _dark={{ bg: "gray.900" }}>
                <Icon as={FiCalendar} />
              </Center>
              <Box>
                <Text fontSize="xs" whiteSpace="nowrap">
                  Dias úteis no mês
                </Text>
                <Heading size="sm">{businessDaysInMonth}</Heading>
              </Box>
            </HStack>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 3 }} borderWidth="1px" borderRadius="lg" p="15px">
            <HStack>
              <Center w="40px" h="40px" borderRadius="full" _light={{ bg: "gray.100" }} _dark={{ bg: "gray.900" }}>
                <Icon as={FiCalendar} />
              </Center>
              <Box>
                <Text fontSize="xs" whiteSpace="nowrap">
                  Dias úteis corridos
                </Text>
                <Heading size="sm">
                  {pastBusinessDays} ({percent.format(pastBusinessDaysPercentage)})
                </Heading>
              </Box>
            </HStack>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 3 }} borderWidth="1px" borderRadius="lg" p="15px">
            <HStack>
              <Center w="40px" h="40px" borderRadius="full" _light={{ bg: "gray.100" }} _dark={{ bg: "gray.900" }}>
                <Icon as={LuCalendarClock} />
              </Center>
              <Box>
                <Text fontSize="xs" whiteSpace="nowrap">
                  Tempo total no mês
                </Text>
                <Heading size="sm">{formatMinutes(totalMinutesInMonth)}</Heading>
              </Box>
            </HStack>
          </GridItem>
          <GridItem colSpan={12}>
            <ByStatus />
          </GridItem>
          <GridItem colSpan={12}>
            <ByTeams />
          </GridItem>
          <GridItem colSpan={12}>
            <ByConsultant />
          </GridItem>
          <GridItem colSpan={12}>
            <ByConsultantAndCustomer />
          </GridItem>
        </Grid>
      </Content>
    </ActivitiesContext.Provider>
  );
};
