import React, { memo, useContext, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import _ from "lodash";
import {
  Box,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { MdErrorOutline } from "react-icons/md";
import MeetingsDetailsContext from "../context";
import { TbExternalLink } from "react-icons/tb";
import ConflictingMeetingRoom from "./conflictingMeetingRoom";

const paths = { meetingRooms: "esta sala.", participants: "este participante." };

const ConflictingMeetings = memo(({ path, selected, isButtonOnly = false }) => {
  const { conflictingMeetings } = useContext(MeetingsDetailsContext);
  const data = useMemo(() => conflictingMeetings?.[path]?.[selected] ?? [], [conflictingMeetings, path, selected]);

  if (data.length === 0) return;
  if (isButtonOnly)
    return (
      <Tooltip label="Existem reuniões conflitantes.">
        <IconButton size="sm" colorScheme="red" variant="ghost" icon={<Icon as={MdErrorOutline} boxSize="20px" />} />
      </Tooltip>
    );
  return (
    <Box>
      <Popover placement="bottom">
        <PopoverTrigger>
          <IconButton colorScheme="red" icon={<Icon as={MdErrorOutline} boxSize="20px" />} />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody fontSize="sm">
            <Box p="5px">
              <Text fontWeight="semibold">Existem reuniões conflitantes</Text>
              <Text fontSize="xs">As reuniões abaixo são conflitantes com {paths[path]}</Text>
            </Box>
            <VStack mt="10px" alignItems="stretch">
              {_.map(data, (item) => (
                <HStack p="10px" borderRadius="lg" borderWidth="1px">
                  <Box flex="1">
                    <Text fontSize="xs">NID {item.nid}</Text>
                    <Text fontWeight="semibold">{item.customerTradingName}</Text>
                    <Text fontSize="sm">{item.scheduledDate}</Text>
                  </Box>

                  <IconButton
                    as={RouterLink}
                    to={`/meetings/edit/${item._id}`}
                    target="_blank"
                    size="xs"
                    variant="outline"
                    icon={<Icon as={TbExternalLink} />}
                  />
                </HStack>
              ))}
            </VStack>
          </PopoverBody>
          {path === "meetingRooms" && (
            <PopoverFooter>
              <ConflictingMeetingRoom />
            </PopoverFooter>
          )}
        </PopoverContent>
      </Popover>
    </Box>
  );
});

export default ConflictingMeetings;
