import React, { Fragment, useContext, useMemo, useState } from "react";
import _ from "lodash";
import { Box, HStack, Icon, IconButton, Td, Text, Th, Tr } from "@chakra-ui/react";
import SearchInput from "../components/SearchInput";
import SortButton from "../components/SortButton";
import { useSearch } from "hooks";
import { RecordsContext } from ".";
import { MdAdd, MdRemove } from "react-icons/md";
import { formatMinutes } from "lib";

const getColor = (item, monthText, upperColor, lowerColor) => {
  const edges = item.avgDurationInMinutes * 0.2;
  if (item.months[monthText] > item.avgDurationInMinutes + edges) return upperColor;
  if (item.months[monthText] < item.avgDurationInMinutes - edges) return lowerColor;
};

const List = ({ data, isParent = false }) => {
  const { months, deviationAnalysis } = useContext(RecordsContext);
  const styleProps = useMemo(() => {
    if (isParent) return { _light: { bg: "white" }, _dark: { bg: "gray.800" } };
    return { _light: { bg: "gray.50" }, _dark: { bg: "gray.900" } };
  }, [isParent]);
  const [searchText, setSearchText] = useState("");
  const [sort, setSort] = useState({ key: "title", direction: "asc" });
  const searched = useSearch(useMemo(() => ({ keys: ["title"], data, searchText }), [data, searchText]));
  const sorted = useMemo(() => _.orderBy(searched, [sort.key], [sort.direction]), [searched, sort]);
  const [isOpen, setIsOpen] = useState({});
  const [upperColor, lowerColor] = useMemo(() => {
    if (deviationAnalysis === "asc") return ["blue.500", "red.500"];
    return ["red.500", "blue.500"];
  }, [deviationAnalysis]);

  return (
    <Fragment>
      <Tr {...styleProps}>
        <Td colSpan={3} p="0" position="sticky" left="0" zIndex="2" {...styleProps}>
          <SearchInput value={searchText} onSubmit={(value) => setSearchText(value)} />
        </Td>
        <Td colSpan={14}></Td>
      </Tr>
      <Tr {...styleProps} position="sticky" top="-12px" zIndex="4">
        <Th textTransform="none" textAlign="left" fontSize="xs" position="sticky" left="-10px" zIndex="2" {...styleProps}>
          <HStack>
            <Text>Título</Text>
            <SortButton keyName="title" {...{ sort, setSort }} />
          </HStack>
        </Th>
        <Th textTransform="none" textAlign="left" fontSize="xs" position="sticky" left="322px" zIndex="2" {...styleProps}>
          <HStack>
            <Text>Total</Text>
            <SortButton keyName="totalDurationInMinutes" {...{ sort, setSort }} />
          </HStack>
        </Th>
        <Th textTransform="none" textAlign="left" fontSize="xs" position="sticky" left="434px" zIndex="2" {...styleProps}>
          <HStack>
            <Text>Média</Text>
            <SortButton keyName="avgDurationInMinutes" {...{ sort, setSort }} />
          </HStack>
        </Th>
        <Th textTransform="none" textAlign="left" fontSize="xs" position="sticky" left="546px" zIndex="2" p="0" {...styleProps}>
          <HStack borderRightWidth="1px" py="8px" px="14px">
            <Text>Máxima</Text>
            <SortButton keyName="maxDurationInMinutes" {...{ sort, setSort }} />
          </HStack>
        </Th>
        {months.map((month) => (
          <Th key={month.monthText} textTransform="none" textAlign="left" fontSize="xs">
            <HStack>
              <Text>{month.monthText}</Text>
              <SortButton keyName={"months.".concat(month.monthText)} {...{ sort, setSort }} />
            </HStack>
          </Th>
        ))}
      </Tr>
      {_.map(sorted, (item) => (
        <Fragment key={item._id}>
          <Tr {...styleProps} _hover={{ _light: { "&>td": { bg: "gray.100" } }, _dark: { "&>td": { bg: "blackAlpha.500!important" } } }}>
            <Td position="sticky" left="-10px" zIndex="2" {...styleProps}>
              <HStack w="300px">
                {item.activities.length >= 1 && (
                  <IconButton
                    size="xs"
                    variant="outline"
                    icon={<Icon as={isOpen[item._id] ? MdRemove : MdAdd} />}
                    onClick={() => setIsOpen((state) => ({ ...state, [item._id]: !state[item._id] }))}
                  />
                )}
                <Text noOfLines={1}>{item.title}</Text>
              </HStack>
            </Td>
            <Td position="sticky" left="322px" zIndex="2" {...styleProps}>
              <Box w="80px">{formatMinutes(item.totalDurationInMinutes)}</Box>
            </Td>
            <Td position="sticky" left="434px" zIndex="2" {...styleProps}>
              <Box w="80px">{formatMinutes(item.avgDurationInMinutes)}</Box>
            </Td>
            <Td position="sticky" left="546px" zIndex="2" p="0" {...styleProps}>
              <Box w="80px" borderRightWidth="1px" py="12px" px="16px" boxSizing="content-box">
                {formatMinutes(item.maxDurationInMinutes)}
              </Box>
            </Td>
            {months.map(({ monthText }) => (
              <Td key={monthText} color={getColor(item, monthText, upperColor, lowerColor)}>
                {formatMinutes(item.months[monthText])}
              </Td>
            ))}
          </Tr>
          {isOpen[item._id] && <List data={item.activities} />}
        </Fragment>
      ))}
    </Fragment>
  );
};

export default List;
