import React, { memo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Heading, HStack, Icon, Link, Divider } from "@chakra-ui/react";
import { Breadcrumb } from "components";
import { useDocumentTitle } from "hooks";
import { Content } from "pages/Private/Container";
import { MdRemove } from "react-icons/md";
import { PermissionedContainer } from "components";

const ListItem = memo(({ to, title }) => {
  return (
    <HStack p="10px" transition="200ms" fontSize="sm">
      <Icon as={MdRemove} />
      <Link as={RouterLink} to={to}>
        {title}
      </Link>
    </HStack>
  );
});

const Container = () => {
  useDocumentTitle("Painéis de controle");

  return (
    <Content>
      <HStack justify="space-between">
        <HStack spacing="20px">
          <Breadcrumb items={[{ label: "painéis de controle" }]} />
        </HStack>
      </HStack>

      <Heading size="md" my="15px">
        Painéis de controle
      </Heading>

      <PermissionedContainer required="dashboards:activities">
        <ListItem to="/dashboards/activities" title="Painel de controle de atividades" />
        <Divider />
      </PermissionedContainer>
      <PermissionedContainer required="dashboards:scheduling">
        <ListItem to="/dashboards/scheduling" title="Painel de controle do agendamento" />
        <Divider />
      </PermissionedContainer>
      <PermissionedContainer required="dashboards:records">
        <ListItem to="/dashboards/records" title="Painel de controle de cadastros" />
        <Divider />
      </PermissionedContainer>
    </Content>
  );
};

export default Container;
