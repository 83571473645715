import React, { useMemo, useRef, useState, useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  SlideFade,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import { MdChevronLeft, MdMoreHoriz } from "react-icons/md";
import { FiClipboard } from "react-icons/fi";
import { Breadcrumb, ExportCsv, Paginator, Portal, TableDrawer, TableEmpty } from "components";
import { useApiGet, useClipboard, useDocumentTitle, useTable } from "hooks";
import { Content, ContentBody, ContentHeader } from "pages/Private/Container";
import defaultColumns from "./defaultColumns";
import Filters from "./filters";
import { TbExternalLink, TbFileExport } from "react-icons/tb";
import { api } from "lib";

export const parentRefs = {
  Task: { path: "/tasks/edit/", label: "Tarefa" },
  Meeting: { path: "/meetings/edit/", label: "Reunião" },
};

export const ConsultationsList = () => {
  useDocumentTitle("Consultorias");
  const navigate = useNavigate();
  const location = useLocation();
  const [sort, setSort] = useState({ dueDate: 1 });
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(100);
  const [query, setQuery] = useState();
  const [data, isLoadingData, refreshData, errorData, fetchAllPaginatedData] = useApiGet(
    useMemo(
      () => ({
        path: "/consultations",
        params: { query, sort, page, perPage },
        options: { isEnabled: _.isObject(query) },
      }),
      [query, sort, page, perPage]
    )
  );
  const { columns, cells, updateTableColumns } = useTable(
    useMemo(() => ({ id: location.pathname, defaultColumns, _v: 7 }), [location.pathname])
  );
  const { isOpen: isOpenExportData, onOpen: onOpenExportData, onClose: onCloseExportData } = useDisclosure();
  const tableDrawerRef = useRef();
  const copyToClipboard = useClipboard();

  const handleTableDrawerChange = useCallback(
    ({ sort, perPage, columns }) => {
      setSort(sort);
      setPerPage(perPage);
      updateTableColumns(columns);
    },
    [updateTableColumns]
  );

  return (
    <>
      <Content>
        <ContentHeader>
          <HStack justify="space-between">
            <HStack>
              {location.state && (
                <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
                  voltar
                </Button>
              )}
              <Breadcrumb items={[{ label: "cadastros" }, { to: "/consultations", label: "consultorias" }]} />
            </HStack>
            <SlideFade in={true} offsetY="-20px">
              <HStack>
                <Box>
                  <Menu>
                    <MenuButton as={Button} size="sm" variant="outline" rightIcon={<Icon as={MdMoreHoriz} />}>
                      mais ações
                    </MenuButton>
                    <Portal>
                      <MenuList fontSize="sm">
                        <MenuItem onClick={onOpenExportData}>
                          <HStack>
                            <Icon as={TbFileExport} />
                            <Text>exportar consultorias</Text>
                          </HStack>
                        </MenuItem>
                      </MenuList>
                    </Portal>
                  </Menu>
                </Box>
              </HStack>
            </SlideFade>
          </HStack>
          <Heading my="15px" size="md">
            Consultorias
          </Heading>
          <Filters
            onQuery={setQuery}
            onPage={setPage}
            onRefresh={refreshData}
            isLoading={isLoadingData}
            onTableDrawer={() => tableDrawerRef.current.open()}
          />
        </ContentHeader>

        <ContentBody>
          <Table size="sm" whiteSpace="nowrap">
            <Thead>
              <Tr>
                <Th>#</Th>
                {cells.map(({ accessor, title }) => (
                  <Th key={accessor}>{title}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {_.map(data?.data, (item) => (
                <Tr key={item._id} _hover={{ _light: { bg: "gray.50" }, _dark: { bg: "gray.900" } }}>
                  <Td>
                    <HStack>
                      <Box>
                        <Menu placement="right-start">
                          <MenuButton as={IconButton} size="xs" colorScheme="main" icon={<Icon as={MdMoreHoriz} />} />
                          <MenuList>
                            <MenuGroup title={item.name} pb="5px">
                              <MenuItem icon={<Icon as={FiClipboard} />} onClick={() => copyToClipboard(item._id)}>
                                copiar código
                              </MenuItem>
                              <MenuItem
                                as={RouterLink}
                                to={parentRefs[item.parentRef].path.concat(item.parent)}
                                target="_blank"
                                icon={<Icon as={TbExternalLink} />}
                              >
                                {parentRefs[item.parentRef].label}
                              </MenuItem>
                            </MenuGroup>
                          </MenuList>
                        </Menu>
                      </Box>
                    </HStack>
                  </Td>
                  {cells.map(({ accessor, formatter, render }) => (
                    <Td key={accessor}>{formatter?.(item) ?? render?.(item)}</Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
            <Tfoot _light={{ bg: "gray.50" }} _dark={{ bg: "gray.900" }} fontWeight="bold">
              <Tr>
                <Td></Td>
                {cells.map(({ accessor, renderFoot }) => (
                  <Td key={accessor}>{renderFoot?.(data?.totals ?? {})}</Td>
                ))}
              </Tr>
            </Tfoot>
          </Table>
          {isLoadingData && (
            <Center p="30px">
              <Spinner />
            </Center>
          )}
          <TableEmpty isLoading={isLoadingData} size={_.size(data?.data)} hasNewButton={false} />
        </ContentBody>
      </Content>

      <Divider />

      <SlideFade in={true} offsetY="20px">
        <Box p="20px">
          <Paginator loading={isLoadingData} page={page} size={data?.size} perPage={perPage} onPaginate={setPage} />
        </Box>
      </SlideFade>

      <TableDrawer
        ref={tableDrawerRef}
        defaultColumns={defaultColumns}
        columns={columns}
        sort={sort}
        perPage={perPage}
        onChange={handleTableDrawerChange}
      />

      <ExportCsv
        filename="consultorias"
        onFetchData={fetchAllPaginatedData}
        columns={columns}
        isOpen={isOpenExportData}
        onClose={onCloseExportData}
      />
    </>
  );
};
