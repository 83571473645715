import React, { createContext, useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { Center, Spinner, useMediaQuery } from "@chakra-ui/react";
import { Private, Public } from "pages";
import { InvalidAuthorization, NetworkError, PermissionDenied, AppVersionError } from "exceptions";
import SocketProvider from "SocketProvider";

export const AppContext = createContext();

const AppProvider = () => {
  const [currentAuth, setCurrentAuth] = useState();
  const [isMobileView] = useMediaQuery("(max-width: 940px)");

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      try {
        await user.reload();
      } finally {
        setCurrentAuth(user);
      }
    });
  }, []);

  if (currentAuth === undefined)
    return (
      <Center h="100vh">
        <Spinner />
      </Center>
    );
  return (
    <AppContext.Provider value={{ currentAuth, isMobileView }}>
      {currentAuth ? (
        <SocketProvider>
          <Private />
        </SocketProvider>
      ) : (
        <Public />
      )}
      <NetworkError />
      <PermissionDenied />
      <InvalidAuthorization />
      <AppVersionError />
    </AppContext.Provider>
  );
};

export default AppProvider;
