const download = async (csvString, filename) => {
  const universalBOM = "\uFEFF";
  const a = window.document.createElement("a");
  a.setAttribute("href", "data:text/csv; charset=utf-8," + encodeURIComponent(universalBOM + csvString));
  a.setAttribute("download", filename);
  window.document.body.appendChild(a);
  a.click();
};

export const csvDownload = async (columns, rows, filename) => {
  const separator = ";";
  const data = rows.map((row) => row.join(separator));
  data.unshift(columns.map(({ label }) => label).join(separator));
  await download(data.join("\n"), `${filename}.csv`);
};
