import React, { createContext, memo, useContext, useEffect, useMemo, useRef } from "react";
import { Box, Center, Divider, HStack, SimpleGrid, Spinner, Text, VStack } from "@chakra-ui/react";
import _ from "lodash";
import { translator } from "lib";
import { ContainerContext } from "pages/Private/Container";

const KanbanContext = createContext();

const Column = memo(({ status, items }) => {
  const { contentScrollTop } = useContext(ContainerContext);
  const { renderItem, isLoading } = useContext(KanbanContext);
  const headerRef = useRef();
  const isSticky = useMemo(() => {
    const bounding = headerRef.current?.getBoundingClientRect();
    return bounding?.top <= 55;
  }, [contentScrollTop]);

  return (
    <VStack spacing="0" borderWidth="1px" borderRadius="md" alignItems="stretch">
      <HStack
        ref={headerRef}
        p="10px"
        justifyContent="center"
        position="sticky"
        top="-5px"
        zIndex="1"
        borderRadius="md"
        mx={isSticky && "3%"}
        color={isSticky && "white"}
        _light={{ bg: isSticky ? status.color : "white" }}
        _dark={{ bg: isSticky ? status.color : "gray.800" }}
        w={isSticky ? "94%" : "100%"}
        transition="200ms"
      >
        <Box w="10px" h="20px" bg={isSticky ? "blackAlpha.300" : status.color} borderRadius="full" />
        <Text fontSize="xs" fontWeight="semibold" whiteSpace="nowrap">
          {translator(status.value).replace("Cancelado pelo", "Canc.")}
        </Text>
      </HStack>
      <Divider />
      <VStack flex="1" p="2px" spacing="2px" alignItems="stretch" minHeight="80px">
        {isLoading ? (
          <Center h="100%">
            <Spinner size="xs" />
          </Center>
        ) : (
          items.map(renderItem)
        )}
      </VStack>
    </VStack>
  );
});

export const GridViewerKanban = ({ statuses, data, renderItem, isLoading }) => {
  const columns = useMemo(() => {
    const response = [];
    const tmp = [...(data ?? [])];
    for (const status of statuses) {
      const items = _.remove(tmp, (o) => o.status === status.value);
      response.push({ status, items });
    }
    return response;
  }, [data]);

  return (
    <KanbanContext.Provider value={{ renderItem, isLoading }}>
      <SimpleGrid columns={[1, statuses.length]} spacing="5px">
        {columns.map((column) => (
          <Column key={column.status.value} status={column.status} items={column.items} renderItem={renderItem} isLoading={isLoading} />
        ))}
      </SimpleGrid>
    </KanbanContext.Provider>
  );
};
