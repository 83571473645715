import React, { useContext, useCallback, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import {
  Box,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Text,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";
import { api, masks } from "lib";
import { useArrayItemHandlers } from "hooks";
import { AsyncSelect, InputCurrency, BoxData, CustomerLogo } from "components";
import CustomerIndicatorsDetailsContext from "../context";
import { translator } from "lib";

let loadCustomersTimeout;

const General = () => {
  const { formData, formErrors, setFormData } = useContext(CustomerIndicatorsDetailsContext);
  const { handleChangeArrayItem } = useArrayItemHandlers(setFormData);

  useEffect(() => {
    setFormData((state) => {
      const date = moment(formData.referenceDate, "DD/MM/YYYY");
      return {
        ...state,
        periodStartDate: date.startOf(state.periodType).format("DD/MM/YYYY"),
        periodEndDate: date.endOf(state.periodType).format("DD/MM/YYYY"),
      };
    });
  }, [formData.periodType, formData.referenceDate]);

  const handleLoadCustomers = useCallback((search, cb) => {
    clearTimeout(loadCustomersTimeout);
    loadCustomersTimeout = setTimeout(async () => {
      const params = {
        search,
        query: { status: { $ne: "inactive" } },
        sort: { tradingName: 1 },
        perPage: 20,
        isAutocomplete: true,
      };
      const response = await api.post("/customers", params);
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
        <GridItem colSpan={{ base: 12, lg: 2 }}>
          <FormControl isRequired={true} isInvalid={formErrors.status}>
            <FormLabel fontSize="sm">Status</FormLabel>
            <Select value={formData.status ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, status: target.value }))}>
              <option>--Selecione</option>
              <option value="pending">Pendente</option>
              <option value="closed">Fechado</option>
            </Select>
            <FormErrorMessage>{formErrors.status}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 3 }}>
          <FormControl isRequired={true} isInvalid={formErrors.customer}>
            <FormLabel fontSize="sm">Cliente</FormLabel>
            <AsyncSelect
              value={formData.customer}
              defaultOptions
              loadOptions={handleLoadCustomers}
              placeholder="Selecione"
              onChange={(customer) => setFormData((state) => ({ ...state, customer }))}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ logoUrl, tradingName, segment }) => (
                <HStack>
                  <CustomerLogo alt={tradingName} src={logoUrl} boxSize="35px" />
                  <Box flex="1">
                    <Text fontSize="sm" noOfLines={1}>
                      {tradingName}
                    </Text>
                    <Text fontSize="xs">{segment?.title || "-"}</Text>
                  </Box>
                </HStack>
              )}
            />
            <FormErrorMessage>{formErrors.customer}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 2 }}>
          <FormControl isRequired={true} isInvalid={formErrors.referenceDate}>
            <FormLabel fontSize="sm">Data de referência</FormLabel>
            <Input
              as={InputMask}
              mask="99/99/9999"
              value={formData.referenceDate ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, referenceDate: target.value }))}
            />
            <FormErrorMessage>{formErrors.referenceDate}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 2 }}>
          <FormControl isRequired={true} isInvalid={formErrors.periodType}>
            <FormLabel fontSize="sm">Tipo de período</FormLabel>
            <Select
              value={formData.periodType ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, periodType: target.value }))}
            >
              <option>--Selecione</option>
              {["day", "week", "month", "quarter", "year"].map((item) => (
                <option value={item}>{translator(item)}</option>
              ))}
            </Select>
            <FormErrorMessage>{formErrors.periodType}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 3 }}>
          <BoxData label="Período" value={[formData.periodStartDate, formData.periodEndDate].join(" - ")} />
        </GridItem>
      </Grid>

      <Divider my={8} />

      <Heading size="sm" mb={6}>
        Indicadores
      </Heading>
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        {_.map(formData.indicators, (indicator, index) => (
          <GridItem key={index} colSpan={{ base: 12, lg: 4 }}>
            <FormControl isRequired={true} isInvalid={formErrors[`indicators[${index}].value`]}>
              <FormLabel fontSize="sm">{indicator.indicatorType.title}</FormLabel>
              {indicator.indicatorType.type === "textual" ? (
                <Input
                  value={indicator.value ?? ""}
                  onChange={({ target }) => handleChangeArrayItem("indicators", index, { value: target.value })}
                />
              ) : (
                <InputGroup>
                  {indicator.indicatorType.type === "monetary" && <InputLeftElement>R$</InputLeftElement>}
                  <Input
                    as={InputCurrency}
                    precision={indicator.indicatorType.precision}
                    value={indicator.value ?? ""}
                    isPercentage={indicator.indicatorType.type === "percentual"}
                    onChange={(value) => handleChangeArrayItem("indicators", index, { value })}
                  />
                  {indicator.indicatorType.type === "percentual" && <InputRightElement>%</InputRightElement>}
                </InputGroup>
              )}
              <FormErrorMessage>{formErrors[`indicators[${index}].value`]}</FormErrorMessage>
            </FormControl>
          </GridItem>
        ))}
      </Grid>
    </>
  );
};

export default General;
