import React, { createContext, useMemo, useState } from "react";
import { Center, Spinner } from "@chakra-ui/react";
import { useApiGet } from "hooks";
import Router from "./router";

export const PrivateContext = createContext();

const Private = () => {
  const [currentUser, isLoadingCurrentUser, refreshCurrentUser] = useApiGet(useMemo(() => ({ path: "/current-user" }), []));
  const [notificationsCount, setNotificationsCount] = useState(0);

  return currentUser ? (
    <PrivateContext.Provider value={{ currentUser, isLoadingCurrentUser, refreshCurrentUser, notificationsCount, setNotificationsCount }}>
      <Router />
    </PrivateContext.Provider>
  ) : (
    <Center h="100vh">
      <Spinner />
    </Center>
  );
};

export default Private;
