import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useCacheState, useStickyState } from "hooks";
import { RangeDatePicker, SyncSelect } from "components";
import { translator } from "lib";
import { statuses } from "consts";
import { MdClose, MdSearch, MdRefresh } from "react-icons/md";
import { BiCog } from "react-icons/bi";
import { HiOutlineFilter } from "react-icons/hi";

const Filters = ({ onQuery, onSearch, onRefresh, onPage, isLoading, onTableDrawer }) => {
  const location = useLocation();
  const [query, setQuery] = useStickyState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.query"),
        defaultValue: location.state ?? { status: statuses.customers.filter((o) => o.value === "active") },
        useCached: _.isObject(location.state) === false,
        _v: 1,
        processor: (data) => ({
          ...data,
          createdAtStartDate: data.createdAtStartDate && moment(data.createdAtStartDate).toDate(),
          createdAtEndDate: data.createdAtEndDate && moment(data.createdAtEndDate).toDate(),
        }),
      }),
      [location.pathname, location.state]
    )
  );
  const [isOpen, setIsOpen] = useCacheState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.isOpen"),
        defaultValue: false,
      }),
      []
    )
  );
  const [formData, setFormData] = useState(query);
  const [isFiltering, setIsFiltering] = useState(false);
  const backgroundColor = useColorModeValue("gray.50", "blackAlpha.300");
  const containerProps = useMemo(
    () => isOpen && { padding: { base: "10px", lg: "20px" }, backgroundColor, marginBottom: 8 },
    [isOpen, backgroundColor]
  );

  useEffect(() => {
    const response = {};
    if (query.nid?.length) _.set(response, "nid", parseInt(query.nid));
    if (query.status?.length) _.set(response, "status.$in", _.map(query.status, "value"));
    if (query.createdAtStartDate) _.set(response, "createdAt.$gte", query.createdAtStartDate);
    if (query.createdAtEndDate) _.set(response, "createdAt.$lte", query.createdAtEndDate);
    setIsFiltering(Object.keys(response).length > 0);
    onQuery(response);
  }, [onQuery, query, location.state]);

  const handleSubmit = useCallback(() => {
    setQuery(formData);
  }, [setQuery, formData]);

  const handleClean = useCallback(() => {
    setQuery({});
    setFormData({});
  }, [setQuery, setFormData]);

  const handleSearch = useCallback((e) => {
    e.preventDefault();
    const [{ value }] = e.target;
    onSearch(value);
    onPage(0);
  }, []);

  return (
    <Box {...containerProps} borderRadius="lg" transition="400ms">
      <Grid templateColumns="repeat(12,1fr)" gap={2} mb={8}>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <form onSubmit={handleSearch}>
            <InputGroup width="100%">
              <Input variant="filled" placeholder="Pesquisar..." />
              <InputRightElement>
                <Icon as={MdSearch} />
              </InputRightElement>
            </InputGroup>
          </form>
        </GridItem>
        <GridItem as={HStack} justifyContent="space-between" colSpan={{ base: 12, lg: 8 }}>
          <HStack>
            <Button
              colorScheme={isFiltering ? "main" : "gray"}
              variant="outline"
              rightIcon={<Icon as={HiOutlineFilter} />}
              onClick={() => setIsOpen((state) => !state)}
            >
              filtros
            </Button>
            {isFiltering && (
              <Button variant="outline" rightIcon={<Icon as={MdClose} />} onClick={handleClean}>
                limpar filtros
              </Button>
            )}
          </HStack>
          <HStack>
            <IconButton variant="outline" icon={<Icon as={MdRefresh} />} fontSize="sm" isLoading={isLoading} onClick={onRefresh} />
            <IconButton fontSize="sm" variant="outline" icon={<Icon as={BiCog} />} onClick={onTableDrawer} />
          </HStack>
        </GridItem>
      </Grid>
      {isOpen && (
        <>
          <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
            <GridItem colSpan={{ base: 12, lg: 2 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  NID
                </FormLabel>
                <Input
                  size="sm"
                  variant="filled"
                  value={formData.nid ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, nid: target.value }))}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 5 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Status
                </FormLabel>
                <SyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.status ?? []}
                  placeholder="Selecione"
                  options={statuses.customers}
                  onChange={(status) => setFormData((state) => ({ ...state, status }))}
                  formatOptionLabel={({ color, value }) => (
                    <HStack>
                      <Box bg={color} w="10px" h="10px" borderRadius="full" />
                      <Text>{translator(value)}</Text>
                    </HStack>
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 5 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Criado em
                </FormLabel>
                <HStack>
                  <RangeDatePicker
                    key={formData.createdAtStartDateTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    selectedDates={[formData.createdAtStartDate, formData.createdAtEndDate]}
                    onDateChange={([createdAtStartDate, createdAtEndDate]) =>
                      setFormData((state) => ({ ...state, createdAtStartDate, createdAtEndDate }))
                    }
                  />
                  {formData.createdAtStartDate && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          createdAtStartDate: null,
                          createdAtEndDate: null,
                          createdAtStartDateTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>
          </Grid>
          <HStack justifyContent="flex-end">
            <Button size="sm" colorScheme="main" rightIcon={<Icon as={MdSearch} />} isLoading={isLoading} onClick={handleSubmit}>
              aplicar
            </Button>
          </HStack>
        </>
      )}
    </Box>
  );
};

export default Filters;
