import _ from "lodash";
import moment from "moment";

const getTaskValue = ({ dueDate, durationInMinutes }) => ({
  monthText: moment(dueDate).startOf("month").format("MM/YY"),
  durationInMinutes,
});

const getMeetingValue = ({ startDate, durationInMinutes }) => ({
  monthText: moment(startDate).startOf("month").format("MM/YY"),
  durationInMinutes,
});

export const consultant = function (activity) {
  if (activity.moduleName === "tasks") {
    const records = [{ _id: activity.responsible._id, title: activity.responsible.name, isEntity: true }];
    return [records, getTaskValue(activity)];
  }
  const records = _.map(activity.participants, (participant) => ({
    _id: participant.user._id,
    title: participant.user.name,
    isEntity: true,
  }));
  return [records, getMeetingValue(activity)];
};

export const customer = function (activity) {
  const records = [{ _id: activity.customer._id, title: activity.customer.tradingName, isEntity: true }];
  if (activity.moduleName === "tasks") return [records, getTaskValue(activity)];
  return [records, getMeetingValue(activity)];
};

export const meetingRoom = function (activity) {
  if (activity.moduleName === "meetings" && activity.mainMeetingRoom) {
    const records = [{ _id: activity.mainMeetingRoom._id, title: activity.mainMeetingRoom.title }];
    return [records, getMeetingValue(activity)];
  }
  return [];
};

export const meetingType = function (activity) {
  if (activity.moduleName === "tasks") return [];
  const records = [{ _id: activity.meetingType._id, title: activity.meetingType.title }];
  return [records, getMeetingValue(activity)];
};

export const demand = function (activity) {
  if (activity.moduleName === "tasks") {
    const records = [{ _id: activity.demand._id, title: activity.demand.title }];
    return [records, getTaskValue(activity)];
  }
  const records = _.map(activity.demands, (demand) => ({ _id: demand._id, title: demand.title }));
  return [records, getMeetingValue(activity)];
};

export const team = function (activity) {
  if (activity.moduleName === "tasks") {
    const records = [{ _id: activity.team._id, title: activity.team.title }];
    return [records, getTaskValue(activity)];
  }
  const records = _.map(activity.meetingType.teams, (team) => ({ _id: team._id, title: team.title }));
  return [records, getMeetingValue(activity)];
};
