import React, { useState, useEffect, useMemo, useCallback } from "react";
import _ from "lodash";
import { Link as RouterLink, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  SlideFade,
  Spinner,
  StackDivider,
  Switch,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import * as yup from "yup";
import { messages } from "consts";
import { api } from "lib";
import { useCustomToast, useDocumentTitle, useApiGet } from "hooks";
import { PermissionedContainer, Breadcrumb, AsyncSelect, BoxData, DocumentHistory } from "components";
import { MdChevronLeft, MdEdit, MdHistory } from "react-icons/md";
import { Content } from "pages/Private/Container";

let loadUsersTimeout = {};

export const TeamsDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar time" : "Nova time");
  const navigate = useNavigate();
  const location = useLocation();
  const [data, isLoadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/teams/${_id}` }), [_id]));
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const toast = useCustomToast();

  useEffect(() => {
    const formData = data ?? { isActive: true };
    setFormData(formData);
  }, [data]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.patch(`/teams/${_id}`, data) : await api.put("/teams", data);
        navigate(`/settings/records/teams/edit/${saved._id}`, { replace: true });
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        if (error.isHandled) return;
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        title: yup.string().required(messages.error.required),
        responsible: yup.string().required(messages.error.required),
        users: yup.array().min(1, messages.error.required).required(messages.error.required),
      });
      const data = {
        ...formData,
        responsible: formData.responsible?._id,
        users: _.map(formData.users, "_id"),
      };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  const handleLoadUsers = useCallback((key, search, cb) => {
    clearTimeout(loadUsersTimeout[key]);
    loadUsersTimeout[key] = setTimeout(async () => {
      const response = await api.post("/users", {
        search,
        query: { isActive: true },
        sort: { name: 1 },
        perPage: 20,
        isAutocomplete: true,
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  return (
    <>
      <Content>
        <HStack justify="space-between">
          <HStack spacing={{ base: "10px", lg: "20px" }}>
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "configurações" },
                { to: "/settings#records", label: "cadastros" },
                { to: "/settings/records/teams", label: "times" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          {_id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Time</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.title : "Novo cadastro"}</Text>
          </Box>
          <HStack>
            <FormControl display="flex" alignItems="center">
              <FormLabel fontSize="sm" mb="0">
                Ativo?
              </FormLabel>
              <Switch
                colorScheme="main"
                isChecked={formData.isActive}
                onChange={() => setFormData((state) => ({ ...state, isActive: !state.isActive }))}
              />
            </FormControl>
          </HStack>
        </HStack>

        <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
          <GridItem colSpan={{ base: 4, lg: 2 }}>
            <BoxData label="NID" value={formData.nid?.toLocaleString() ?? "-"} />
          </GridItem>
          <GridItem colSpan={{ base: 8, lg: 10 }}>
            <FormControl isRequired={true} isInvalid={formErrors.title}>
              <FormLabel fontSize="sm">Título</FormLabel>
              <Input value={formData.title ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))} />
              <FormErrorMessage>{formErrors.title}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={{ base: 12, lg: 4 }}>
            <FormControl isRequired={true} isInvalid={formErrors.responsible}>
              <FormLabel fontSize="sm">Responsável</FormLabel>
              <AsyncSelect
                value={formData.responsible}
                defaultOptions
                loadOptions={(search, cb) => handleLoadUsers("responsible", search, cb)}
                placeholder="Selecione"
                onChange={(responsible) => setFormData((state) => ({ ...state, responsible }))}
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ avatarUrl, name }) => (
                  <HStack>
                    <Avatar size="xs" name={name} src={avatarUrl} />
                    <Text>{name}</Text>
                  </HStack>
                )}
              />
              <FormErrorMessage>{formErrors.responsible}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 8 }}>
            <FormControl isRequired={true} isInvalid={formErrors.users}>
              <FormLabel fontSize="sm">Usuários</FormLabel>
              <AsyncSelect
                value={formData.users ?? []}
                isMulti
                defaultOptions
                loadOptions={(search, cb) => handleLoadUsers("users", search, cb)}
                placeholder="Selecione"
                onChange={(users) => setFormData((state) => ({ ...state, users }))}
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ avatarUrl, name }) => (
                  <HStack>
                    <Avatar size="xs" name={name} src={avatarUrl} />
                    <Text>{name}</Text>
                  </HStack>
                )}
              />
              <FormErrorMessage>{formErrors.users}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>

        {_.size(formData.demands) > 0 && (
          <>
            <Divider my={8} />

            <Heading size="sm" mb={6}>
              Demandas
            </Heading>
            <VStack alignItems="stretch" spacing="15px" divider={<StackDivider />}>
              {_.map(formData.demands, (demand) => (
                <HStack justifyContent="space-between">
                  <Text>{demand.title}</Text>
                  <Button
                    as={RouterLink}
                    to={`/settings/records/demands/edit/${demand._id}`}
                    target="_blank"
                    size="sm"
                    variant="outline"
                    leftIcon={<Icon as={MdEdit} />}
                  >
                    editar
                  </Button>
                </HStack>
              ))}
            </VStack>
          </>
        )}
      </Content>

      <PermissionedContainer required={"teams:".concat(_id ? "update" : "create")}>
        <Divider />
        <SlideFade in={true} offsetY="20px">
          <HStack p="20px">
            <Button size="sm" colorScheme="main" isLoading={isLoadingData || isLoadingSaveData} onClick={handleSubmit}>
              salvar
            </Button>
            <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
              voltar
            </Button>
          </HStack>
        </SlideFade>
      </PermissionedContainer>

      {_id && <DocumentHistory path={`/teams/${_id}/history`} isOpen={isOpenDocumentHistory} onClose={onCloseDocumentHistory} />}
    </>
  );
};
