import React, { useMemo } from "react";
import { Text } from "@chakra-ui/react";
import { currency, percent } from "lib";

export const IndicatorValue = ({ type, value, ...props }) => {
  const content = useMemo(() => {
    switch (type) {
      case "textual":
        return value;
      case "numeric":
        return value.toLocaleString();
      case "monetary":
        return currency.format(value);
      default:
        return percent.format(value);
    }
  }, [type, value]);

  return <Text {...props}>{content}</Text>;
};
