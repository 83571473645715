import React, { useContext, useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import _ from "lodash";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Link,
  StackDivider,
  VStack,
} from "@chakra-ui/react";
import { api } from "lib";
import { useArrayItemHandlers, usePermissioned } from "hooks";
import { PermissionedContainer, PhoneInput, AsyncSelect, EmailAutocomplete } from "components";
import { MdAddCircleOutline, MdOutlineDelete } from "react-icons/md";
import CustomersDetailsContext from "../context";

let loadDemandsTimeout;

const Contacts = () => {
  const { formData, setFormData, formErrors } = useContext(CustomersDetailsContext);
  const { handleChangeArrayItem, handleAddArrayItem, handleDeleteArrayItem } = useArrayItemHandlers(setFormData);
  const isAllowedUpdateDemands = usePermissioned("demands:update");

  const handleLoadDemands = useCallback((search, cb) => {
    clearTimeout(loadDemandsTimeout);
    loadDemandsTimeout = setTimeout(async () => {
      const response = await api.post("/demands", {
        search,
        query: { isActive: true },
        sort: { title: 1 },
        perPage: 20,
        isAutocomplete: true,
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  return (
    <>
      <Heading size="sm" mb={6}>
        Pessoas de contato
      </Heading>
      <VStack align="stretch" divider={<StackDivider />} spacing={8}>
        {_.map(formData.contacts, (contact, index) => (
          <Grid key={index} templateColumns="repeat(12, 1fr)" gap={4}>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl isRequired={true} isInvalid={formErrors.contacts?.[index]?.name}>
                <FormLabel fontSize="sm">Nome</FormLabel>
                <Input
                  value={contact.name ?? ""}
                  onChange={({ target }) => handleChangeArrayItem("contacts", index, { name: target.value })}
                />
                <FormErrorMessage>{formErrors.contacts?.[index]?.name}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 2 }}>
              <FormControl isRequired={true} isInvalid={formErrors.contacts?.[index]?.office}>
                <FormLabel fontSize="sm">Cargo</FormLabel>
                <Input
                  value={contact.office ?? ""}
                  onChange={({ target }) => handleChangeArrayItem("contacts", index, { office: target.value })}
                />
                <FormErrorMessage>{formErrors.contacts?.[index]?.office}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 2 }}>
              <FormControl isRequired={true} isInvalid={formErrors.contacts?.[index]?.email}>
                <FormLabel fontSize="sm">E-mail</FormLabel>
                <EmailAutocomplete value={contact.email ?? ""} onChange={(email) => handleChangeArrayItem("contacts", index, { email })} />
                <FormErrorMessage>{formErrors.contacts?.[index]?.email}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 2 }}>
              <PhoneInput
                label="Telefone"
                phone={contact.phone}
                category={contact.phoneCategory}
                onChangePhone={(phone) => handleChangeArrayItem("contacts", index, { phone })}
                onChangeCategory={(phoneCategory) => handleChangeArrayItem("contacts", index, { phoneCategory })}
                isRequired={false}
                isInvalid={formErrors.contacts?.[index]?.phone}
              />
            </GridItem>
            <GridItem colSpan={{ base: 7, lg: 2 }}>
              <FormControl isInvalid={formErrors.contacts?.[index]?.branch}>
                <FormLabel fontSize="sm">Ramal</FormLabel>
                <HStack>
                  <Input
                    value={contact.branch ?? ""}
                    onChange={({ target }) => handleChangeArrayItem("contacts", index, { branch: target.value })}
                  />
                  <IconButton
                    size="md"
                    variant="outline"
                    icon={<Icon as={MdOutlineDelete} />}
                    onClick={() => handleDeleteArrayItem("contacts", index)}
                  />
                </HStack>
                <FormErrorMessage>{formErrors.contacts?.[index]?.branch}</FormErrorMessage>
              </FormControl>
            </GridItem>
          </Grid>
        ))}
      </VStack>
      <Button
        size="sm"
        colorScheme="main"
        variant="ghost"
        mt={4}
        leftIcon={<Icon as={MdAddCircleOutline} />}
        onClick={() => handleAddArrayItem("contacts", { phoneCategory: "cellphone" })}
      >
        adicionar contato
      </Button>
    </>
  );
};

export default Contacts;
