import React, { useCallback, useContext, useState } from "react";
import _ from "lodash";
import { Avatar, Box, Icon, IconButton, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from "@chakra-ui/react";
import { FileUpload } from "components";
import { api } from "lib";
import { useCustomToast } from "hooks";
import { MdEdit } from "react-icons/md";
import { PrivateContext } from "pages/Private";

const Toolbar = () => {
  const { currentUser, isLoadingCurrentUser, refreshCurrentUser } = useContext(PrivateContext);
  const { isOpen: isOpenAvatarChange, onOpen: onOpenAvatarChange, onClose: onCloseAvatarChange } = useDisclosure();
  const [isLoadingDeleteData, setIsLoadingDeleteData] = useState(false);
  const toast = useCustomToast();

  const handleDeleteAvatar = useCallback(async () => {
    try {
      setIsLoadingDeleteData(true);
      await api.delete(`files/${currentUser.avatar?._id}`);
      refreshCurrentUser();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingDeleteData(false);
    }
  }, [refreshCurrentUser]);

  return (
    <>
      <Box position="relative">
        <Avatar size="xl" marginBottom="10px" name={currentUser.name} src={currentUser.avatar?.thumb} />
        <Menu>
          <MenuButton
            as={IconButton}
            size="xs"
            colorScheme="main"
            position="absolute"
            right="10px"
            bottom="10px"
            borderRadius="full"
            icon={<Icon as={MdEdit} />}
            isLoading={isLoadingCurrentUser || isLoadingDeleteData}
          />
          <MenuList>
            <MenuItem onClick={onOpenAvatarChange}>Carregar foto</MenuItem>
            <MenuItem onClick={handleDeleteAvatar} isDisabled={!currentUser.avatar?._id}>
              Remover foto
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
      <FileUpload
        path="/files"
        group="avatar"
        ownerRef="User"
        owner={currentUser._id}
        isOpen={isOpenAvatarChange}
        onClose={onCloseAvatarChange}
        onChange={refreshCurrentUser}
      />
    </>
  );
};

export default Toolbar;
