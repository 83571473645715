import React, { useContext } from "react";
import _ from "lodash";
import { Box, Heading, Text } from "@chakra-ui/react";
import { InputChips } from "components";
import MeetingsDetailsContext from "../context";

const CustomerParticipants = () => {
  const { formData, setFormData, formErrors } = useContext(MeetingsDetailsContext);

  return (
    <>
      <Box mb={6}>
        <Heading size="sm">Participantes do cliente</Heading>
        {_.isString(formErrors.customerParticipants) && (
          <Text fontSize="sm" color="red.500">
            {formErrors.customerParticipants}
          </Text>
        )}
      </Box>

      <InputChips
        value={formData.customerParticipants}
        onChange={(customerParticipants) => setFormData((state) => ({ ...state, customerParticipants }))}
      />
    </>
  );
};

export default CustomerParticipants;
