import React from "react";

const Icon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 0 9.94 9.939">
      <path
        id="Caminho_100"
        data-name="Caminho 100"
        d="M1856.1,452.78a1.656,1.656,0,1,0-2.071,1.6v3a1.656,1.656,0,1,0,.829,0v-3a1.659,1.659,0,0,0,1.242-1.6Zm-2.485,0a.828.828,0,1,1,.828.829A.829.829,0,0,1,1853.619,452.78Zm1.657,6.212a.828.828,0,1,1-.829-.828A.83.83,0,0,1,1855.276,458.992Zm6.212-1.6v-2.951a2.073,2.073,0,0,0-2.071-2.071h-1.437l.909-.957a.414.414,0,1,0-.6-.571l-.992,1.045a1.239,1.239,0,0,0-.013,1.744l1,1.09a.414.414,0,0,0,.61-.56l-.884-.963h1.407a1.245,1.245,0,0,1,1.243,1.243v2.951a1.656,1.656,0,1,0,.828,0Zm-.414,2.433a.829.829,0,1,1,.828-.829A.829.829,0,0,1,1861.074,459.821Z"
        transform="translate(-1852.791 -450.71)"
        fill="#cc7729"
      />
    </svg>
  );
};

export default Icon;
