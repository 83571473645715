import React, { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
  Text,
} from "@chakra-ui/react";
import moment from "moment";
import { useCacheState, useStickyState } from "hooks";
import { MdCalendarMonth, MdChevronLeft, MdChevronRight, MdKeyboardArrowDown, MdToday } from "react-icons/md";
import { BiCalendarWeek } from "react-icons/bi";
import { BsCalendarRange } from "react-icons/bs";
import { TbCalendarDue, TbLayoutKanban } from "react-icons/tb";
import { FiUsers } from "react-icons/fi";

const dateRanges = {
  day: { icon: MdToday, label: "Dia" },
  week: { icon: BiCalendarWeek, label: "Semana" },
  fortnight: { icon: BsCalendarRange, label: "Quinzena" },
  month: { icon: MdCalendarMonth, label: "Mês" },
};

const viewModes = {
  calendar: { icon: TbCalendarDue, label: "Calendário" },
  kanban: { icon: TbLayoutKanban, label: "Quadro Kanban" },
  consultant: { icon: FiUsers, label: "Consultor" },
};

export const GridViewerToolbar = ({ onChange }) => {
  const location = useLocation();
  const [viewMode, setViewMode] = useStickyState(
    useMemo(
      () => ({
        key: location.pathname.concat("viewer.viewMode"),
        defaultValue: "calendar",
        _v: 1,
      }),
      [location.pathname]
    )
  );
  const [dateRange, setDateRange] = useStickyState(
    useMemo(
      () => ({
        key: location.pathname.concat("viewer.dateRange"),
        defaultValue: "fortnight",
      }),
      [location.pathname]
    )
  );
  const [addDate, setAddDate] = useCacheState(
    useMemo(() => ({ key: location.pathname.concat("viewer.addDate"), defaultValue: 0 }), [location.pathname])
  );
  const [startDate, setStartDate] = useCacheState(
    useMemo(
      () => ({ key: location.pathname.concat("viewer.startDate"), defaultValue: moment().startOf(dateRange).toDate() }),
      [location.pathname, dateRange]
    )
  );
  const [endDate, setEndDate] = useCacheState(
    useMemo(
      () => ({ key: location.pathname.concat("viewer.endDate"), defaultValue: moment().endOf(dateRange).toDate() }),
      [location.pathname, dateRange]
    )
  );

  useEffect(() => {
    let startDate = moment().startOf(dateRange).add(addDate, dateRange);
    let endDate = moment().endOf(dateRange).add(addDate, dateRange);
    if (dateRange === "fortnight") {
      startDate = moment()
        .startOf("week")
        .add(addDate * 2, "week");
      endDate = moment(startDate).endOf("week").add(1, "week");
    } else if (dateRange === "month") startDate.subtract(startDate.day(), "days");
    setStartDate(startDate.toDate());
    setEndDate(endDate.toDate());
  }, [dateRange, addDate, setStartDate, setEndDate]);

  useEffect(() => {
    onChange(viewMode, dateRange, startDate, endDate);
  }, [viewMode, dateRange, startDate, endDate, onChange]);

  return (
    <HStack my="15px" zIndex="999" display={{ base: "block", lg: "flex" }} spacing={{ base: 0, lg: 2 }}>
      <Box flex="1" py="5px">
        <Heading size="sm">{moment(endDate).format("MMMM [de] YYYY")}</Heading>
        <Text fontSize="sm">
          {moment(startDate).format("ddd, DD [de] MMMM")} - {moment(endDate).format("ddd, DD [de] MMMM")}
        </Text>
      </Box>
      <Box py="5px">
        <ButtonGroup size="sm" isAttached variant="outline" w="100%">
          <IconButton flex="1" icon={<Icon as={MdChevronLeft} />} onClick={() => setAddDate((state) => (state -= 1))} />
          <Button flex="1" isActive={addDate === 0} onClick={() => setAddDate(0)}>
            Hoje
          </Button>
          <IconButton flex="1" icon={<Icon as={MdChevronRight} />} onClick={() => setAddDate((state) => (state += 1))} />
        </ButtonGroup>
      </Box>
      <Box py="5px">
        <Menu>
          <MenuButton
            as={Button}
            w="100%"
            size="sm"
            variant="outline"
            leftIcon={<Icon as={dateRanges[dateRange].icon} />}
            rightIcon={<Icon as={MdKeyboardArrowDown} />}
          >
            {dateRanges[dateRange].label}
          </MenuButton>
          <Portal>
            <MenuList>
              <MenuOptionGroup value={dateRange} onChange={(value) => setDateRange(value)}>
                {Object.entries(dateRanges).map(([value, { icon, label }]) => (
                  <MenuItemOption key={value} value={value}>
                    <HStack>
                      <Text flex="1" fontSize="sm">
                        {label}
                      </Text>
                      <Icon as={icon} />
                    </HStack>
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Portal>
        </Menu>
      </Box>
      <Box>
        <Menu>
          <MenuButton
            as={Button}
            w="100%"
            size="sm"
            variant="outline"
            leftIcon={<Icon as={viewModes[viewMode].icon} />}
            rightIcon={<Icon as={MdKeyboardArrowDown} />}
          >
            {viewModes[viewMode].label}
          </MenuButton>
          <Portal>
            <MenuList>
              <MenuOptionGroup value={viewMode} onChange={(value) => setViewMode(value)}>
                {Object.entries(viewModes).map(([value, { icon, label }]) => (
                  <MenuItemOption key={value} value={value}>
                    <HStack>
                      <Text flex="1" fontSize="sm">
                        {label}
                      </Text>
                      <Icon as={icon} />
                    </HStack>
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Portal>
        </Menu>
      </Box>
    </HStack>
  );
};
