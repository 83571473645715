export class Status {
  constructor(tasksSize) {
    this.quantity = 0;
    this.tasksSize = tasksSize;
  }
  incQuantity() {
    this.quantity += 1;
  }
  get percentage() {
    return this.quantity / this.tasksSize;
  }
}
