import React, { useState, useEffect, useMemo, useCallback } from "react";
import _ from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Select,
  SlideFade,
  Spinner,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { MdChevronLeft, MdHistory } from "react-icons/md";
import * as yup from "yup";
import { translator, api } from "lib";
import { messages } from "consts";
import { PermissionedContainer, Breadcrumb, InputCurrency, DocumentHistory, BoxData } from "components";
import { useApiGet, useCustomToast, useDocumentTitle } from "hooks";
import { Content } from "pages/Private/Container";

const types = ["textual", "numeric", "monetary", "percentual"];

export const IndicatorTypesDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar tipo de indicador" : "Nova tipo de indicador");
  const navigate = useNavigate();
  const location = useLocation();
  const [data, isLoadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/indicator-types/${_id}` }), [_id]));
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const isPrecision = useMemo(() => ["numeric", "percentual"].indexOf(formData.type) !== -1, [formData.type]);
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const toast = useCustomToast();

  useEffect(() => {
    setFormData(data ?? { isActive: true });
  }, [data]);

  useEffect(() => {
    setFormData((state) => ({ ...state, precision: state.type !== "textual" && 2 }));
  }, [formData.type]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.patch(`/indicator-types/${_id}`, data) : await api.put("/indicator-types", data);
        navigate(`/settings/records/indicator-types/edit/${saved._id}`, { replace: true });
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        if (error.isHandled) return;
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        title: yup.string().required(messages.error.required),
      });
      await schema.validate(formData, { abortEarly: false });
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <>
      <Content>
        <HStack justify="space-between">
          <HStack spacing={{ base: "10px", lg: "20px" }}>
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "configurações" },
                { to: "/settings#records", label: "cadastros" },
                { to: "/settings/records/indicator-types", label: "tipos de indicador" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          {_id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Tipo de indicador</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.title : "Novo cadastro"}</Text>
          </Box>
          <HStack>
            <FormControl display="flex" alignItems="center">
              <FormLabel fontSize="sm" mb="0">
                Ativo?
              </FormLabel>
              <Switch
                colorScheme="main"
                isChecked={formData.isActive}
                onChange={() => setFormData((state) => ({ ...state, isActive: !state.isActive }))}
              />
            </FormControl>
          </HStack>
        </HStack>

        <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
          <GridItem colSpan={{ base: 12, lg: 2 }}>
            <BoxData label="NID" value={formData.nid?.toLocaleString() ?? "-"} />
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 6 }}>
            <FormControl isRequired={true} isInvalid={formErrors.title}>
              <FormLabel fontSize="sm">Título</FormLabel>
              <Input value={formData.title ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))} />
              <FormErrorMessage>{formErrors.title}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: isPrecision ? 2 : 4 }}>
            <FormControl isRequired={true} isInvalid={formErrors.type}>
              <FormLabel fontSize="sm">Tipo</FormLabel>
              <Select value={formData.type ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, type: target.value }))}>
                <option value="">--Selecione</option>
                {types.map((type) => (
                  <option key={type} value={type}>
                    {translator(type)}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{formErrors.type}</FormErrorMessage>
            </FormControl>
          </GridItem>
          {isPrecision && (
            <GridItem colSpan={{ base: 12, lg: 2 }}>
              <FormControl isRequired={true} isInvalid={formErrors.precision}>
                <FormLabel fontSize="sm">Casas decimais</FormLabel>
                <Input
                  as={InputCurrency}
                  precision="0"
                  value={formData.precision ?? ""}
                  onChange={(precision) => setFormData((state) => ({ ...state, precision }))}
                />
                <FormErrorMessage>{formErrors.precision}</FormErrorMessage>
              </FormControl>
            </GridItem>
          )}
        </Grid>
      </Content>

      <PermissionedContainer required={"indicator-types:".concat(_id ? "update" : "create")}>
        <Divider />
        <SlideFade in={true} offsetY="20px">
          <HStack p="20px">
            <Button size="sm" colorScheme="main" isLoading={isLoadingData || isLoadingSaveData} onClick={handleSubmit}>
              salvar
            </Button>
            <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
              voltar
            </Button>
          </HStack>
        </SlideFade>
      </PermissionedContainer>

      {_id && <DocumentHistory path={`/indicator-types/${_id}/history`} isOpen={isOpenDocumentHistory} onClose={onCloseDocumentHistory} />}
    </>
  );
};
