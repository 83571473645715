import React, { useCallback, useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import { useCustomToast } from "hooks";
import { messages, firebaseAuthErrors } from "consts";
import * as yup from "yup";
import { Logo } from "components";
import { MdKeyboardArrowRight, MdLockOutline, MdMail, MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";

const __DEV__ = process.env.NODE_ENV === "development";

export const SignIn = () => {
  const [formData, setFormData] = useState(__DEV__ ? { email: "marcotulio.magalhaes@gmail.com", password: "123456" } : { type: "pf" });
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const toast = useCustomToast();

  const handleSignIn = useCallback(
    async ({ email, password }) => {
      try {
        setIsLoading(true);
        const auth = getAuth();
        await signInWithEmailAndPassword(auth, email, password);
      } catch (error) {
        if (error.isHandled) return;
        const description = firebaseAuthErrors.find((o) => o.code === error.code)?.message ?? error.message;
        toast({ description, status: "error", duration: 5000, isClosable: true });
        setIsLoading(false);
      }
    },
    [toast]
  );

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        const schema = yup.object().shape({
          email: yup.string().required(messages.error.required),
          password: yup.string().required(messages.error.required),
        });
        await schema.validate(formData, { abortEarly: false });
        handleSignIn(formData);
        setFormErrors({});
      } catch (error) {
        const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
        setFormErrors(formErrors);
      }
    },
    [handleSignIn, formData]
  );

  return (
    <Box p={8}>
      <VStack mb={4}>
        <Logo width={250} />
        <Text fontSize="sm" textAlign="center">
          Seja bem-vindo. Por favor, informe suas credenciais nos campos abaixo para iniciar a sessão.
        </Text>
      </VStack>
      <form onSubmit={handleSubmit}>
        <VStack align="stretch" spacing="20px">
          <FormControl isRequired={true} isInvalid={formErrors.email}>
            <FormLabel fontSize="sm">E-mail</FormLabel>
            <InputGroup>
              <InputLeftAddon>
                <Icon as={MdMail} />
              </InputLeftAddon>
              <Input
                value={formData.email ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, email: target.value }))}
                autoFocus={true}
              />
            </InputGroup>
            <FormErrorMessage>{formErrors.email}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired={true} isInvalid={formErrors.password}>
            <HStack mb="8px" justifyContent="space-between">
              <FormLabel fontSize="sm" mb="0">
                Senha
              </FormLabel>
              <Link as={RouterLink} to="/password-reset" fontSize="sm">
                Esqueceu a senha?
              </Link>
            </HStack>
            <InputGroup>
              <InputLeftAddon>
                <Icon as={MdLockOutline} />
              </InputLeftAddon>
              <Input
                type={isVisible ? "text" : "password"}
                value={formData.password ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, password: target.value }))}
              />
              <InputRightElement>
                <IconButton
                  variant="ghost"
                  colorScheme="main"
                  icon={<Icon as={isVisible ? MdOutlineVisibilityOff : MdOutlineVisibility} />}
                  onClick={() => setIsVisible((state) => !state)}
                />
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{formErrors.password}</FormErrorMessage>
          </FormControl>
          <Button type="submit" colorScheme="main" rightIcon={<Icon as={MdKeyboardArrowRight} />} isLoading={isLoading}>
            Entrar
          </Button>
        </VStack>
      </form>
    </Box>
  );
};
