import { useMemo } from "react";
import { useParams } from "react-router-dom";

const keys = {
  receivable: {
    documentTitle: "Recebimentos",
    newDocumentTitle: "Recebimento",
  },
  payable: {
    documentTitle: "Pagamentos",
    newDocumentTitle: "Pagamento",
  },
};

export const useCorrelationKeys = () => {
  const { type } = useParams();
  return useMemo(() => keys[type], [type]);
};
