import { statuses } from "consts";

export class Team {
  constructor(team) {
    this._id = team._id;
    this.title = team.title;
    this.quantity = 0;
    this.statuses = {};
    const _self = this;
    for (const { value } of statuses.tasks)
      this.statuses[value] = {
        quantity: 0,
        get percentage() {
          return this.quantity / _self.quantity;
        },
      };
  }
  incQuantity() {
    this.quantity += 1;
  }
  incStatusQuantity(status) {
    this.statuses[status].quantity += 1;
  }
}
