import React, { useEffect, useState } from "react";
import moment from "moment";
import { Box, Button, HStack, Icon, Menu, MenuButton, MenuItemOption, MenuList, MenuOptionGroup } from "@chakra-ui/react";
import { RangeDatePicker, Portal } from "components";
import { MdCalendarMonth, MdKeyboardArrowDown } from "react-icons/md";

const dateRanges = [
  { value: "current_month", label: "Mês atual" },
  { value: "last_month", label: "Mês passado" },
  { value: "custom_range", label: "Período personalizado" },
];

export const RangeDateSelect = ({ selected, onChange }) => {
  const [selectedDateRange, setSelectedDateRange] = useState(dateRanges.find((o) => o.value === "current_month"));

  useEffect(() => {
    switch (selectedDateRange.value) {
      case "current_month":
        onChange({
          startDate: moment().startOf("month").toDate(),
          endDate: moment().endOf("month").toDate(),
        });
        break;
      case "last_month":
        onChange({
          startDate: moment().startOf("month").subtract(1, "month").toDate(),
          endDate: moment().endOf("month").subtract(1, "month").toDate(),
        });
        break;
    }
  }, [selectedDateRange.value]);

  return (
    <HStack>
      {selectedDateRange.value === "custom_range" && (
        <Box>
          <RangeDatePicker
            propsConfigs={{ inputProps: { size: "sm" } }}
            selectedDates={[selected?.startDate, selected?.endDate]}
            onDateChange={([startDate, endDate]) => onChange((state) => ({ ...state, startDate, endDate }))}
          />
        </Box>
      )}
      <Box>
        <Menu>
          <MenuButton
            as={Button}
            size="sm"
            variant="outline"
            leftIcon={<Icon as={MdCalendarMonth} />}
            rightIcon={<Icon as={MdKeyboardArrowDown} />}
          >
            {selectedDateRange.label}
          </MenuButton>
          <Portal>
            <MenuList fontSize="sm">
              <MenuOptionGroup
                value={selectedDateRange.value}
                onChange={(value) => setSelectedDateRange(dateRanges.find((o) => o.value === value))}
              >
                {dateRanges.map((item) => (
                  <MenuItemOption key={item.value} value={item.value}>
                    {item.label}
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Portal>
        </Menu>
      </Box>
    </HStack>
  );
};
