import React, { useContext, useState, useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Divider,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Select,
  Spinner,
  StackDivider,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import moment from "moment";
import { api, translator } from "lib";
import { useCustomToast, useApiGet } from "hooks";
import { messages } from "consts";
import { MdRefresh, MdOutlineDelete, MdEdit } from "react-icons/md";
import { PiNotepadBold } from "react-icons/pi";
import Details from "./details";
import CustomersDetailsContext from "../context";
import { CustomBadge, PermissionedContainer } from "components";
import CustomerNotesContext from "./context";

const Notes = () => {
  const { _id } = useParams();
  const { setCounters, setIsLoadingCounters } = useContext(CustomersDetailsContext);
  const [query, setQuery] = useState({ status: "active" });
  const [notes, isLoadingNotes, refreshNotes] = useApiGet(
    useMemo(
      () => ({
        path: `/customers/${_id}/notes`,
        params: { query, sort: { createdAt: -1 }, perPage: -1 },
        options: { isEnabled: !!_id },
      }),
      [_id, query]
    )
  );
  const [editSelected, setEditSelected] = useState();
  const [deleteSelected, setDeleteSelected] = useState();
  const [isLoadingDeleteData, setIsLoadingDeleteData] = useState(false);
  const toast = useCustomToast();

  useEffect(() => {
    setCounters((state) => ({ ...state, notes: notes?.size ?? 0 }));
  }, [setCounters, notes?.size]);

  useEffect(() => {
    setIsLoadingCounters((state) => ({ ...state, notes: isLoadingNotes }));
  }, [setIsLoadingCounters, isLoadingNotes]);

  const handleDeleteData = useCallback(async () => {
    try {
      setIsLoadingDeleteData(true);
      await api.delete(`/customers/${_id}/notes/${deleteSelected._id}`);
      toast({ description: messages.success.deleteData, status: "success", isClosable: true });
      refreshNotes();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingDeleteData(false);
      setDeleteSelected();
    }
  }, [toast, deleteSelected?._id, refreshNotes]);

  return (
    <CustomerNotesContext.Provider value={{ editSelected, setEditSelected, refreshNotes }}>
      <HStack mb="20px">
        <Text flex="1" fontSize="sm" fontWeight="semibold">
          {notes?.size ?? 0} registros adicionados
        </Text>
        <Box>
          <Select
            size="sm"
            variant="filled"
            value={query.status || ""}
            onChange={({ target }) => setQuery((state) => ({ ...state, status: target.value || undefined }))}
          >
            <option value="">Todas</option>
            <option value="active">Ativas</option>
            <option value="finished">Finalizadas</option>
          </Select>
        </Box>
        <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingNotes} onClick={refreshNotes} />
        <PermissionedContainer required="customers:notes:create">
          <Tooltip placement="left" isDisabled={_id} shouldWrapChildren label="Antes de adicionar notas você deve salvar o cliente atual.">
            <Button size="sm" colorScheme="main" isDisabled={!_id} onClick={() => setEditSelected({})}>
              incluir registro
            </Button>
          </Tooltip>
        </PermissionedContainer>
      </HStack>

      <VStack align="stretch" divider={<StackDivider />} spacing={4}>
        {_.map(notes?.data, (item) => (
          <Box key={item._id} borderWidth="1px" borderRadius="lg">
            <HStack p="15px">
              <Box flex="1">
                <Heading size="sm">#{item.nid}</Heading>
                <Text fontSize="xs">{item._id}</Text>
              </Box>
              <PermissionedContainer required="customers:notes:update">
                <Button size="sm" variant="outline" rightIcon={<Icon as={MdEdit} />} onClick={() => setEditSelected(item)}>
                  editar
                </Button>
              </PermissionedContainer>
              <PermissionedContainer required="customers:notes:delete">
                <Button size="sm" variant="outline" rightIcon={<Icon as={MdOutlineDelete} />} onClick={() => setDeleteSelected(item)}>
                  excluir
                </Button>
              </PermissionedContainer>
            </HStack>
            <Divider />
            <Box p="15px">
              <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                <GridItem colSpan={{ base: 12, lg: 3 }}>
                  <Text fontSize="xs" fontWeight="semibold">
                    Status
                  </Text>
                  <CustomBadge.CustomerNotes.Status status={item.status} />
                </GridItem>
                <GridItem colSpan={{ base: 12, lg: 3 }}>
                  <Text fontSize="xs" fontWeight="semibold">
                    Time
                  </Text>
                  <Text fontSize="sm">{item.team?.title || "-"}</Text>
                </GridItem>
                <GridItem colSpan={{ base: 12, lg: 3 }}>
                  <Text fontSize="xs" fontWeight="semibold">
                    Módulo de destino
                  </Text>
                  <Text fontSize="sm">{translator(item.destModule)}</Text>
                </GridItem>
                <GridItem colSpan={{ base: 12, lg: 3 }}>
                  <Text fontSize="xs" fontWeight="semibold">
                    Tipo de ação
                  </Text>
                  <Text fontSize="sm">{translator(item.actionType)}</Text>
                </GridItem>

                <GridItem colSpan={{ base: 12, lg: 3 }}>
                  <Text fontSize="xs" fontWeight="semibold">
                    Criada por
                  </Text>
                  <Text fontSize="sm">{item.createdBy?.name}</Text>
                </GridItem>
                <GridItem colSpan={{ base: 12, lg: 3 }}>
                  <Text fontSize="xs" fontWeight="semibold">
                    Criada em
                  </Text>
                  <Text fontSize="sm">{moment(item.createdAt).format("DD/MM/YYYY [às] HH:mm")}</Text>
                </GridItem>
                <GridItem colSpan={{ base: 12, lg: 3 }}>
                  <Text fontSize="xs" fontWeight="semibold">
                    Finalizada por
                  </Text>
                  <Text fontSize="sm">{item.finishedBy?.name || "-"}</Text>
                </GridItem>
                <GridItem colSpan={{ base: 12, lg: 3 }}>
                  <Text fontSize="xs" fontWeight="semibold">
                    Finalizada em
                  </Text>
                  <Text fontSize="sm">{item.finishedAt ? moment(item.finishedAt).format("DD/MM/YYYY [às] HH:mm") : "-"}</Text>
                </GridItem>

                <GridItem colSpan={12}>
                  <Text fontSize="xs" fontWeight="semibold">
                    Conteúdo
                  </Text>
                  <Text fontSize="sm">{item.content}</Text>
                </GridItem>
              </Grid>
            </Box>
          </Box>
        ))}
      </VStack>

      {isLoadingNotes ? (
        <Center p="40px">
          <Spinner />
        </Center>
      ) : (
        _.size(notes?.data) === 0 && (
          <Center paddingTop="40px" paddingBottom="20px">
            <Box textAlign="center">
              <Icon as={PiNotepadBold} boxSize={20} marginBottom="10px" />
              <Text fontSize="lg" fontWeight="bold">
                Nenhuma nota adicionada
              </Text>
              <Text fontSize="sm">Este cliente ainda não possui notas adicionadas.</Text>
            </Box>
          </Center>
        )
      )}

      <Details />

      <AlertDialog isOpen={deleteSelected} onClose={() => setDeleteSelected()} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>Deseja realmente excluir a nota selecionada?</AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button size="sm" variant="outline" onClick={() => setDeleteSelected()}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="red" isLoading={isLoadingDeleteData} onClick={handleDeleteData}>
              excluir
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </CustomerNotesContext.Provider>
  );
};

export default Notes;
