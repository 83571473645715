import React, { createContext, memo, useContext, useMemo } from "react";
import { Box, Center, HStack, Spinner, StackDivider, Text, VStack } from "@chakra-ui/react";
import _ from "lodash";
import moment from "moment";

const ConsultantContext = createContext();

const Row = memo(({ date, cols, isToday }) => {
  const { renderItem, isLoading } = useContext(ConsultantContext);
  return (
    <HStack alignItems="stretch" divider={<StackDivider />}>
      <Box
        w="100px"
        minH="200px"
        position="sticky"
        left="0"
        zIndex="1"
        borderWidth="1px"
        borderRadius="lg"
        _light={{ bg: "gray.50" }}
        _dark={{ bg: "gray.900" }}
      >
        <Center position="sticky" top="0" h="140px">
          <HStack justifyContent="center" p="10px" spacing="4px" transform="rotate(-90deg)">
            <Text fontSize="lg">{moment(date).format("ddd")},</Text>
            <Center
              _light={{ bg: isToday ? "main.500" : "gray.100" }}
              _dark={{ bg: isToday ? "main.500" : "gray.700" }}
              borderRadius="full"
              w="35px"
              h="35px"
            >
              <Text fontSize="lg" color={isToday && "white"}>
                {moment(date).format("DD")}
              </Text>
            </Center>
            {isLoading && <Spinner size="xs" />}
          </HStack>
        </Center>
      </Box>
      {_.map(cols, (col) => (
        <Box key={col._id} as={VStack} w="200px" alignItems="stretch" spacing={1}>
          {_.map(col.items, renderItem)}
        </Box>
      ))}
    </HStack>
  );
});

export const GridViewerConsultant = ({ dateKey, colKey, startDate, endDate, data, renderItem, isLoading }) => {
  const cols = useMemo(() => {
    let cols;
    if (colKey === "responsible") cols = _(data).map("responsible");
    else cols = _(data).flatMap((o) => o.participants.map(({ user }) => user));
    return cols.uniqBy("_id").sortBy("name").value();
  }, [colKey, data]);
  const rows = useMemo(() => {
    const rows = [];
    const tmp = [...(data || [])];
    let date = moment(startDate);
    if (date.day() === 0 && date.date() !== moment(endDate).date()) date.add(1, "days");
    for (date; date.isBefore(endDate); date.add(1, "days")) {
      const items = _.remove(tmp, (o) => date.isSame(moment(o[dateKey]), "day"));
      const row = {
        date: moment(date).toDate(),
        isToday: date.isSame(moment(), "day"),
        cols: _.map(cols, (item) => {
          const col = { ...item };
          if (colKey === "responsible") col.items = _.filter(items, (o) => o.responsible._id === item._id);
          else col.items = _.filter(items, (o) => o.participants.findIndex((o) => o.user._id === item._id) !== -1);
          return col;
        }),
      };
      rows.push(row);
    }
    return rows;
  }, [dateKey, colKey, startDate, endDate, data, cols]);

  return (
    <ConsultantContext.Provider value={{ renderItem, isLoading }}>
      <Box display="inline-block" pr="15px">
        <HStack position="sticky" top="0" zIndex="1" divider={<StackDivider />}>
          <Box w="100px" mb={1}></Box>
          {_.map(cols, (col) => (
            <Box
              key={col._id}
              w="200px"
              p="10px"
              borderWidth="1px"
              borderRadius="lg"
              _light={{ bg: "gray.50" }}
              _dark={{ bg: "gray.900" }}
              mb={1}
            >
              <Text whiteSpace="nowrap" fontSize="xs" textAlign="center">
                {col.name}
              </Text>
            </Box>
          ))}
        </HStack>
        <VStack divider={<StackDivider />}>
          {rows.map((row) => (
            <Row key={row.date.toISOString()} {...row} />
          ))}
        </VStack>
      </Box>
    </ConsultantContext.Provider>
  );
};
