import { useCallback } from "react";
import { Box, Heading, HStack, Icon, IconButton, Text, useToast } from "@chakra-ui/react";
import { Lottie } from "components";
import { MdClose } from "react-icons/md";
import errorAnimationData from "assets/lottie/error.json";
import successAnimationData from "assets/lottie/success.json";
import warningAnimationData from "assets/lottie/warning.json";
import infoAnimationData from "assets/lottie/info.json";

const schemes = {
  success: {
    title: "Sucesso!",
    bg: "green.500",
    animationData: successAnimationData,
  },
  error: {
    title: "Oops!",
    bg: "red.500",
    animationData: errorAnimationData,
  },
  warning: {
    title: "Atenção!",
    bg: "orange.500",
    animationData: warningAnimationData,
  },
  info: {
    title: "Informação!",
    bg: "blue.500",
    animationData: infoAnimationData,
  },
};

const GenericToast = ({ status, title, description, isClosable, onClose }) => {
  return (
    <HStack color="white" p="10px" bg={schemes[status].bg} borderRadius="lg" position="relative">
      <Box w="40px" h="40px">
        <Lottie animationData={schemes[status].animationData} style={{ width: 40, height: 40 }} />
      </Box>
      <Box flex="1">
        {title?.length > 0 && <Heading size="xs">{title}</Heading>}
        {description?.length > 0 && (
          <Text fontSize="xs" fontWeight="semibold">
            {description}
          </Text>
        )}
      </Box>
      {isClosable && (
        <IconButton
          size="xs"
          right="2px"
          top="2px"
          position="absolute"
          variant="ghost"
          colorScheme="whiteAlpha"
          icon={<Icon as={MdClose} color="white" boxSize="15px" />}
          onClick={onClose}
        />
      )}
    </HStack>
  );
};

export const useCustomToast = () => {
  const toast = useToast();

  const response = useCallback(
    ({ status = "success", position = "bottom-right", render = (props) => <GenericToast {...props} />, ...props }) => {
      props.title = props.title ?? schemes[status].title;
      props.description = props.description ?? schemes[status].description;
      return toast({ status, position, render, ...props });
    },
    [toast]
  );

  response.isActive = toast.isActive;

  return response;
};
