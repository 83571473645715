import React, { useContext, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import _ from "lodash";
import * as yup from "yup";
import { api } from "lib";
import { useCustomToast } from "hooks";
import { messages } from "consts";
import { MdCheck, MdOutlineCancel } from "react-icons/md";
import SolicitationsDetailsContext from "./context";

const statuses = {
  authorized: {
    label: "autorizar",
    variant: "solid",
    colorScheme: "green",
    icon: MdCheck,
  },
  refused: {
    label: "recusar",
    variant: "ghost",
    colorScheme: "red",
    icon: MdOutlineCancel,
  },
};

const Progress = ({ status }) => {
  const { _id } = useParams();
  const { refreshData } = useContext(SolicitationsDetailsContext);
  const [formData, setFormData] = useState({ status });
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useCustomToast();

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = await api.patch(`/solicitations/${_id}/status`, data);
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
        onClose();
      } catch (error) {
        if (error.isHandled) return;
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, onClose]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({ notes: yup.string().required(messages.error.required) });
      await schema.validate(formData, { abortEarly: false });
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <>
      <Button
        size="sm"
        variant={statuses[status].variant}
        colorScheme={statuses[status].colorScheme}
        rightIcon={<Icon as={statuses[status].icon} />}
        isLoading={isLoadingSaveData}
        onClick={onOpen}
      >
        {statuses[status].label}
      </Button>
      <AlertDialog isOpen={isOpen} onClose={onClose} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>
            <Text>Deseja {statuses[status].label} a solicitação? Este é um procedimento irreversível.</Text>
            <Divider my={4} />
            <FormControl isRequired={true} isInvalid={formErrors.notes}>
              <FormLabel fontSize="sm">Notas</FormLabel>
              <Textarea
                value={formData.notes ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, notes: target.value }))}
              />
              <FormErrorMessage>{formErrors.notes}</FormErrorMessage>
            </FormControl>
          </AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button size="sm" variant="outline" onClick={onClose}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="main" isLoading={isLoadingSaveData} onClick={handleSubmit}>
              confirmar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default Progress;
