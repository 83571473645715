import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment-business-days";
import { useLocation } from "react-router-dom";
import {
  Box,
  Center,
  FormControl,
  Heading,
  HStack,
  Icon,
  IconButton,
  InputGroup,
  InputLeftAddon,
  Select,
  Spinner,
  Table,
  Text,
} from "@chakra-ui/react";
import { Breadcrumb } from "components";
import { useDocumentTitle, useStickyState } from "hooks";
import { Content, ContentBody, ContentHeader } from "pages/Private/Container";
import { api, db } from "lib";
import { MdRefresh } from "react-icons/md";
import Hierarchy from "./hierarchy";
import defaultRows from "./hierarchy/defaultRows";
import List from "./list";

export const RecordsContext = createContext();

export const Records = () => {
  useDocumentTitle("Painel de controle de cadastros");
  const location = useLocation();
  const [query] = useState({
    status: "finished",
    startDate: moment().startOf("month").subtract(12, "months").toDate(),
    endDate: moment().endOf("month").toDate(),
  });
  const [rows, setRows] = useStickyState(
    useMemo(
      () => ({
        key: location.pathname.concat("rows"),
        defaultValue: defaultRows.filter((o) => o.isActive),
        useCached: true,
        processor: (rows) => rows.map(({ key }) => defaultRows.find((o) => o.key === key)),
        _v: 1,
      }),
      [location.pathname]
    )
  );
  const [data, setData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [timestampData, setTimestampData] = useState(localStorage.getItem("activities-timestamp"));
  const months = useMemo(() => {
    const mapper = (subtract) => {
      const month = moment().startOf("month").subtract(subtract, "month");
      const businessDaysInMonth = month.monthBusinessDays().length;
      return {
        month: month.toDate(),
        monthText: month.format("MM/YY"),
        totalMinutesInMonth: businessDaysInMonth * 60 * 8,
        durationInMinutes: 0,
      };
    };
    return _.range(0, 13).map(mapper).reverse();
  }, []);
  const activities = useMemo(() => {
    const recursive = (rowIndex, activities) => {
      const response = {};
      try {
        for (const item of activities) {
          const processed = rows[rowIndex].processor(item);
          if (processed.length === 0) continue;
          const [records, value] = processed;
          for (const { _id, title, isEntity } of records) {
            if (_.has(response, _id) === false) {
              response[_id] = { _id, title, activities: [], months: {}, isEntity };
              for (const month of months) response[_id].months[month.monthText] = 0;
            }
            response[_id].months[value.monthText] += value.durationInMinutes;
            response[_id].activities.push(item);
          }
        }
        for (const key in response) {
          const values = Object.values(response[key].months);
          response[key].totalDurationInMinutes = _.sum(values);
          response[key].avgDurationInMinutes = response[key].totalDurationInMinutes / values.length;
          response[key].maxDurationInMinutes = _.max(values);
          response[key].activities = recursive(rowIndex + 1, response[key].activities);
        }
      } finally {
        return Object.values(response);
      }
    };
    return recursive(0, data);
  }, [rows, months, data]);
  const [deviationAnalysis, setDeviationAnalysis] = useStickyState(
    useMemo(() => ({ key: location.pathname.concat("deviationAnalysis"), defaultValue: "asc", _v: 1 }), [location.pathname])
  );
  const updatedAt = useMemo(() => localStorage.getItem("activities-timestamp"), [activities]);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      try {
        setIsLoadingData(true);
        const activities = await (async () => {
          if (_.isString(timestampData) && moment(parseInt(timestampData)).isAfter(moment().subtract(24, "hours"))) {
            const activities = await db.activities.toArray();
            if (activities.length >= 1) return activities;
          }
          await db.activities.clear();
          const promises = ["meetings", "tasks"].map((value) => api.fetchAllPaginatedData(`/dashboards/records/${value}`, { query }));
          const results = await Promise.all(promises);
          const response = _.flatten(results);
          await db.activities.bulkAdd(response);
          localStorage.setItem("activities-timestamp", Date.now());
          return response;
        })();
        setData(activities);
      } finally {
        setIsLoadingData(false);
      }
    }, 500);
    return () => clearTimeout(timeout);
  }, [query, timestampData]);

  const refreshActivities = useCallback(() => setTimestampData(Date.now()), []);

  return (
    <RecordsContext.Provider value={{ rows, setRows, months, deviationAnalysis }}>
      <Content>
        <ContentHeader>
          <HStack justify="space-between">
            <Breadcrumb items={[{ label: "painéis de controle" }, { to: "/dashboards/tasks", label: "atividades" }]} />
          </HStack>

          <HStack my="15px">
            <Box flex="1">
              <Heading size="md">Painel de controle de atividades</Heading>
              {updatedAt && <Text fontSize="xs">Atualizado em {moment(parseInt(updatedAt)).format("DD/MM/YYYY [às] HH:mm:ss")}</Text>}
            </Box>
            <Box>
              <FormControl>
                <InputGroup size="sm">
                  <InputLeftAddon>Análise de desvio</InputLeftAddon>
                  <Select size="sm" value={deviationAnalysis} onChange={({ target }) => setDeviationAnalysis(target.value)}>
                    <option value="asc">Ascendente</option>
                    <option value="desc">Descendente</option>
                  </Select>
                </InputGroup>
              </FormControl>
            </Box>
            <IconButton
              size="sm"
              variant="outline"
              icon={<Icon as={MdRefresh} />}
              fontSize="sm"
              isLoading={isLoadingData}
              onClick={refreshActivities}
            />
          </HStack>

          <Hierarchy />
        </ContentHeader>

        <ContentBody>
          <Table size="sm" fontSize="xs">
            <List data={activities} isParent={true} />
          </Table>
          {isLoadingData && (
            <Center p="40px">
              <Spinner />
            </Center>
          )}
        </ContentBody>
      </Content>
    </RecordsContext.Provider>
  );
};
