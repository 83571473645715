import React from "react";

const Icon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 0 9.992 9.991">
      <g id="Grupo_16" data-name="Grupo 16" transform="translate(-1852.765 -419.549)">
        <path
          id="Caminho_93"
          data-name="Caminho 93"
          d="M1862.34,428.707h-7.493a1.249,1.249,0,0,1-1.249-1.249v-7.493a.417.417,0,0,0-.833,0v7.493a2.086,2.086,0,0,0,2.082,2.082h7.493a.417.417,0,1,0,0-.833Z"
          fill="#cc7729"
        />
        <path
          id="Caminho_94"
          data-name="Caminho 94"
          d="M1855.263,427.875a.417.417,0,0,0,.417-.417v-2.914a.417.417,0,0,0-.833,0v2.914A.416.416,0,0,0,1855.263,427.875Z"
          fill="#cc7729"
        />
        <path
          id="Caminho_95"
          data-name="Caminho 95"
          d="M1856.928,423.712v3.746a.417.417,0,1,0,.833,0v-3.746a.417.417,0,1,0-.833,0Z"
          fill="#cc7729"
        />
        <path
          id="Caminho_96"
          data-name="Caminho 96"
          d="M1859.01,424.961v2.5a.417.417,0,1,0,.833,0v-2.5a.417.417,0,1,0-.833,0Z"
          fill="#cc7729"
        />
        <path
          id="Caminho_97"
          data-name="Caminho 97"
          d="M1861.091,423.3v4.163a.417.417,0,1,0,.833,0V423.3a.417.417,0,0,0-.833,0Z"
          fill="#cc7729"
        />
        <path
          id="Caminho_98"
          data-name="Caminho 98"
          d="M1855.263,423.3a.417.417,0,0,0,.3-.122l1.492-1.492a.425.425,0,0,1,.589,0l.9.9a1.25,1.25,0,0,0,1.766,0l2.326-2.326a.416.416,0,0,0-.589-.588L1859.721,422a.418.418,0,0,1-.589,0l-.9-.9a1.248,1.248,0,0,0-1.766,0l-1.493,1.493a.416.416,0,0,0,.294.71Z"
          fill="#cc7729"
        />
      </g>
    </svg>
  );
};

export default Icon;
