import React, { useState, useCallback, useEffect } from "react";
import {
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Text,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import _ from "lodash";
import * as yup from "yup";
import { messages } from "consts";
import { BoxData } from "components";
import { MdClose, MdEdit } from "react-icons/md";
import { BiEraser } from "react-icons/bi";

export const LatLng = ({ location, onChange }) => {
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setFormData({});
    setFormErrors({});
  }, [isOpen]);

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        location: yup
          .string()
          .required(messages.error.required)
          .matches(/^((\-?|\+?)?\d+(\.\d+)?),\s*((\-?|\+?)?\d+(\.\d+)?)$/gi, messages.error.invalidLatitudeLongitude),
      });
      await schema.validate(formData, { abortEarly: false });
      const [lat, lng] = formData.location.split(",").map((v) => parseFloat(v));
      onChange({ lat, lng });
      onClose();
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, onClose, onChange]);

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <BoxData label="Latitude" value={location?.lat ?? "-"} />
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <BoxData label="Longitude" value={location?.lng ?? "-"} />
        </GridItem>
      </Grid>
      <HStack mt={4} justifyContent="space-between">
        <Button size="sm" colorScheme="main" variant="ghost" leftIcon={<Icon as={MdEdit} />} onClick={onOpen}>
          editar localização
        </Button>
        <Button size="sm" variant="ghost" leftIcon={<Icon as={BiEraser} />} onClick={() => onChange(null)}>
          limpar localização
        </Button>
      </HStack>
      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader as={HStack}>
            <Text flex="1">Alterar localização</Text>
            <IconButton size="sm" variant="outline" icon={<Icon as={MdClose} />} onClick={onClose} />
          </ModalHeader>
          <Divider />
          <ModalBody py={4}>
            <Heading size="md" mb="5px">
              Obter as coordenadas de um lugar
            </Heading>
            <OrderedList fontSize="xs" mb="20px">
              <ListItem>
                No computador, abra o{" "}
                <Link href="https://www.google.com/maps" target="_blank" fontWeight="semibold">
                  Google Maps
                </Link>
                .
              </ListItem>
              <ListItem>
                Clique com o botão direito do mouse no local ou área no mapa.
                <UnorderedList>
                  <ListItem>
                    Isso abrirá uma janela pop-up. Você pode encontrar sua latitude e longitude em formato decimal na parte superior.
                  </ListItem>
                </UnorderedList>
              </ListItem>
              <ListItem>Para copiar as coordenadas automaticamente, clique com o botão esquerdo na latitude e longitude.</ListItem>
            </OrderedList>
            <FormControl isInvalid={formErrors.location}>
              <FormLabel fontSize="sm">Latitude, Longitude</FormLabel>
              <Input
                value={formData.location ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, location: target.value }))}
              />
              <FormErrorMessage>{formErrors.location}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <Divider />
          <ModalFooter as={HStack} justifyContent="flex-end">
            <Button size="sm" variant="outline" onClick={onClose}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="main" onClick={handleSubmit}>
              alterar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
