import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Center,
  Heading,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { MdRefresh } from "react-icons/md";
import { Breadcrumb, CustomBadge, TableEmpty } from "components";
import { useApiGet, useCustomToast, useDocumentTitle } from "hooks";
import { api } from "lib";
import { messages } from "consts";
import { Content } from "pages/Private/Container";
import { HiPlay } from "react-icons/hi";
import { LuListChecks } from "react-icons/lu";
import ReactJson from "react-json-view";

export const CronsList = () => {
  useDocumentTitle("Crons");
  const navigate = useNavigate();
  const [data, isLoadingData, refreshData] = useApiGet(useMemo(() => ({ path: "/crons" }), []));
  const [crons, setCrons] = useState({});
  const toast = useCustomToast();

  useEffect(() => {
    setCrons(data || []);
  }, [data]);

  const handleExecute = useCallback(
    async (topic) => {
      try {
        setCrons((state) => {
          const tmp = [...state];
          const index = tmp.findIndex((o) => o.topic === topic);
          tmp[index].isLoading = true;
          return tmp;
        });
        await api.put(`/crons/${topic}`, data);
        toast({ description: messages.success.executedCron, status: "success", isClosable: true });
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        refreshData();
      }
    },
    [refreshData]
  );

  return (
    <Content>
      <HStack justify="space-between">
        <Breadcrumb items={[{ label: "configurações" }, { to: "/settings/records/crons", label: "crons" }]} />
      </HStack>

      <HStack my="15px" justifyContent="space-between">
        <Heading size="md">Crons ({_.size(crons)})</Heading>
        <IconButton variant="outline" icon={<Icon as={MdRefresh} />} fontSize="sm" isLoading={isLoadingData} onClick={refreshData} />
      </HStack>

      <Table size="sm" whiteSpace="nowrap">
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Tópico</Th>
            <Th>Intervalo</Th>
            <Th>Status últ. exec.</Th>
            <Th>Início últ. exec.</Th>
            <Th>Fim últ. exec.</Th>
            <Th>Duração últ. exec.</Th>
            <Th>Criador últ. exec.</Th>
            <Th>#</Th>
          </Tr>
        </Thead>
        <Tbody>
          {_.map(crons, (item) => (
            <Tr
              key={item.topic}
              cursor="pointer"
              _hover={{ _light: { bg: "gray.50" }, _dark: { bg: "gray.700" } }}
              _active={{ _light: { bg: "gray.100" }, _dark: { bg: "gray.900" } }}
              onDoubleClick={() => navigate(`detailing/${item.topic}`)}
            >
              <Td>
                <IconButton
                  size="xs"
                  colorScheme="green"
                  icon={<Icon as={HiPlay} boxSize="15px" />}
                  isLoading={item.isLoading}
                  onClick={handleExecute.bind(this, item.topic)}
                  isRound={true}
                />
              </Td>
              <Td>{item.topic}</Td>
              <Td>{item.interval}</Td>
              <Td>
                <CustomBadge.Crons.Status status={item.status || "-"} />
              </Td>
              <Td>{item.startedAt ? moment(item.startedAt).format("DD/MM/YYYY [às] HH:mm") : "-"}</Td>
              <Td>{item.finishedAt ? moment(item.finishedAt).format("DD/MM/YYYY [às] HH:mm") : "-"}</Td>
              <Td>{item.durationInSeconds ? item.durationInSeconds.toLocaleString().concat(" segundos") : "-"}</Td>
              <Td>{item.createdBy?.name || "-"}</Td>
              <Td>
                <Popover placement="left">
                  <PopoverTrigger>
                    <Button size="xs" variant="outline" leftIcon={<Icon as={LuListChecks} />}>
                      log de execução
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent onClick={(e) => e.stopPropagation()}>
                    <PopoverArrow />
                    {_.isObject(item.err) && (
                      <PopoverHeader>
                        <Alert status="error" p="10px" borderRadius="lg">
                          <AlertIcon />
                          <AlertDescription fontSize="xs">{item.err.message}</AlertDescription>
                        </Alert>
                      </PopoverHeader>
                    )}
                    <PopoverBody>
                      <ReactJson src={item.data} name={false} />
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {isLoadingData && (
        <Center p="30px">
          <Spinner />
        </Center>
      )}
      <TableEmpty isLoading={isLoadingData} size={_.size(data)} hasNewButton={false} />
    </Content>
  );
};
