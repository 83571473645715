const Icon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 0 10.118 9.995">
      <g id="_01_align_center" data-name=" 01 align center" transform="translate(-1852.702 -355.404)">
        <path
          id="Caminho_86"
          data-name="Caminho 86"
          d="M1858.325,355.62a.844.844,0,0,0-1.128,0l-4.495,4.046v4.384a1.349,1.349,0,0,0,1.349,1.349h7.42a1.349,1.349,0,0,0,1.349-1.349h0v-4.384Zm.7,8.935h-2.53v-2.119a1.265,1.265,0,1,1,2.53,0Zm2.951-.506a.507.507,0,0,1-.506.506h-1.6v-2.119a2.108,2.108,0,0,0-4.216,0v2.119h-1.6a.507.507,0,0,1-.506-.506h0v-4.008l4.216-3.794,4.216,3.794Z"
          fill="#cc7729"
        />
      </g>
    </svg>
  );
};

export default Icon;
