import React, { createContext, useState, useCallback, useContext } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import { currency } from "lib";
import { MdAddCircleOutline, MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import { FiMapPin } from "react-icons/fi";
import Details from "./details";
import CustomersDetailsContext from "../../context";

export const AddressesContext = createContext();

const Addresses = () => {
  const { formData, setFormData } = useContext(CustomersDetailsContext);
  const [editSelected, setEditSelected] = useState(null);
  const [deleteSelected, setDeleteSelected] = useState(null);

  const handleDeleteData = useCallback(async () => {
    setFormData((state) => {
      const addresses = [...(state?.addresses ?? [])];
      const index = addresses.findIndex((o) => o._id === deleteSelected._id);
      addresses.splice(index, 1);
      return { ...state, addresses };
    });
    setDeleteSelected();
  }, [deleteSelected]);

  return (
    <AddressesContext.Provider value={{ editSelected, setEditSelected }}>
      <Heading size="sm" mb={6}>
        Endereços
      </Heading>
      <VStack align="stretch" divider={<StackDivider />}>
        {_.map(formData.addresses, (item) => (
          <Grid key={item._id} templateColumns="repeat(12, 1fr)" gap={4} py="10px">
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <Text fontSize="sm" fontWeight="semibold">
                {item.title}
              </Text>
              <Text fontSize="sm">
                {item.line1} - {item.line2}
              </Text>
              <Text fontSize="xs">
                {currency.format(item.travelCostAmount)} • {item.travelDurationInMinutes} minutos
              </Text>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <HStack justifyContent="flex-end">
                <Button
                  flex={{ base: "1", lg: "none" }}
                  variant="outline"
                  rightIcon={<Icon as={MdOutlineEdit} />}
                  onClick={() => setEditSelected(item)}
                >
                  editar
                </Button>
                <Button
                  flex={{ base: "1", lg: "none" }}
                  variant="outline"
                  rightIcon={<Icon as={MdOutlineDelete} />}
                  onClick={() => setDeleteSelected(item)}
                >
                  deletar
                </Button>
              </HStack>
            </GridItem>
          </Grid>
        ))}
      </VStack>

      {_.size(formData.addresses) === 0 && (
        <Center paddingTop="40px" paddingBottom="20px">
          <Box textAlign="center">
            <Icon as={FiMapPin} boxSize={20} marginBottom="10px" />
            <Text fontSize="lg" fontWeight="bold">
              Nenhum endereço adicionado
            </Text>
            <Text fontSize="sm">Este cliente ainda não possui endereços adicionados.</Text>
          </Box>
        </Center>
      )}

      <Button
        size="sm"
        variant="ghost"
        colorScheme="main"
        mt={4}
        leftIcon={<Icon as={MdAddCircleOutline} />}
        onClick={() => setEditSelected({})}
      >
        adicionar endereço
      </Button>

      <Details />

      <AlertDialog isOpen={deleteSelected} onClose={() => setDeleteSelected()} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>Deseja realmente excluir o registro selecionado?</AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button onClick={() => setDeleteSelected()}>Cancelar</Button>
            <Button colorScheme="red" onClick={handleDeleteData}>
              Excluir
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </AddressesContext.Provider>
  );
};

export default Addresses;
