import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { Box, Center, HStack, Icon, Button, Text, useDisclosure, IconButton, Tooltip } from "@chakra-ui/react";
import { FileList, FileUpload } from "components";
import { MdAttachFile, MdRefresh } from "react-icons/md";
import { FaRegFolderOpen } from "react-icons/fa";
import CustomerIndicatorsDetailsContext from "../context";
import { PermissionedContainer } from "components";

const Files = () => {
  const { _id } = useParams();
  const { formData, files, isLoadingFiles, refreshFiles } = useContext(CustomerIndicatorsDetailsContext);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <HStack mb="20px">
        <Text fontSize="sm" fontWeight="semibold" flex="1">
          {_.size(files?.data)} arquivos adicionados
        </Text>
        <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingFiles} onClick={refreshFiles} />
        {!formData.isClosed && (
          <PermissionedContainer required="customer-indicators:update">
            <Tooltip
              placement="left"
              isDisabled={_id}
              shouldWrapChildren
              label="Antes de adicionar arquivos você deve salvar o cliente atual."
            >
              <Button size="sm" colorScheme="main" rightIcon={<Icon as={MdAttachFile} />} isDisabled={!_id} onClick={onOpen}>
                incluir arquivos
              </Button>
            </Tooltip>
          </PermissionedContainer>
        )}
      </HStack>

      <FileList path="/files" data={files?.data} onChange={refreshFiles} isEditable={!formData.isClosed} isDeletable={!formData.isClosed} />

      {_.size(files?.data) === 0 && (
        <Center paddingTop="40px" paddingBottom="20px">
          <Box textAlign="center">
            <Icon as={FaRegFolderOpen} boxSize={20} marginBottom="10px" />
            <Text fontSize="lg" fontWeight="bold">
              Nenhum arquivo adicionado
            </Text>
            <Text fontSize="sm">Você ainda não adicionou arquivos neste cliente.</Text>
          </Box>
        </Center>
      )}

      <FileUpload path="/files" ownerRef="Customer" owner={_id} isOpen={isOpen} onClose={onClose} onChange={refreshFiles} />
    </>
  );
};

export default Files;
