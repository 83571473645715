import { memo, useMemo } from "react";
import { Badge } from "@chakra-ui/react";
import { translator } from "lib";
import { priorities } from "consts";

export const Priority = memo(({ priority, ...props }) => {
  const colorScheme = useMemo(() => {
    try {
      const finded = priorities.dashboardsScheduling.find((o) => o.value === priority);
      const [colorScheme] = finded.color.split(".");
      return colorScheme;
    } catch (error) {
      return "gray";
    }
  }, [priority]);

  return (
    <Badge colorScheme={colorScheme} {...props}>
      {translator(priority)}
    </Badge>
  );
});
