import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import moment from "moment";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { api, masks, translator } from "lib";
import { statuses } from "consts";
import { useCacheState, useNewTabState, useStickyState } from "hooks";
import { AsyncSelect, CustomerLogo, RangeDatePicker, SyncSelect } from "components";
import { MdClose, MdRefresh, MdSearch } from "react-icons/md";
import { BiCog } from "react-icons/bi";
import { HiOutlineFilter } from "react-icons/hi";

let loadCustomersTimeout, loadTeamsTimeout, loadUsersTimeout;
let loadDemandsTimeout = {};

const defaultValue = { status: statuses.tasks.filter((o) => ["unscheduled", "pending", "started", "paused"].indexOf(o.value) !== -1) };

const Filters = ({ onQuery, onRefresh, onPage, isLoading, onTableDrawer }) => {
  const location = useLocation();
  const newTabState = useNewTabState();
  const [query, setQuery] = useStickyState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.query"),
        defaultValue: newTabState ?? location.state ?? defaultValue,
        useCached: _.isObject(newTabState) === false && _.isObject(location.state) === false,
        _v: 2,
        processor: (data) => ({
          ...data,
          dueDateStartDate: data.dueDateStartDate && moment(data.dueDateStartDate).toDate(),
          dueDateEndDate: data.dueDateEndDate && moment(data.dueDateEndDate).toDate(),
          startedAtStartDate: data.startedAtStartDate && moment(data.startedAtStartDate).toDate(),
          startedAtEndDate: data.startedAtEndDate && moment(data.startedAtEndDate).toDate(),
          finishedAtStartDate: data.finishedAtStartDate && moment(data.finishedAtStartDate).toDate(),
          finishedAtEndDate: data.finishedAtEndDate && moment(data.finishedAtEndDate).toDate(),
          createdAtStartDate: data.createdAtStartDate && moment(data.createdAtStartDate).toDate(),
          createdAtEndDate: data.createdAtEndDate && moment(data.createdAtEndDate).toDate(),
        }),
      }),
      [location.pathname, location.state, newTabState]
    )
  );
  const [isOpen, setIsOpen] = useCacheState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.isOpen"),
        defaultValue: false,
      }),
      []
    )
  );
  const [formData, setFormData] = useState(query);
  const [isFiltering, setIsFiltering] = useState(false);
  const backgroundColor = useColorModeValue("gray.50", "blackAlpha.300");
  const containerProps = useMemo(
    () => isOpen && { padding: { base: "10px", lg: "20px" }, backgroundColor, marginBottom: 8 },
    [isOpen, backgroundColor]
  );

  useEffect(() => {
    const response = {};
    const toObjectId = (data) => _.map(data, (o) => ["@ObjectId", o._id]);
    if (query.nid?.length) _.set(response, `nid`, parseInt(query.nid));
    if (query.status?.length) _.set(response, `status.$in`, _.map(query.status, "value"));
    if (query.customer?._id) _.set(response, `["customer._id"]`, ["@ObjectId", query.customer._id]);
    if (query.customer?.length) _.set(response, `["customer._id"].$in`, toObjectId(query.customer));
    if (query.team?.length) _.set(response, `["team._id"].$in`, toObjectId(query.team));
    if (query.responsible?.length) _.set(response, `["responsible._id"].$in`, toObjectId(query.responsible));
    if (query.demand?.length) _.set(response, `["demand._id"].$in`, toObjectId(query.demand));
    if (query.demandExcept?.length) _.set(response, `["demand._id"].$nin`, toObjectId(query.demandExcept));
    if (query.dueDateStartDate) _.set(response, `dueDate.$gte`, ["@ISODate", query.dueDateStartDate]);
    if (query.dueDateEndDate) _.set(response, `dueDate.$lte`, ["@ISODate", query.dueDateEndDate]);
    if (query.startedAtStartDate) _.set(response, `startedAt.$gte`, ["@ISODate", query.startedAtStartDate]);
    if (query.startedAtEndDate) _.set(response, `startedAt.$lte`, ["@ISODate", query.startedAtEndDate]);
    if (query.finishedAtStartDate) _.set(response, `finishedAt.$gte`, ["@ISODate", query.finishedAtStartDate]);
    if (query.finishedAtEndDate) _.set(response, `finishedAt.$lte`, ["@ISODate", query.finishedAtEndDate]);
    if (query.createdAtStartDate) _.set(response, `createdAt.$gte`, ["@ISODate", query.createdAtStartDate]);
    if (query.createdAtEndDate) _.set(response, `createdAt.$lte`, ["@ISODate", query.createdAtEndDate]);
    if (query.isDelayed?.length) _.set(response, `isDelayed`, query.isDelayed === "yes");
    setIsFiltering(Object.keys(response).length > 0);
    onQuery(response);
    onPage(0);
  }, [onQuery, onPage, query]);

  const handleSubmit = useCallback(() => {
    setQuery(formData);
  }, [setQuery, formData]);

  const handleClean = useCallback(() => {
    setQuery({});
    setFormData({});
  }, [setQuery, setFormData]);

  const handleLoadCustomers = useCallback((search, cb) => {
    clearTimeout(loadCustomersTimeout);
    loadCustomersTimeout = setTimeout(async () => {
      const params = {
        search,
        query: { status: { $ne: "inactive" } },
        sort: { tradingName: 1 },
        perPage: 20,
        isAutocomplete: true,
      };
      const response = await api.post("/customers", params);
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleLoadTeams = useCallback((search, cb) => {
    clearTimeout(loadTeamsTimeout);
    loadTeamsTimeout = setTimeout(async () => {
      const response = await api.post("/teams", {
        search,
        query: { isActive: true },
        sort: { title: 1 },
        perPage: 20,
        isAutocomplete: true,
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleLoadUsers = useCallback((search, cb) => {
    clearTimeout(loadUsersTimeout);
    loadUsersTimeout = setTimeout(async () => {
      const response = await api.post("/users", {
        search,
        query: { isActive: true },
        sort: { name: 1 },
        perPage: 20,
        isAutocomplete: true,
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleLoadDemands = useCallback((key, search, cb) => {
    clearTimeout(loadDemandsTimeout[key]);
    loadDemandsTimeout[key] = setTimeout(async () => {
      const response = await api.post("/demands", {
        search,
        query: { isActive: true },
        sort: { title: 1 },
        perPage: 20,
        isAutocomplete: true,
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  return (
    <Box {...containerProps} borderRadius="lg" transition="400ms">
      <Grid templateColumns="repeat(12,1fr)" gap={2} mb={8}>
        <GridItem as={HStack} justifyContent="space-between" colSpan={{ base: 12, lg: 12 }}>
          <HStack>
            <Button
              variant="outline"
              colorScheme={isFiltering ? "main" : "gray"}
              rightIcon={<Icon as={HiOutlineFilter} />}
              onClick={() => setIsOpen((state) => !state)}
            >
              filtros
            </Button>
            {isFiltering && (
              <Button variant="outline" rightIcon={<Icon as={MdClose} />} onClick={handleClean}>
                limpar filtros
              </Button>
            )}
          </HStack>
          <HStack>
            <IconButton variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoading} onClick={onRefresh} />
            <IconButton variant="outline" icon={<Icon as={BiCog} />} onClick={onTableDrawer} />
          </HStack>
        </GridItem>
      </Grid>
      {isOpen && (
        <>
          <Grid templateColumns="repeat(12, 1fr)" gap={2} mb={4}>
            <GridItem colSpan={{ base: 12, lg: 1 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  NID
                </FormLabel>
                <Input
                  size="sm"
                  variant="filled"
                  value={formData.nid ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, nid: target.value }))}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Status
                </FormLabel>
                <SyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.status ?? []}
                  placeholder="Selecione"
                  options={statuses.tasks}
                  onChange={(status) => setFormData((state) => ({ ...state, status }))}
                  formatOptionLabel={({ color, value }) => (
                    <HStack>
                      <Box bg={color} w="10px" h="10px" borderRadius="full" />
                      <Text>{translator(value)}</Text>
                    </HStack>
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Cliente
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.customer ?? []}
                  defaultOptions
                  loadOptions={handleLoadCustomers}
                  placeholder="Selecione"
                  onChange={(customer) => setFormData((state) => ({ ...state, customer }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ logoUrl, tradingName, segment }) => (
                    <HStack>
                      <CustomerLogo alt={tradingName} src={logoUrl} boxSize="35px" />
                      <Box flex="1">
                        <Text fontSize="sm" noOfLines={1}>
                          {tradingName}
                        </Text>
                        <Text fontSize="xs">{segment?.title || "-"}</Text>
                      </Box>
                    </HStack>
                  )}
                  isDisabled={_.isObject(location.state?.customer)}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Time
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.team ?? []}
                  defaultOptions
                  loadOptions={handleLoadTeams}
                  placeholder="Selecione"
                  onChange={(team) => setFormData((state) => ({ ...state, team }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ title }) => title}
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 12 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Responsáveis
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.responsible ?? []}
                  defaultOptions
                  loadOptions={handleLoadUsers}
                  placeholder="Selecione"
                  onChange={(responsible) => setFormData((state) => ({ ...state, responsible }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ avatarUrl, name }) => (
                    <HStack>
                      <Avatar size="xs" name={name} src={avatarUrl} />
                      <Text>{name}</Text>
                    </HStack>
                  )}
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Demandas
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.demand ?? []}
                  defaultOptions
                  loadOptions={(search, cb) => handleLoadDemands("demand", search, cb)}
                  placeholder="Selecione"
                  onChange={(demand) => setFormData((state) => ({ ...state, demand }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ title }) => title}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Demandas (exceto)
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.demandExcept ?? []}
                  defaultOptions
                  loadOptions={(search, cb) => handleLoadDemands("demandExcept", search, cb)}
                  placeholder="Selecione"
                  onChange={(demandExcept) => setFormData((state) => ({ ...state, demandExcept }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ title }) => title}
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Atrasado?
                </FormLabel>
                <Select
                  size="sm"
                  variant="filled"
                  value={formData.isDelayed ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, isDelayed: target.value }))}
                >
                  <option value="">Todos</option>
                  <option value="yes">Sim</option>
                  <option value="no">Não</option>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Vencimento
                </FormLabel>
                <HStack>
                  <RangeDatePicker
                    key={formData.dueDateStartDateTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    selectedDates={[formData.dueDateStartDate, formData.dueDateEndDate]}
                    onDateChange={([dueDateStartDate, dueDateEndDate]) =>
                      setFormData((state) => ({ ...state, dueDateStartDate, dueDateEndDate }))
                    }
                  />
                  {formData.dueDateStartDate && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          dueDateStartDate: null,
                          dueDateEndDate: null,
                          dueDateStartDateTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Iniciada em
                </FormLabel>
                <HStack>
                  <RangeDatePicker
                    key={formData.startedAtStartDateTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    selectedDates={[formData.startedAtStartDate, formData.startedAtEndDate]}
                    onDateChange={([startedAtStartDate, startedAtEndDate]) =>
                      setFormData((state) => ({ ...state, startedAtStartDate, startedAtEndDate }))
                    }
                  />
                  {formData.startedAtStartDate && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          startedAtStartDate: null,
                          startedAtEndDate: null,
                          startedAtStartDateTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Finalizada em
                </FormLabel>
                <HStack>
                  <RangeDatePicker
                    key={formData.finishedAtStartDateTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    selectedDates={[formData.finishedAtStartDate, formData.finishedAtEndDate]}
                    onDateChange={([finishedAtStartDate, finishedAtEndDate]) =>
                      setFormData((state) => ({ ...state, finishedAtStartDate, finishedAtEndDate }))
                    }
                  />
                  {formData.finishedAtStartDate && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          finishedAtStartDate: null,
                          finishedAtEndDate: null,
                          finishedAtStartDateTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Criado em
                </FormLabel>
                <HStack>
                  <RangeDatePicker
                    key={formData.createdAtStartDateTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    selectedDates={[formData.createdAtStartDate, formData.createdAtEndDate]}
                    onDateChange={([createdAtStartDate, createdAtEndDate]) =>
                      setFormData((state) => ({ ...state, createdAtStartDate, createdAtEndDate }))
                    }
                  />
                  {formData.createdAtStartDate && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          createdAtStartDate: null,
                          createdAtEndDate: null,
                          createdAtStartDateTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>
          </Grid>
          <HStack justifyContent="flex-end">
            <Button size="sm" colorScheme="main" rightIcon={<Icon as={MdSearch} />} isLoading={isLoading} onClick={handleSubmit}>
              aplicar
            </Button>
          </HStack>
        </>
      )}
    </Box>
  );
};

export default Filters;
