import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "consts";
import moment from "moment";
import "moment/locale/pt-br";
import { initializeApp } from "firebase/app";
import AppProvider from "./AppProvider";
import "./style.css";

moment.locale("pt-br");

initializeApp({
  apiKey: "AIzaSyDjJRpSPCA29-kW5xp0XZp6d-keDuYLt6Y",
  authDomain: "formatar-c0b44.firebaseapp.com",
  projectId: "formatar-c0b44",
  storageBucket: "formatar-c0b44.appspot.com",
  messagingSenderId: "1009304832364",
  appId: "1:1009304832364:web:ac27f46136d2f2f9024b49",
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ChakraProvider theme={theme}>
    <AppProvider />
  </ChakraProvider>
);
