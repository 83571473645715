import { Box, Image } from "@chakra-ui/react";
import ImgFallbackEmpty from "assets/imgs/empty.jpg";

export const CustomerLogo = ({ alt, src, boxSize = "60px" }) => {
  return (
    <Box w={boxSize}>
      <Image boxSize={boxSize} objectFit="cover" alt={alt} src={src} fallbackSrc={ImgFallbackEmpty} borderWidth="1px" borderRadius="md" />
    </Box>
  );
};
