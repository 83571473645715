import React from "react";

const Icon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 0 10.118 10.118">
      <g id="Grupo_15" data-name="Grupo 15" transform="translate(-1852.702 -404.894)">
        <path
          id="Caminho_90"
          data-name="Caminho 90"
          d="M1862.4,409.532h-.843v-.844a.422.422,0,0,0-.843,0v.844h-.843a.421.421,0,1,0,0,.843h.843v.843a.422.422,0,0,0,.843,0v-.843h.843a.421.421,0,1,0,0-.843Z"
          fill="#cc7729"
        />
        <path
          id="Caminho_91"
          data-name="Caminho 91"
          d="M1856.5,409.953a2.529,2.529,0,1,0-2.529-2.529A2.53,2.53,0,0,0,1856.5,409.953Zm0-4.216a1.686,1.686,0,1,1-1.686,1.687A1.687,1.687,0,0,1,1856.5,405.737Z"
          fill="#cc7729"
        />
        <path
          id="Caminho_92"
          data-name="Caminho 92"
          d="M1856.5,410.8a3.8,3.8,0,0,0-3.794,3.8.422.422,0,0,0,.843,0,2.951,2.951,0,1,1,5.9,0,.422.422,0,0,0,.844,0A3.8,3.8,0,0,0,1856.5,410.8Z"
          fill="#cc7729"
        />
      </g>
    </svg>
  );
};

export default Icon;
