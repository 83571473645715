import { memo, useMemo } from "react";
import { Badge } from "@chakra-ui/react";
import { translator } from "lib";

export const Status = memo(({ status }) => {
  const colorScheme = useMemo(() => {
    switch (status) {
      case "pending":
        return "yellow";
      case "authorized":
        return "green";
      case "refused":
        return "red";
      default:
        return "gray";
    }
  }, [status]);
  return <Badge colorScheme={colorScheme}>{translator(status)}</Badge>;
});
