import React, { memo, useCallback, useState } from "react";
import { Image, Spinner, Center, Icon, Box, Modal, ModalOverlay, ModalContent } from "@chakra-ui/react";
import { api } from "lib";
import { useCustomToast } from "hooks";
import { BsFile } from "react-icons/bs";
import icons from "./icons";

export const FileImage = memo(({ path, _id, title, type, location, ext, ...props }) => {
  const [imageSrc, setImageSrc] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useCustomToast();

  const handleOpen = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await api({
        method: "post",
        url: `${path}/${_id}/object`,
        responseType: "blob",
      });
      setImageSrc(URL.createObjectURL(data));
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoading(false);
    }
  }, [path, _id]);

  if (/^image/i.test(type))
    return (
      <>
        <Box position="relative" overflow="hidden" borderRadius="md">
          <Image
            src={location}
            objectFit="cover"
            alt={title}
            fallback={
              <Center py={6}>
                <Spinner size="sm" />
              </Center>
            }
            onClick={handleOpen}
            cursor="pointer"
            {...props}
          />
          {isLoading && (
            <Center w="100%" h="100%" top="0" position="absolute" bg="blackAlpha.500" zIndex="1">
              <Spinner color="white" />
            </Center>
          )}
        </Box>
        <Modal isOpen={imageSrc} isCentered onClose={() => setImageSrc()}>
          <ModalOverlay />
          <ModalContent>
            <Image src={imageSrc} alt={title} borderRadius="md" />
          </ModalContent>
        </Modal>
      </>
    );
  return <Icon as={icons[ext] ?? BsFile} {...props} />;
});
