import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Button, Grid, GridItem, HStack, Icon, VStack } from "@chakra-ui/react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdCheck } from "react-icons/md";
import { RecordsContext } from "..";
import ActiveItem from "./activeItem";
import InactiveItem from "./inactiveItem";
import defaultRows from "./defaultRows";

const Hierarchy = () => {
  const { rows, setRows } = useContext(RecordsContext);
  const [actives, setActives] = useState([]);
  const inactives = useMemo(() => {
    const actvs = actives.map((o) => o.key);
    return defaultRows.filter((o) => actvs.indexOf(o.key) === -1);
  }, [actives]);
  const [isLoadingApply, setIsLoadingApply] = useState(false);

  useEffect(() => {
    setActives(rows);
  }, [rows]);

  const onDragEnd = useCallback((result) => {
    if (!result.destination) return;
    setActives((state) => {
      const rows = [...state];
      const [removed] = rows.splice(result.source.index, 1);
      rows.splice(result.destination.index, 0, removed);
      return rows;
    });
  }, []);

  const handleAdd = useCallback((item) => {
    setActives((state) => {
      const tmp = [...state];
      tmp.push(item);
      return tmp;
    });
  }, []);

  const handleRemove = useCallback((index) => {
    setActives((state) => {
      const tmp = [...state];
      tmp.splice(index, 1);
      return tmp;
    });
  }, []);

  const handleApply = useCallback(() => {
    setIsLoadingApply(true);
    setRows(actives);
    setTimeout(() => setIsLoadingApply(false), 1000);
  }, [actives]);

  return (
    <Grid templateColumns="repeat(12, 1fr)" gap={2}>
      <GridItem colSpan={{ base: 12, lg: 6 }} p="15px" borderWidth="1px" borderRadius="lg">
        <VStack align="stretch" spacing={1}>
          {inactives.map((item) => (
            <InactiveItem key={item.key} title={item.title} onAdd={handleAdd.bind(this, item)} />
          ))}
        </VStack>
      </GridItem>
      <GridItem colSpan={{ base: 12, lg: 6 }} p="15px" borderWidth="1px" borderRadius="lg">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(provided, snapshot) => (
              <VStack {...provided.droppableProps} ref={provided.innerRef} align="stretch" spacing={1}>
                {_.map(actives, (item, index) => (
                  <Draggable key={item.key} draggableId={item.key} {...{ index }}>
                    {(provided, snapshot) => (
                      <ActiveItem {...{ provided, snapshot, index }} title={item.title} onRemove={handleRemove.bind(this, index)} />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </VStack>
            )}
          </Droppable>
        </DragDropContext>
      </GridItem>
      <GridItem colSpan={12} as={HStack} justifyContent="flex-end">
        <Button size="sm" variant="outline" onClick={() => setActives(defaultRows.filter((o) => o.isActive))}>
          resetar
        </Button>
        <Button size="sm" colorScheme="main" leftIcon={<Icon as={MdCheck} />} isLoading={isLoadingApply} onClick={handleApply}>
          aplicar
        </Button>
      </GridItem>
    </Grid>
  );
};

export default Hierarchy;
