import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  VisuallyHidden,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import { csvDownload } from "lib";
import { messages } from "consts";
import { IndicatorValue } from "components";
import { translator } from "lib";
import papaparse from "papaparse";
import { MdAttachFile, MdClose, MdDeleteOutline, MdDownload, MdOutlineCloudUpload } from "react-icons/md";
import CustomerIndicatorsDetailsContext from "../context";

const parse = (file) => {
  return new Promise((resolve) => {
    papaparse.parse(file, {
      header: true,
      skipEmptyLines: true,
      delimiter: ";",
      complete: resolve,
    });
  });
};

const getValue = ({ type }, value) => {
  let num = value.replace("R$", "").replace("%", "").replaceAll(".", "").replaceAll(",", ".");
  switch (type) {
    case "textual":
      return value;
    case "numeric":
    case "monetary":
      return parseFloat(num);
    default:
      return parseFloat(num) / 100;
  }
};

const Import = ({ isOpen, onClose }) => {
  const { setFormData } = useContext(CustomerIndicatorsDetailsContext);
  const { indicatorTypes } = useContext(CustomerIndicatorsDetailsContext);
  const [data, setData] = useState([]);
  const formRef = useRef();
  const inputRef = useRef();
  const toast = useToast();

  useEffect(() => {
    setData([]);
  }, [isOpen]);

  const handleDownload = useCallback(() => {
    const columns = [{ label: "Campo 01" }, { label: "Campo 02" }, { label: "Campo 03" }, { label: "Campo 04" }];
    const rows = Array(4).fill(["R$ 8.350,00", "R$ 10.600,00", "83,50%", "12,45%"]);
    csvDownload(columns, rows, "modelo-indicadores");
  }, []);

  const handleFileChange = useCallback(
    async ({ target }) => {
      try {
        const [file] = target.files;
        const data = [];
        const result = await parse(file);
        if (result.length > 500) throw new Error(messages.error.importListSize);
        _.forEach(result.data, (item) => {
          const errors = [];
          const entries = Object.entries(item);
          for (const [key, value] of entries) {
            const indicatorType = _.find(indicatorTypes?.data, (o) => o.title === key);
            if (indicatorType) data.push({ indicatorType, value: getValue(indicatorType, value) });
            else errors.push(key);
          }
          if (errors.length) throw new Error(messages.error.importNotFoundIndicatorTypes.concat(errors.join(", ")));
        });
        setData(data);
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        formRef.current.reset();
      }
    },
    [indicatorTypes?.data]
  );

  const handleDelete = useCallback((index) => {
    setData((state) => {
      const tmp = [...state];
      tmp.splice(index, 1);
      return tmp;
    });
  }, []);

  const handleSubmit = useCallback(async () => {
    setFormData((state) => ({ ...state, indicators: data }));
    onClose();
  }, [data, onClose]);

  return (
    <Modal size="6xl" isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader as={HStack}>
          <Text flex="1">Importação de indicadores</Text>
          <Button size="sm" colorScheme="main" leftIcon={<Icon as={MdDownload} />} onClick={handleDownload}>
            baixar exemplo
          </Button>
          <IconButton size="sm" variant="outline" icon={<Icon as={MdClose} />} onClick={onClose} />
        </ModalHeader>
        <ModalBody>
          {data.length === 0 ? (
            <Center py="60px">
              <VStack maxW="lg">
                <Icon as={MdAttachFile} boxSize="100px" />
                <Heading size="lg" textAlign="center">
                  Selecione o arquivo
                </Heading>
                <Text textAlign="center">
                  Para iniciar a importação selecione o arquivo com os dados. É importante que o arquivo siga o exemplo disponibilizado.
                </Text>
                <Box h="5px" />
                <HStack>
                  <Button variant="outline" leftIcon={<Icon as={MdAttachFile} />} onClick={() => inputRef.current.click()}>
                    selecionar arquivo
                  </Button>
                </HStack>
              </VStack>
            </Center>
          ) : (
            <Table size="sm" whiteSpace="nowrap">
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>Título</Th>
                  <Th>Tipo</Th>
                  <Th>Valor</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.map((item, index) => (
                  <Tr key={index}>
                    <Td>
                      <IconButton size="xs" variant="outline" icon={<Icon as={MdDeleteOutline} />} onClick={() => handleDelete(index)} />
                    </Td>
                    <Td>{item.indicatorType.title}</Td>
                    <Td>{translator(item.indicatorType.type)}</Td>
                    <Td>
                      <IndicatorValue type={item.indicatorType.type} value={item.value} />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent="space-between">
            <Button size="sm" onClick={() => setData([])}>
              limpar dados
            </Button>
            <Button
              size="sm"
              colorScheme="green"
              leftIcon={<Icon as={MdOutlineCloudUpload} />}
              onClick={handleSubmit}
              isDisabled={data.length === 0}
            >
              importar dados
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
      <VisuallyHidden>
        <form ref={formRef}>
          <input ref={inputRef} type="file" accept=".csv" onChange={handleFileChange} />
        </form>
      </VisuallyHidden>
    </Modal>
  );
};

export default Import;
