import moment from "moment";
import { Avatar, HStack, Link, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { translator } from "lib";
import { CustomBadge, CustomerLogo } from "components";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "status",
    title: "Status",
    isVisible: true,
    render: ({ status }) => <CustomBadge.Solicitations.Status status={status} />,
    exporter: ({ status }) => translator(status),
  },
  {
    accessor: "title",
    title: "Título",
    isVisible: true,
    render: ({ _id, title }) => (
      <Link as={RouterLink} to={`edit/${_id}`}>
        {title}
      </Link>
    ),
    exporter: ({ title }) => title,
  },
  {
    accessor: "customer",
    title: "Cliente",
    isVisible: true,
    render: ({ customer }) => (
      <HStack>
        <CustomerLogo boxSize="30px" alt={customer?.tradingName} src={customer?.logoUrl} />
        <Text>{customer?.tradingName}</Text>
      </HStack>
    ),
    exporter: ({ customer }) => customer?.tradingName,
  },
  {
    accessor: "meetingType",
    title: "Tipo de reunião",
    isVisible: true,
    formatter: ({ meetingType }) => meetingType?.title ?? "-",
  },
  {
    accessor: "responsible",
    title: "Responsável",
    isVisible: true,
    render: ({ responsible }) => (
      <HStack>
        <Avatar size="xs" name={responsible?.name} src={responsible?.avatarUrl} />
        <Text>{responsible?.name ?? "-"}</Text>
      </HStack>
    ),
    exporter: ({ responsible }) => responsible?.name ?? "-",
  },
  {
    accessor: "closedAt",
    title: "Fechada em",
    isVisible: true,
    formatter: ({ closedAt }) => (closedAt ? moment(closedAt).format("DD/MM/YYYY HH:mm") : "-"),
  },
  {
    accessor: "createdBy",
    title: "Criada por",
    isVisible: true,
    formatter: ({ createdBy }) => createdBy?.name ?? "-",
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
